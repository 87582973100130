import { createSlice } from "@reduxjs/toolkit";
import {
  getConnectionThunk,
  createMessageThunk,
  getUserMessageThunk,
  getMessageConnectionThunk,
} from "./messageThunk";

const initialState = {
  connections: [],
  messages: [],
};

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // get skills
      // .addCase(getConnectionThunk.fulfilled, (state, action) => {
      //   state.connections = action?.payload?.data?.body;
      // })

      .addCase(getMessageConnectionThunk.fulfilled, (state, action) => {
        state.connections = action?.payload?.data?.body;
      })

      .addCase(getUserMessageThunk.fulfilled, (state, action) => {
        state.messages = action?.payload?.data?.body;
      })

      .addCase(createMessageThunk.fulfilled, (state, action) => {
        //state.connections = action?.payload?.data?.body;
      });
  },
});

export default messageSlice.reducer;
