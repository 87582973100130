import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const api_url = process.env.REACT_APP_API_URL;

export const getAllHashtagsThunk = createAsyncThunk(
  "hashtag/getAllHashtags",
  async (token) => {
    try {
      const data = await axios.get(`${api_url}/hashtag/getAllHashtags`, {
        headers: {
          Authorization: token,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);

export const createHashtagThunk = createAsyncThunk(
  "hashtag/create",
  async (body) => {
    try {
      const data = await axios.post(`${api_url}/hashtag/createHashtag`, body, {
        headers: {
          Authorization: body.token,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);
