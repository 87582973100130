import { createSlice } from "@reduxjs/toolkit";
import {
  getPopularPostsThunk,
  getPostsThunk,
  getTopRatedPostsThunk,
  postCreateThunk,
  postDeleteThunk,
  postGetsByUserThunk,
  getMynetworkPostsThunk,
} from "./postThunk";

const initialState = {
  post: {},
  posts: [],
  errorMessage: "",
  isLoggingStatus: false,
  userPosts: [],
  topRatedPosts: [],
  popularPosts: [],
  networkPosts: [],
};

const postsSlice = createSlice({
  name: "post",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // create
      .addCase(postCreateThunk.pending, (state) => {
        state.isLoggingStatus = false;
      })
      .addCase(postCreateThunk.fulfilled, (state) => {
        state.isLoggingStatus = true;
        state.post = "";
      })
      .addCase(postCreateThunk.rejected, (state, action) => {
        state.errorMessage = action?.error?.msg ? action?.error?.msg : "";
        state.isLoggingStatus = false;
      })
      // get posts
      .addCase(postGetsByUserThunk.pending, (state) => {
       
      })
      .addCase(postGetsByUserThunk.fulfilled, (state, action) => {
        state.posts = action?.payload?.data?.body?.data;
      })
      .addCase(postGetsByUserThunk.rejected, (state, action) => {
        state.errorMessage = action?.error?.msg ? action.error.msg : "";
        state.isLoggingStatus = false;
      })
      .addCase(getPostsThunk.fulfilled, (state, action) => {
        state.userPosts = action?.payload?.data?.body?.data;
      })
      .addCase(getTopRatedPostsThunk.fulfilled, (state, action) => {
        state.userPosts = action?.payload?.data?.body?.data;
      })
      .addCase(getPopularPostsThunk.fulfilled, (state, action) => {
        state.popularPosts = action?.payload?.data?.body;
      })
      .addCase(getMynetworkPostsThunk.fulfilled, (state, action) => {
        state.networkPosts = action?.payload?.data?.body?.data;
      });
  },
});

export default postsSlice.reducer;
