import React from "react";
import { Link } from "react-router-dom";
import logo from "../Components/Assets/images/logo.svg";
export default function Footer(props) {
  return (
    <div className="footer px-4 py-4">
      <div className="nav-items  d-flex justify-content-center flex-wrap">
        <Link to="/home">Home</Link>
        <a href="#" onClick={() => props.handlePostType("general")}>
          Posts
        </a>
        <Link to="/Settings">Settings</Link>
        <a href="#" onClick={() => props.handlePostType("job")}>
          Jobs
        </a>
        <Link to="/terms-condition">Privacy & Terms</Link>
        <Link to="/Profile">Profile</Link>
        {/* <Link>More</Link> */}
      </div>
      <div className="copyright pt-3 d-flex align-items-center justify-content-center">
        <div style={{width:"55px"}}>
        <img className="footer-logo" src={require("../Components/Assets/images/logo07.svg").default} />
        </div>
        <p className="mb-0">
           Connexus © 2023
        </p>
      </div>
    </div>
  );
}
