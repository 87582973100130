import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const api_url = process.env.REACT_APP_API_URL;

// SKILL

//skill create thunk
export const skillCreateThunk = createAsyncThunk(
  "skill/create",
  async (body) => {
    try {
      const data = await axios.post(`${api_url}/skill/create`, body, {
        headers: {
          Authorization: body.token,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);
//skill update thunk
export const skillUpdateThunk = createAsyncThunk(
  "skill/update",
  async (body) => {
    try {
      const data = await axios.post(`${api_url}/skill/update`, body, {
        headers: {
          Authorization: body.token,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);
//skill delete thunk
export const skillDeleteThunk = createAsyncThunk(
  "skill/delete",
  async (body) => {
    try {
      const data = await axios.delete(`${api_url}/skill/delete/${body._id}`, {
        headers: {
          Authorization: body.token,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);
//skill getBy user thunk
export const getUserSkillsThunk = createAsyncThunk(
  "skill/getUserSkills",
  async (token) => {
    try {
      const data = await axios.get(`${api_url}/skill/getUserSkills`, {
        headers: {
          Authorization: token,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);

// EXPERIENCE

//Experience create thunk
export const experienceCreateThunk = createAsyncThunk(
  "experience/create",
  async (body) => {
    try {
      const data = await axios.post(`${api_url}/experience/create`, body, {
        headers: {
          Authorization: body.token,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);
//Experience update thunk
export const experienceUpdateThunk = createAsyncThunk(
  "experience/update",
  async (body) => {
    try {
      const data = await axios.post(`${api_url}/experience/update`, body, {
        headers: {
          Authorization: body.token,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);
//Experience delete thunk
export const experienceDeleteThunk = createAsyncThunk(
  "experience/delete",
  async (body) => {
    try {
      const data = await axios.delete(
        `${api_url}/experience/delete/${body._id}`,
        {
          headers: {
            Authorization: body.token,
          },
        }
      );

      return data;
    } catch (error) {
      return error;
    }
  }
);
//Experience getBy user thunk
export const getUserExperiencesThunk = createAsyncThunk(
  "experience/getUserExperiences",
  async (token) => {
    try {
      const data = await axios.get(`${api_url}/experience/getUserExperiences`, {
        headers: {
          Authorization: token,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);

// EDUCATION

//education create thunk
export const educationCreateThunk = createAsyncThunk(
  "education/create",
  async (body) => {
    try {
      const data = await axios.post(`${api_url}/education/create`, body, {
        headers: {
          Authorization: body.token,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);
//Experience update thunk
export const educationUpdateThunk = createAsyncThunk(
  "education/update",
  async (body) => {
    try {
      const data = await axios.post(`${api_url}/education/update`, body, {
        headers: {
          Authorization: body.token,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);
//Experience delete thunk
export const educationDeleteThunk = createAsyncThunk(
  "education/delete",
  async (body) => {
    try {
      const data = await axios.delete(
        `${api_url}/education/delete/${body._id}`,
        {
          headers: {
            Authorization: body.token,
          },
        }
      );

      return data;
    } catch (error) {
      return error;
    }
  }
);
//Experience getBy user thunk
export const getUserEducationsThunk = createAsyncThunk(
  "education/getUserEducations",
  async (token) => {
    try {
      const data = await axios.get(`${api_url}/education/getUserEducations`, {
        headers: {
          Authorization: token,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);

// Followers
export const getFollowersThunk = createAsyncThunk(
  "follow/getFollowers",
  async (token) => {
    try {
      const data = await axios.get(`${api_url}/follow/getfollower`, {
        headers: {
          Authorization: token,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);
// Followings
export const getFollowingsThunk = createAsyncThunk(
  "follow/getfollowings",
  async (token) => {
    try {
      const data = await axios.get(`${api_url}/follow/getfollowings`, {
        headers: {
          Authorization: token,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);

export const getConnectionThunk = createAsyncThunk(
  "follow/connections",
  async (token) => {
    try {
      const data = await axios.get(`${api_url}/follow/connections`, {
        headers: {
          Authorization: token,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);
