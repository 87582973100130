import React from 'react'
import { Link } from 'react-router-dom'

export default function Information() {
  return (
    <div>
      <div className="container main login ">
        <div className="row align-items-center py-5">
          <div className="col-lg-12 col-md-12 col-sm-12 left">
            <div className="form-body">
              <div className="row">
                <div className="form-holder">
                  <div className="form-content">
                    <div className="form-items">
                      {/* <h3>Password Reset Link</h3> */}
                      <h4>
                        Password reset link sent your email.
                      </h4>
                   <p>Please check your email box.</p>
                    </div>
                  </div>
                </div>
                  <Link to="/" className='mt-4'>Back To Login</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
