import { createSlice } from "@reduxjs/toolkit";
import { getUserThunk } from "./userThunk";

const initialState = {
  users: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      //users list
      .addCase(getUserThunk.fulfilled, (state, action) => {
        state.users = action?.payload?.data?.body;
      });
  },
});

export default userSlice.reducer;
