import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import steve from "../Components/Assets/images/steve-jobs.png";
import user from "../Components/Assets/images/user2.png";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../Features/auth/authSelector";
import { profileSelector } from "../Features/profile/profileSelector";
import {
  getFollowersThunk,
  getFollowingsThunk,
} from "../Features/profile/profileThunk";
export default function Followers() {

  const { token } = useSelector(authSelector);
  const { followers, followings } = useSelector(profileSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const callApi = async () => {
    try {
      const follow = await dispatch(getFollowersThunk(token));
      const following = await dispatch(getFollowingsThunk(token));
    } catch (error) {
      console.log({ error });
    }
  };
  useEffect(() => {
    callApi();
  }, []);

  const handleViewPeople = (_id) => {
    navigate(`/results/view-profile/${_id}`);
  };

  return (
    <div className="followers">
      <nav>
        <div
          className="nav nav-tabs border-0 follower"
          id="nav-tab"
          role="tablist"
        >
          <button
            className="nav-link active border-0"
            id="nav-home-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-home"
            type="button"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
          >
            Followers
          </button>
          <button
            className="nav-link border-0"
            id="nav-profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-profile"
            type="button"
            role="tab"
            aria-controls="nav-profile"
            aria-selected="false"
          >
            Followings
          </button>
        </div>
      </nav>
      <div className="tab-content " id="nav-tabContent">
        <div
          className="tab-pane fade show active"
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
        >
          <ul className="connection-list list-unstyled p-0 mb-0">
            {followers?.length !== 0 ? (
              followers?.map((item) => {
                return (
                  <li
                    className="position-relative pt-2 pb-2 px-4 m-0"
                    key={item?._id}
                  >
                    <a className="d-flex align-items-center justify-content-between" href="javascript:void(0)">
                      <div className="detials d-flex align-items-center justify-content-between" onClick={() => handleViewPeople(item?._id)}>
                        {item?.followerUser?.image ? (
                          <img
                            className="me-3"
                            src={`${process.env.REACT_APP_API_URL}/uploads/users/${item?.followerUser?.image}`}
                          />
                        ) : (
                          <div
                            className="me-3 avatar-text d-flex align-items-center justify-content-center"
                            style={{ background: "grey" }}
                          >
                            <span className="text-uppercase text-white">
                              {item?.followerUser?.firstName?.slice(0, 1)}{" "}
                            </span>
                          </div>
                        )}

                        <div className="connection-detail text-start">
                          <h3 className="mb-1">
                            {item?.followerUser?.firstName +
                              item?.followerUser?.lastName}
                          </h3>
                          <p className="mb-0">
                            {item?.followerUser?.profession}
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                );
              })
            ) : (
              <div className="connection-detail py-5 text-center">
                <h3 className="text-muted">You don't have any follower yet</h3>
                {/* <p className="mb-0">CEO of Apple</p> */}
              </div>
            )}
          </ul>
          <div className="view-all text-start px-4 py-3">
            <Link className="view-all-btn mb-3 text-dark">View All</Link>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="nav-profile"
          role="tabpanel"
          aria-labelledby="nav-profile-tab"
        >
          <ul className="connection-list list-unstyled p-0 mb-0">
            {followings?.length !== 0 ? (
              followings?.map((item) => {
                return (
                  <li
                    className="position-relative pt-2 pb-2 px-4 m-0"
                    key={item?._id}
                  >
                    <a className="d-flex align-items-center justify-content-between" href="javascript:void(0)">
                      <div className="detials d-flex align-items-center justify-content-between" onClick={() => handleViewPeople(item?._id)}>
                        {item?.followingUser?.image ? (
                          <img
                            className="me-3"
                            src={`${process.env.REACT_APP_API_URL}/uploads/users/${item?.followingUser?.image}`}
                          />
                        ) : (
                          <div
                            className="me-3 avatar-text d-flex align-items-center justify-content-center"
                            style={{ background: "grey" }}
                          >
                            <span className="text-uppercase text-white">
                              {item?.followingUser?.firstName?.slice(0, 1)}
                            </span>
                          </div>
                        )}
                        <div className="connection-detail text-start">
                          <h3 className="mb-1">
                            {item?.followingUser?.firstName +
                              item?.followingUser?.lastName}
                          </h3>
                          <p className="mb-0">
                            {item?.followingUser?.profession}
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                );
              })
            ) : (
              <div className="connection-detail py-5 text-center">
                <h3 className="text-muted">
                  You are not following anybody yet.
                </h3>
                {/* <p className="mb-0">CEO of Apple</p> */}
              </div>
            )}
          </ul>
          <div className="view-all text-start px-4 py-3">
            <Link className="view-all-btn mb-3 text-dark" to={"/connections"}>
              View All
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
