import React, { useState } from "react";
import Connections from "../../Components/Connections";
import Advertisement from "../../Components/Advertisement";
import Messagebox from "../../Components/Messagebox";
import SearchMessageUser from "../SearchMessageUser";
import Footer from "../../Components/Footer";
import UserList from "../UserList";
import Navbar from "../Header/Navbar";
import { Link, useNavigate, useLocation } from "react-router-dom";

export default function Messages() {
  const location = useLocation();
  const [defaultUser, setDefaultUser] = useState(
    location?.state?.defaultUser ? location?.state?.defaultUser : ""
  );
  const [defaultRoom, setDefaultRoom] = useState("");
  const [userSearch, setUserSearch] = useState(false);

  const handleUserMsg = (user, connectionId) => {
    setDefaultUser(user);
    setDefaultRoom(connectionId);
    setUserSearch("");
  };

  const handleUserSearch = (value) => {
    setUserSearch(value);
    setDefaultUser("");
  };



  return (
    <div>
      <Navbar />
      <div className="container main">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-12 left">
            <UserList
              defaultUser={defaultUser}
              handleUserMsg={handleUserMsg}
              defaultRoom={defaultRoom}
              handleUserSearch={handleUserSearch}
            />
          </div>

          <div className="col-lg-6 col-md-8 col-sm-12 center">
            {userSearch ? (
              <SearchMessageUser
                defaultUser={defaultUser}
                handleUserMsg={handleUserMsg}
                handleUserSearch={handleUserSearch}
                searchTerm={
                  location?.state?.defaultUser
                    ? location?.state?.defaultUser?.firstName +
                    " " +
                    location?.state?.defaultUser?.lastName
                    : ""
                }
                userSelected={location?.state?.defaultUser?._id}
              />
            ) : (
              <Messagebox
                defaultUser={defaultUser}
                handleUserMsg={handleUserMsg}
                defaultRoom={defaultRoom}
              />
            )}
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 right">
            <Connections />
            {/* <Advertisement /> */}
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
