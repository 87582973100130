import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import bioedit from "../Components/Assets/images/bio-edit.svg";
import moscow from "../Components/Assets/images/moscow.svg";
import { useDispatch, useSelector } from "react-redux";
import { profileSelector } from "../Features/profile/profileSelector";
import {
  getUserEducationsThunk,
  getUserExperiencesThunk,
} from "../Features/profile/profileThunk";
import plus from "../Components/Assets/images/plus.svg";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import axios from "axios";
import { authSelector } from "../Features/auth/authSelector";
import * as Yup from "yup";
import toast from "react-hot-toast";

export default function Education() {
  const { educations } = useSelector(profileSelector);
  const { token } = useSelector(authSelector);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isAddMode, setIsAddMode] = useState(false);
  const [seeMore, setSeeMore] = useState(3);

  const [dataValue, setDataValue] = useState({
    title: "",
    school: "",
    grade: "",
    field_of_study: "",
    activities_and_societies: "",
    description: "",
    startDate: "",
    endDate: "",
    _id: "",
  });

  const dispatch = useDispatch();

  const callApi = async (token) => {
    await dispatch(getUserEducationsThunk(token));
  };
  const [show, setShow] = useState(false);

  useEffect(() => {
    callApi(token);
  }, []);

  // Function to handle adding and editing
  const handleAddEdit = async (values) => {
    try {
      if (!isAddMode) {
        // Add Education
        try {
          delete values._id;
          const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/education/create`,
            values,
            {
              headers: {
                Authorization: token,
              },
            }
          ); 
          if (res.status === 200) {
            toast.success(res?.data?.message);
            callApi(token);
            // setShow(false);
            formik.resetForm();
          } else {
            toast.error(res?.data?.message);
          }
        } catch (error) {
          console.log({ error });
          toast.error(error.response.data.message);
        }
      } else {
        // Edit Education 

        const res = await axios.put(
          `${process.env.REACT_APP_API_URL}/education/update`,
          values,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (res.status === 200) {
          toast.success(res?.data?.message);
          callApi(token);
          setShow(false);
          setIsAddMode(false);
          formik.resetForm();
        }
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
    }
  };

  const formik = useFormik({
    initialValues: dataValue,
    validationSchema: Yup.object({
      title: Yup.string().required("This field is required"),
      school: Yup.string().required("This field is required"),
      startDate: Yup.date().required("This field is required"),
      endDate: Yup.date()
        .required("This field is required")
        .min(
          Yup.ref("startDate"),
          "End Date must be later than or equal to Start Date"
        ),
      //endDate: Yup.string().required("This field will be required"),
    }),
    onSubmit: (values) => {
      handleAddEdit(values);
    },
  }); 
  return (
    <div>
      <div className="education experience my-4 px-4 py-4 text-start ">
        <div className="title-with-icon d-flex justify-content-between">
          <div className="titel">
            <h3 className="mb-1">Education</h3>
          </div>
          <div className="icon">
            <button
              onClick={() => {
                setIsAddMode(false);
                setShow(true);
              }}
              type="button"
              className="border-0 bg-transparent"
            >
              <img src={plus} width={22} />
            </button>
          </div>
        </div>
        <ul className="list-unstyled my-4">
          {educations &&
            educations?.slice(0, seeMore)?.map((item) => {
              return (
                <>
                  <li className="d-flex align-items-start mb-4 pb-4">
                    <img src={moscow} />
                    <div className="details ms-3">
                      <h4>{item?.title}</h4>
                      <p className="designation mb-2">
                        School/College -
                        <span span className="ms-2">
                          {item?.school}
                        </span>
                      </p>
                      <p className="years-months mb-2">
                        {item?.startDate}-{item?.endDate}
                        {/* <span className="ms-2">{item?.endDate}</span> */}
                      </p>
                      <p className="description">
                        Grade :<span className="ms-2">{item?.grade}</span>
                      </p>
                    </div>

                    <div className="icon ms-auto">
                      <button
                        onClick={() => {
                          setIsAddMode(true); // Set edit mode
                          formik.setValues({
                            title: item.title,
                            school: item.school,
                            grade: item.grade,
                            field_of_study: item.field_of_study,
                            activities_and_societies:
                              item.activities_and_societies,
                            description: item.description,
                            startDate: item.startDate,
                            endDate: item.endDate,
                            _id: item._id,
                          });
                          setShow(true);
                        }}
                        type="button"
                        className="border-0 bg-transparent"
                      >
                        <img src={bioedit} />
                      </button>
                    </div>
                  </li>
                </>
              );
            })}
        </ul>
        {seeMore === educations?.length ? (
          <Link
            className="common-btn"
            style={{ display: educations?.length > 3 ? "" : "none" }}
            onClick={() => setSeeMore(3)}
          >
            Less more
          </Link>
        ) : (
          <Link
            className="common-btn"
            onClick={() => setSeeMore(educations?.length)}
          >
            See more
          </Link>
        )}
      </div>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          formik.resetForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{!isAddMode ? "Add" : "Edit"} Education</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="modal-body px-3">
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3 text-start">
                <label htmlFor="exampleInputEmail1" className="form-label m-0">
                  Title <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  name="title"
                  className="form-control"
                  id="title"
                  aria-describedby="title"
                />
                <span style={{ color: "red" }}>
                  {" "}
                  {formik.touched.title && formik.errors.title ? (
                    <div>{formik.errors.title} </div>
                  ) : null}
                </span>
              </div>
              <div className="mb-3 text-start">
                <label htmlFor="exampleInputEmail1" className="form-label m-0">
                  School Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  value={formik.values.school}
                  onChange={formik.handleChange}
                  name="school"
                  className="form-control "
                  id="school"
                  aria-describedby="school"
                />
                <span style={{ color: "red" }}>
                  {" "}
                  {formik.touched.school && formik.errors.school ? (
                    <div>{formik.errors.school} </div>
                  ) : null}
                </span>
              </div>
              <div className="mb-3 text-start">
                <label htmlFor="exampleInputEmail1" className="form-label m-0">
                  Field of study <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  value={formik.values.field_of_study}
                  onChange={formik.handleChange}
                  name="field_of_study"
                  className="form-control "
                  id="field_of_study"
                  aria-describedby="field_of_study"
                />
              </div>
              <div className="mb-3 text-start">
                <label htmlFor="exampleInputEmail1" className="form-label m-0">
                  Start date <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  value={formik.values.startDate}
                  onChange={formik.handleChange}
                  name="startDate"
                  className="form-control "
                  rows="4"
                  cols="50"
                />
                <span style={{ color: "red" }}>
                  {" "}
                  {formik.touched.startDate && formik.errors.startDate ? (
                    <div>{formik.errors.startDate} </div>
                  ) : null}
                </span>
              </div>
              <div className="mb-3 text-start">
                <label htmlFor="exampleInputEmail1" className="form-label m-0">
                  End date <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  value={formik.values.endDate}
                  onChange={formik.handleChange}
                  name="endDate"
                  className="form-control "
                  rows="4"
                  cols="50"
                />
                <span style={{ color: "red" }}>
                  {" "}
                  {formik.touched.endDate && formik.errors.endDate ? (
                    <div>{formik.errors.endDate} </div>
                  ) : null}
                </span>
              </div>
              <div className="mb-3 text-start">
                <label htmlFor="exampleInputEmail1" className="form-label m-0">
                  Grade
                </label>
                <input
                  type="text"
                  value={formik.values.grade}
                  onChange={formik.handleChange}
                  name="grade"
                  className="form-control "
                  id="grade"
                  aria-describedby="grade"
                />
              </div>
              <span style={{ color: "red" }}> {errorMessage}</span>
              <div className="file-input">
                <button type="submit" className="save-changes">
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
