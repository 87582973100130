export const postSelector = (state) => {
  const {
    post,
    posts,
    errorMessage,
    isLoggingStatus,
    userPosts,
    topRatedPosts,
    popularPosts,
    networkPosts,
  } = state.posts;
  return {
    post,
    posts,
    errorMessage,
    isLoggingStatus,
    userPosts,
    topRatedPosts,
    popularPosts,
    networkPosts,
  };
};
