import React, { useEffect, useState } from "react";
import thomas from "../../Components/Assets/images/thomas.png";
import photo from "../../Components/Assets/images/photo.svg";
import video from "../../Components/Assets/images/video-3.svg";
import document from "../../Components/Assets/images/document.svg";
import calendar from "../../Components/Assets/images/calendar.svg";
import send from "../../Components/Assets/images/send.svg";
import { Button, Form, Modal, Select } from "react-bootstrap";
import { useFormik } from "formik";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../Features/auth/authSelector";
import { useNavigate } from "react-router-dom";
import {
  getPostsThunk,
  postGetsByUserThunk,
  createPostThunk,
  createPostPaymentThunk,
} from "../../Features/posts/postThunk";
import * as Yup from "yup";
import { postSchema } from "../../utils/validation";
import toast from "react-hot-toast";

import logo from "../Assets/images/logo.svg";

export default function EditPost(props) {
  const [show, setShow] = useState(false);
  const [attachment, setAttachment] = useState("");
  const { userData, token } = useSelector(authSelector);
  const [filesName, setFilesName] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [attachments, setAttachments] = useState("");
  const [savedPost, setSavedPost] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const jobTypes = [
    { value: "general", label: "General post" },
    { value: "job", label: "Job type" },
    { value: "webinar", label: "Webinar post" },
  ];

  const formik = useFormik({
    initialValues: {
      description: props.editPost.description,
      postType: props.editPost.postType,
      opening: props.editPost.opening,
      title: props.editPost.title,
      salary: props.editPost.salary,
      link: props.editPost.link,
    },
    validationSchema: postSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const formData = new FormData();
        formData.append("description", formik.values.description);
        formData.append("postType", formik.values.postType);
        formData.append("opening", formik.values.opening);
        formData.append("title", formik.values.title);
        formData.append("salary", formik.values.salary);
        formData.append("link", formik.values.link);
        formData.append("_id", props.editPost?._id);
        for (let i = 0; i < attachments.length; i++) {
          formData.append("attachfiles", attachments[i]);
        }

        let resp = await axios.post(
          `${process.env.REACT_APP_API_URL}/post/update`,
          formData,
          {
            headers: {
              Authorization: token,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (resp?.status === 200) {
          setShow(false);
          setAttachments("");
          setFilesName("");
          props.onClose();
          props.fetchData();

          toast.success(resp?.data?.message, {
            position: "top-right",
           
          });
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          
        
        });
      }
    },
  });

  const handlePostAttachments = (e) => {
    let files = [];
    let attachments = [];
    for (const file of e.target.files) {
      files.push(file.name);
      attachments.push(file);
    }
    setFilesName(files.join(", "));
    setAttachments(attachments);
 
  };

  

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onClose}
        className="add-post-modal"
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title>
            <div className="user-field d-flex align-items-center">
              {userData?.image ? (
                <img
                  className="border-thumb"
                  src={`${process.env.REACT_APP_API_URL}/uploads/users/${userData?.image}`}
                />
              ) : (
                <div
                  className="me-3 avatar-text d-flex align-items-center justify-content-center"
                  style={{ background: "grey" }}
                >
                  <span className="text-uppercase text-white">
                    {userData?.firstName?.slice(0, 1)}{" "}
                  </span>
                </div>
              )}
              <h5 className="m-2">
                {userData?.firstName + userData?.lastName}
              </h5>
            </div>
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <select
              className="form-select w-auto mb-3"
              value={formik.values.postType}
              name="postType"
              onChange={formik.handleChange}
              disabled
            >
              <option value="">Select post type</option>
              {jobTypes.map((item) => {
                return (
                  <option value={item?.value} key={item.value}>
                    {item?.label}
                  </option>
                );
              })}
            </select>

            {formik.touched.postType && formik.errors.postType ? (
              <div style={{ color: "red" }}>{formik.errors.postType} </div>
            ) : null}

            <div className="row">
              {(formik.values.postType === "job" ||
                formik.values.postType === "webinar") && (
                <div className="col-md-6">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter title"
                    className="mb-3"
                    value={formik.values.title}
                    name="title"
                    onChange={formik.handleChange}
                  />
                </div>
              )}
              {formik.touched.title && formik.errors.title ? (
                <div style={{ color: "red" }}>{formik.errors.title} </div>
              ) : null}

              {formik.values.postType === "job" && (
                <>
                  <div className="col-md-6">
                    <Form.Label>Openings</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Number of openings"
                      className="mb-3"
                      value={formik.values.opening}
                      name="opening"
                      onChange={formik.handleChange}
                    />
                  </div>
                  {formik.touched.opening && formik.errors.opening ? (
                    <div style={{ color: "red" }}>{formik.errors.opening} </div>
                  ) : null}

                  <div className="col-md-6">
                    <Form.Label>Salary</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter salary"
                      className="mb-3"
                      value={formik.values.salary}
                      name="salary"
                      onChange={formik.handleChange}
                    />
                  </div>
                  {formik.touched.salary && formik.errors.salary ? (
                    <div style={{ color: "red" }}>{formik.errors.salary} </div>
                  ) : null}
                </>
              )}
              {formik.values.postType === "webinar" && (
                <div className="col-md-6">
                  <Form.Label>Webinar Link</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter webinar link"
                    className="mb-3"
                    name="link"
                    value={formik.values.link}
                    onChange={formik.handleChange}
                  />
                </div>
              )}
              {formik.touched.link && formik.errors.link ? (
                <div style={{ color: "red" }}>{formik.errors.link} </div>
              ) : null}

              <div className="col-md-12">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  className="p-0 border-0 mb-3"
                  as="textarea"
                  placeholder="What do you want to talk about?"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  rows={6}
                />
              </div>
              {formik.touched.description && formik.errors.description ? (
                <div style={{ color: "red" }}>{formik.errors.description} </div>
              ) : null}
            </div>

            {formik.values.postType !== "job" && (
              <div className="d-flex align-items-center">
                <div className="add-media">
                  <Form.Control
                    type="file"
                    className="d-none"
                    id="addFile"
                    accept="image/*"
                    onChange={(e) => handlePostAttachments(e)}
                    name="attachments[]"
                    multiple
                  />
                  <Form.Label htmlFor="addFile">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="19"
                      viewBox="0 0 24 21"
                      fill="none"
                    >
                      <path
                        d="M20.5714 0H3.42857C2.51956 0.000992601 1.64807 0.362535 1.0053 1.0053C0.362535 1.64807 0.000992601 2.51956 0 3.42857V17.1429C0.000992601 18.0519 0.362535 18.9234 1.0053 19.5661C1.64807 20.2089 2.51956 20.5704 3.42857 20.5714H20.5714C21.4804 20.5704 22.3519 20.2089 22.9947 19.5661C23.6375 18.9234 23.999 18.0519 24 17.1429V3.42857C23.999 2.51956 23.6375 1.64807 22.9947 1.0053C22.3519 0.362535 21.4804 0.000992601 20.5714 0ZM16.2857 3.42857C16.7943 3.42857 17.2915 3.57938 17.7143 3.86194C18.1372 4.14449 18.4668 4.54609 18.6614 5.01596C18.856 5.48582 18.907 6.00285 18.8077 6.50166C18.7085 7.00047 18.4636 7.45865 18.104 7.81827C17.7444 8.1779 17.2862 8.4228 16.7874 8.52202C16.2886 8.62124 15.7715 8.57032 15.3017 8.37569C14.8318 8.18107 14.4302 7.85148 14.1476 7.42861C13.8651 7.00574 13.7143 6.50858 13.7143 6C13.715 5.31823 13.9861 4.66459 14.4682 4.18251C14.9503 3.70043 15.6039 3.42928 16.2857 3.42857ZM3.42857 18.8571C2.97391 18.8571 2.53788 18.6765 2.21639 18.355C1.8949 18.0335 1.71429 17.5975 1.71429 17.1429V13.5198L6.795 9.00375C7.2852 8.56899 7.92272 8.33749 8.57766 8.3564C9.23261 8.37531 9.8557 8.64322 10.32 9.10554L13.7995 12.5775L7.51982 18.8571H3.42857ZM22.2857 17.1429C22.2857 17.5975 22.1051 18.0335 21.7836 18.355C21.4621 18.6765 21.0261 18.8571 20.5714 18.8571H9.94446L16.4491 12.3525C16.9096 11.9609 17.4941 11.7451 18.0986 11.7435C18.7032 11.742 19.2887 11.9547 19.7513 12.3439L22.2857 14.4557V17.1429Z"
                        fill="#666666"
                      />
                    </svg>
                  </Form.Label>
                  <p className="img_name mb-0 ms-2">{filesName}</p>
                </div>
              </div>
            )}
          </Modal.Body>

          <Modal.Footer className="">
            <Button type="submit" className="save-changes px-5">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
