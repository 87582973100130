import { createSlice } from "@reduxjs/toolkit";

import {
  deleteUserChatThunk,
  forgotPasswordThunk,
  getFindColleaguesThunk,
  getProfileThunk,
  loginThunk,
  pinUserThunk,
  registerThunk,
  searchResultThunk,
  socialLoginThunk,
  updateProfileThunk,
  userProfileThunk,
} from "./authThunk";

const initialState = {
  // login

  isLoggedIn: false,
  isLogged: false,
  isLoggingStatus: "",
  isLoading: false,
  isLoggingError: false,
  loggingErrorMsg: "",
  userData:
    localStorage.getItem("user") !== "undefined"
      ? JSON.parse(localStorage.getItem("user"))
      : "",
  token:
    JSON.parse(localStorage.getItem("token")) ??
    JSON.parse(localStorage.getItem("token")),
  // register
  isRegisterSuccess: false,
  isRegisterStatus: "idle",
  isRegisterError: false,
  registerErrorMsg: "",
  // forgot password
  forgotPasswordStatus: "idle",
  isForgotPasswordError: false,
  forgotPasswordErrorMsg: "",
  isForgotPasswordSuccess: false,
  // User profile
  user: {},
  // Search results
  searchResults: [],
  findColleagues: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // login thunk

      .addCase(loginThunk.pending, (state) => {
        state.isLoggingStatus = "loading";
        state.isLoggingError = false;
        // state.isLoggedIn = false;
        state.isLoading = true;
      })
      .addCase(loginThunk.fulfilled, (state, action) => {
        if (action?.payload.status === 200) {
          const currentDate = new Date().toLocaleDateString();

          localStorage.setItem(
            "lastLoginTimestamp",
            JSON.stringify(currentDate)
          );
          state.isLoggedIn = true;
          state.isLogged = true;
          state.isLoading = false;
          localStorage.setItem(
            "user",
            JSON.stringify(action?.payload?.data?.body?.user)
          );
          localStorage.setItem("loggedin", true);
          localStorage.setItem(
            "token",
            JSON.stringify(action?.payload?.data?.body?.token)
          );
          state.token = action?.payload?.data?.body?.token;
          state.userData = { ...action?.payload?.data?.body?.user };
        }
      })
      .addCase(loginThunk.rejected, (state, action) => {
        state.loggingErrorMsg = action?.error?.msg ? action?.error?.msg : "";
        state.isLoggingStatus = "failed";
        state.isLoggingError = true;
        // state.isLoggedIn = false;
        state.isLoading = false;
      })

      // SOCIAL LOGIN
      .addCase(socialLoginThunk.fulfilled, (state, action) => {
        if (action?.payload.status === 200) {
          const currentDate = new Date().toLocaleDateString();

          localStorage.setItem(
            "lastLoginTimestamp",
            JSON.stringify(currentDate)
          );
          state.isLoggedIn = true;
          state.isLogged = true;
          state.isLoading = false;
          localStorage.setItem(
            "user",
            JSON.stringify(action?.payload?.data?.body?.user)
          );
          localStorage.setItem("loggedin", true);
          localStorage.setItem(
            "token",
            JSON.stringify(action?.payload?.data?.body?.token)
          );
          state.token = action?.payload?.data?.body?.token;
          state.userData = { ...action?.payload?.data?.body?.user };
        }
      })

      // Get user profile

      .addCase(userProfileThunk.pending, (state, action) => {
        state.isLoggingStatus = "loading";
        state.isLoggingError = false;
        // state.isLoggedIn = false;
        state.isLoading = true;
      })
      .addCase(userProfileThunk.fulfilled, (state, action) => {
        state.isLoggingStatus = "idle";
        // state.user = action.payload.data.body.user;
        state.isLoggingError = false;
        // state.isLoggedIn = true;
        state.isLoading = false;
      })
      .addCase(userProfileThunk.rejected, (state, action) => {
        state.isLoggingStatus = "loading";
        state.isLoggingError = false;
        // state.isLoggedIn = false;
        state.isLoading = true;
      })

      // register thunk

      .addCase(registerThunk.pending, (state, action) => {
        state.isRegisterStatus = "loading";
        state.isRegisterError = false;
        state.isRegisterSuccess = false;
      })
      .addCase(registerThunk.fulfilled, (state, action) => {
        if (action?.payload?.response?.status === 422) {
          state.isRegisterStatus = "idle";
          state.isRegisterError = false;
          // state.isRegisterSuccess = action.payload.response.data.status
          // state.registerErrorMsg = action.payload.response.data.msg
        } else if (action?.payload?.status === 200) {
          state.isRegisterStatus = "idle";
          state.isRegisterError = false;
          state.isRegisterSuccess = action?.payload?.data?.status;
          state.registerErrorMsg = action?.payload?.data?.msg;
          state.userData = action?.payload?.data;
        } else {
        }
      })
      .addCase(registerThunk.rejected, (state, action) => {
        state.isRegisterError = true;
        state.registerErrorMsg = action?.payload?.error?.response?.data?.msg;
        state.isRegisterSuccess = false;

        state.isRegisterStatus = "failed";
      })

      // forgot password
      .addCase(forgotPasswordThunk.pending, (state, action) => {
        state.forgotPasswordStatus = "loading";
        state.isForgotPasswordError = false;
        state.isForgotPasswordSuccess = false;
      })
      .addCase(forgotPasswordThunk.fulfilled, (state, action) => {
        state.forgotPasswordStatus = "idle";
        state.isForgotPasswordError = false;
        state.forgotPasswordErrorMsg = "";
      })
      .addCase(forgotPasswordThunk.rejected, (state, action) => {
        state.forgotPasswordStatus = "failed";
        state.isForgotPasswordError = false;
        state.forgotPasswordErrorMsg = "";
      })
      .addCase(updateProfileThunk.fulfilled, (state, action) => {
        localStorage.setItem(
          "user",
          JSON.stringify(action?.payload?.data?.body?.user)
        );
      })
      .addCase(getProfileThunk.fulfilled, (state, action) => {
        localStorage.setItem(
          "user",
          JSON.stringify(action?.payload?.data?.body?.user)
        );

        state.userData = { ...action?.payload?.data?.body?.user };
      })

      .addCase(pinUserThunk.fulfilled, (state, action) => {
        localStorage.setItem(
          "user",
          JSON.stringify(action?.payload?.data?.body)
        );

        state.userData = { ...action?.payload?.data?.body };
      })
      .addCase(getFindColleaguesThunk.fulfilled, (state, action) => {
            state.findColleagues = action?.payload?.data?.body;
      })
      .addCase(searchResultThunk.fulfilled, (state, action) => {
        state.isLoading = true;
        if (action?.payload?.status === 200) {
          state.searchResults = action?.payload?.data?.body;
          state.isLoading = false;
        } else {
          state.isLoading = false;
        }
      });
  },
});

// export const { refreshStatus, addToken, removeToken } = authSlice.actions;
export default authSlice.reducer;
