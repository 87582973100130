import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const api_url = process.env.REACT_APP_API_URL;

//post create thunk
export const postCreateThunk = createAsyncThunk("post/create", async (body) => {
  try {
    const data = await axios.post(`${api_url}/post/create`, body, {
      headers: {
        Authorization: "",
      },
    });

    return data;
  } catch (error) {
    return error;
  }
});

export const postUpdateThunk = createAsyncThunk("post/update", async (body) => {
  try {
    const data = await axios.post(`${api_url}/post/update`, body, {
      headers: {
        Authorization: "",
      },
    });

    return data;
  } catch (error) {
    return error;
  }
});

export const postDeleteThunk = createAsyncThunk("post/delete", async (body) => {
  try {
    const data = await axios.delete(`${api_url}/post/delete`, body, {
      headers: {
        Authorization: "",
      },
    });

    return data;
  } catch (error) {
    return error;
  }
});
export const postGetsByUserThunk = createAsyncThunk(
  "post/getByUserPost",
  async (params) => {
    try {
      const data = await axios.get(`${api_url}/post/getByUserPost`, {
        headers: {
          Authorization: params.token,
        },
        params: {
          hashtag: params.hashtag,
          bookmark: params.bookmark,
          postType: params.postType,
          activepage: params.page,
          limit: params.limit,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);

export const getPostsThunk = createAsyncThunk(
  "post/getAllPost",
  async (params) => {
    try {
      const data = await axios.get(`${api_url}/post/getAllPost`, {
        headers: {
          Authorization: params.token,
        },
        params: {
          hashtag: params.hashtag,
          bookmark: params.bookmark,
          postType: params.postType,
          activepage: params.page,
          limit: params.limit,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);

export const getTopRatedPostsThunk = createAsyncThunk(
  "post/toprated",
  async (params) => {
    try {
      const data = await axios.get(`${api_url}/post/toprated`, {
        headers: {
          Authorization: params.token,
        },
        params: {
          hashtag: params.hashtag,
          bookmark: params.bookmark,
          postType: params.postType,
          activepage: params.page,
          limit: params.limit,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);

export const getPopularPostsThunk = createAsyncThunk(
  "post/popular",
  async (token) => {
    try {
      const data = await axios.get(`${api_url}/post/popular`, {
        headers: {
          Authorization: token,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);

export const createPostThunk = createAsyncThunk("post/create", async (body) => {
  try {
    const data = await axios.post(`${api_url}/post/create`, body, {
      headers: {
        Authorization: body.token,
        "Content-Type": "multipart/form-data",
      },
    });

    return data;
  } catch (error) {
    return error;
  }
});

export const createPostPaymentThunk = createAsyncThunk(
  "payment/create",
  async (body) => {
    try {
      const data = await axios.post(`${api_url}/payment/create`, body, {
        headers: {
          Authorization: body.token,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);

export const createPostBookmarkThunk = createAsyncThunk(
  "bookmark/create",
  async (body) => {
    try {
      const data = await axios.post(`${api_url}/bookmark/create`, body, {
        headers: {
          Authorization: body.token,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);

export const deletePostBookmarkThunk = createAsyncThunk(
  "bookmark/delete",
  async (body) => {
    try {
      const data = await axios.delete(
        `${api_url}/bookmark/delete/${body.bookmarkId}`,
        {
          headers: {
            Authorization: body.token,
          },
        }
      );

      return data;
    } catch (error) {
      return error;
    }
  }
);

export const getMynetworkPostsThunk = createAsyncThunk(
  "post/mynetwork",
  async (body) => {
    try {
      const data = await axios.get(`${api_url}/post/getMyNetworkPosts`, {
        headers: {
          Authorization: body.token,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);

export const reportPostThunk = createAsyncThunk("post/report", async (body) => {
  try {
    const data = await axios.post(`${api_url}/post/reportPost`, body, {
      headers: {
        Authorization: body.token,
      },
    });

    return data;
  } catch (error) {
    return error;
  }
});
