import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const api_url = process.env.REACT_APP_API_URL;

export const getUserGoalsThunk = createAsyncThunk(
  "get/goalsByUser",
  async (token) => {
    try {
      const data = await axios.get(`${api_url}/goal/getUserGoals`, {
        headers: {
          Authorization: token,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);

export const createGoalThunk = createAsyncThunk("goal/create", async (body) => {
  try {
    const data = await axios.post(`${api_url}/goal/create`, body, {
      headers: {
        Authorization: body.token,
        "Content-Type": "multipart/form-data",
      },
    });

    return data;
  } catch (error) {
    return error;
  }
});

export const updateGoalThunk = createAsyncThunk("goal/update", async (body) => {
  try {
    const data = await axios.put(`${api_url}/goal/update/${body.id}`, body, {
      headers: {
        Authorization: body.token,
        "Content-Type": "multipart/form-data",
      },
    });

    return data;
  } catch (error) {
    return error;
  }
});
