import React, { useEffect, useState } from "react";

import rarrow from "../Components/Assets/images/right-arrow.svg";
import { useFormik } from "formik";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { ModalBody, ModalHeader } from "react-bootstrap";
import * as Yup from "yup";
import EditProfileModal from "./EditProfileModal";
import { authSelector } from "../Features/auth/authSelector";
import {
  updateProfileThunk,
  getProfileThunk,
} from "../Features/auth/authThunk";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

export default function Settingscontent({ }) {
  const [user, setUserData] = useState();
  const [token, setToken] = useState("");
  const [show, setShow] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const { userData } = useSelector(authSelector);
  const [section, setSection] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = JSON.parse(localStorage.getItem("token"));

    setUserData(user);
    setToken(token);
  }, []);
  function calculateAge() {
    var today = new Date();
    var birthDate = new Date(userData?.dob);

    var age = today.getFullYear() - birthDate.getFullYear();
    var monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }
  const resetPasswordSchema = Yup.object({
    currentPassword: Yup.string().required("Current Password is required."),

    newPassword: Yup.string().required("New Password is required."),
    // confirmPassword: Yup.string()
    //   .required("Confirm password is required.")
    //   .oneOf([Yup.ref("password"), null], "Passwords must match."),
  });

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      //   confirmPassword: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (body) => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/changePassword`,
          body,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        setShow(false);
      } catch (error) {
        console.log(error);
      }
    },
  });

  const updateNotification = async (payload) => {
    const res = await dispatch(updateProfileThunk(payload));

    if (res?.payload?.status === 200) {
      toast.success(res?.payload?.data?.message);
      await dispatch(getProfileThunk(token));
    } else {
      toast.error(res?.payload?.data?.message);
    }
  };

  return (
    <>
      <div className="position-relative setting-content">
        <div className="tab-content" id="v-pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="v-pills-home"
            role="tabpanel"
            aria-labelledby="v-pills-home-tab"
          >
            <p className="stat-title mb-3 position-relative">
              Account Preferences
            </p>
            <div className="tab-boxes settings pt-3 pb-0 px-4">
              <h4 className="text-start">Profile</h4>
              <ul
                className=" list-unstyled pt-1 m-0"
                onClick={() => {
                  setEditProfile(true);
                  setSection("profile");
                }}
              >
                <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button className="border-0 d-flex align-items-center justify-content-between px-0 py-2">
                      <div className="notify-user d-flex  align-items-center ">
                        <div className="notification-message text-start d-flex  align-items-center justify-content-between w-100">
                          <p className="mb-0">Name</p>
                          <span className="right-data me-3">
                            {" "}
                            {userData?.firstName +
                              "  " +
                              userData?.lastName}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li>

                <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button className="border-0 d-flex align-items-center justify-content-between px-0 py-2">
                      <div className="notify-user d-flex  align-items-center ">
                        <div className="notification-message text-start d-flex  align-items-center justify-content-between w-100">
                          <p className="mb-0">Email</p>
                          <span className="right-data me-3">
                            {" "}
                            {userData?.email}
                          </span>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li>
                <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button className="border-0 d-flex align-items-center justify-content-between px-0 py-2">
                      <div className="notify-user d-flex  align-items-center ">
                        <div className="notification-message text-start d-flex  align-items-center justify-content-between w-100">
                          <p className="mb-0">Phone Number</p>
                          <span className="right-data me-3">
                            {userData?.phone}
                          </span>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
            <div className="tab-boxes settings pt-3 pb-0 px-4 mt-3">
              <h4 className="text-start">General</h4>
              <ul
                className=" list-unstyled pt-1 m-0"
                onClick={() => {
                  setEditProfile(true);
                  setSection("general");
                }}
              >
                <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button className="border-0 d-flex align-items-center justify-content-between px-0 py-2">
                      <div className="notify-user d-flex  align-items-center ">
                        <div className="notification-message text-start d-flex  align-items-center justify-content-between w-100">
                          <p className="mb-0">Age</p>
                          <span className="right-data me-3">
                            {calculateAge()} Years Old
                          </span>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li>
                <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button className="border-0 d-flex align-items-center justify-content-between px-0 py-2">
                      <div className="notify-user d-flex  align-items-center ">
                        <div className="notification-message text-start d-flex  align-items-center justify-content-between w-100">
                          <p className="mb-0">Employment</p>
                          <span className="right-data me-3">
                            {userData?.employment}
                          </span>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li>
                <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button className="border-0 d-flex align-items-center justify-content-between px-0 py-2">
                      <div className="notify-user d-flex  align-items-center ">
                        <div className="notification-message text-start d-flex  align-items-center justify-content-between w-100">
                          <p className="mb-0">Location</p>
                          <span className="right-data me-3">
                            {userData?.country}
                          </span>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li>
                <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button className="border-0 d-flex align-items-center justify-content-between px-0 py-2">
                      <div className="notify-user d-flex  align-items-center ">
                        <div className="notification-message text-start d-flex  align-items-center justify-content-between w-100">
                          <p className="mb-0">Education</p>
                          <span className="right-data me-3">
                            {userData?.education}
                          </span>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
            {/* <div className="tab-boxes settings pt-3 pb-0 px-4 my-3">
              <h4 className="text-start">Visibility</h4>
              <ul className=" list-unstyled pt-1 m-0">
                <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button className="border-0 d-flex align-items-center justify-content-between px-0 py-2">
                      <div className="notify-user d-flex  align-items-center ">
                        <div className="notification-message text-start d-flex align-items-center justify-content-between w-100">
                          <p className="mb-0">
                            Private Network (toggle between public and private
                            network)
                          </p>
                          <span className="right-data me-3">Public</span>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li>
                <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button className="border-0 d-flex align-items-center justify-content-between px-0 py-2">
                      <div className="notify-user d-flex  align-items-center ">
                        <div className="notification-message text-start d-flex align-items-center justify-content-between w-100">
                          <p className="mb-0">
                            Profile visibility (show up in searches)
                          </p>
                          <span className="right-data me-3">Yes</span>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li>
                <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button className="border-0 d-flex align-items-center justify-content-between px-0 py-2">
                      <div className="notify-user d-flex  align-items-center ">
                        <div className="notification-message text-start d-flex align-items-center justify-content-between w-100">
                          <p className="mb-0">Find me by name</p>
                          <span className="right-data me-3">Yes</span>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li>
                <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button className="border-0 d-flex align-items-center justify-content-between px-0 py-2">
                      <div className="notify-user d-flex  align-items-center ">
                        <div className="notification-message text-start d-flex align-items-center justify-content-between w-100">
                          <p className="mb-0">Find me by email</p>
                          <span className="right-data me-3">Yes</span>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li>
                <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button className="border-0 d-flex align-items-center justify-content-between px-0 py-2">
                      <div className="notify-user d-flex  align-items-center ">
                        <div className="notification-message text-start d-flex align-items-center justify-content-between w-100">
                          <p className="mb-0">Find me by phone number</p>
                          <span className="right-data me-3">No</span>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li>
              </ul>
            </div> */}
          </div>
          <div
            className="tab-pane fade"
            id="v-pills-profile"
            role="tabpanel"
            aria-labelledby="v-pills-profile-tab"
          >
            <p className="stat-title mb-3 position-relative">
              Sign in & Security
            </p>
            <div className="tab-boxes settings pt-3 pb-0 px-4">
              <h4 className="text-start">Account access</h4>
              <ul className=" list-unstyled pt-1 m-0">
                {/* <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button className="border-0 d-flex align-items-center justify-content-between px-0 py-2">
                      <div className="notify-user d-flex  align-items-center w-100">
                        <div className="notification-message text-start d-flex align-items-center justify-content-between w-100">
                          <p className="mb-0">User Type (Free or Premium)</p>
                          <span className="right-data me-3">Premium user</span>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li> */}
                <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button className="border-0 d-flex align-items-center justify-content-between px-0 py-2">
                      <div className="notify-user d-flex  align-items-center w-100">
                        <div className="notification-message text-start d-flex align-items-center justify-content-between w-100">
                          <p className="mb-0">Two-step verification</p>
                          <span className="right-data me-3">Verified</span>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li>
                <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button
                      className="border-0 d-flex align-items-center justify-content-between px-0 py-2"
                      onClick={() => setShow(true)}
                    >
                      <div className="notify-user d-flex  align-items-center w-100">
                        <div className="notification-message text-start d-flex align-items-center justify-content-between w-100">
                          <p className="mb-0">Change Password</p>
                          <span className="right-data me-3">***********</span>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li>
                {/* <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button className="border-0 d-flex align-items-center justify-content-between px-0 py-2">
                      <div className="notify-user d-flex  align-items-center w-100">
                        <div className="notification-message text-start d-flex align-items-center justify-content-between w-100">
                          <p className="mb-0">Change Email</p>
                          <span className="right-data me-3">{user?.email}</span>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li> */}
              </ul>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="v-pills-messages"
            role="tabpanel"
            aria-labelledby="v-pills-messages-tab"
          >
            <p className="stat-title mb-3 position-relative">Data Privacy</p>
            <div className="tab-boxes settings pt-4 pb-0 px-4">
              <h4 className="text-start">Privacy</h4>
              <ul className=" list-unstyled pt-1 m-0">
                <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button className="border-0 d-flex align-items-center justify-content-between px-0 py-2">
                      <div className="notify-user d-flex  align-items-center w-100">
                        <div className="notification-message text-start d-flex align-items-center justify-content-between w-100">
                          <p className="mb-0">
                            Search history (6 months, how much should search
                            history go back)
                          </p>
                          <span className="right-data me-3">2 Months</span>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li>
                <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button className="border-0 d-flex align-items-center justify-content-between px-0 py-2">
                      <div className="notify-user d-flex  align-items-center w-100">
                        <div className="notification-message text-start d-flex align-items-center justify-content-between w-100">
                          <p className="mb-0">Invitations</p>
                          <span className="right-data me-3">Yes</span>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li>
                <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button className="border-0 d-flex align-items-center justify-content-between px-0 py-2">
                      <div className="notify-user d-flex  align-items-center w-100">
                        <div className="notification-message text-start d-flex align-items-center justify-content-between w-100">
                          <p className="mb-0">Messages</p>
                          <span className="right-data me-3">Yes</span>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li>
                <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button className="border-0 d-flex align-items-center justify-content-between px-0 py-2">
                      <div className="notify-user d-flex  align-items-center w-100">
                        <div className="notification-message text-start d-flex align-items-center justify-content-between w-100">
                          <p className="mb-0">Message nudges</p>
                          <span className="right-data me-3">No</span>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li>
                <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button className="border-0 d-flex align-items-center justify-content-between px-0 py-2">
                      <div className="notify-user d-flex  align-items-center w-100">
                        <div className="notification-message text-start d-flex align-items-center justify-content-between w-100">
                          <p className="mb-0">
                            Automated detection of harmful content
                          </p>
                          <span className="right-data me-3">Yes</span>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
            <div className="tab-boxes settings pt-4 pb-0 px-4 my-3">
              <h4 className="text-start">Show profile</h4>
              <ul className=" list-unstyled pt-1 m-0">
                <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button className="border-0 d-flex align-items-center justify-content-between px-0 py-2">
                      <div className="notify-user d-flex  align-items-center w-100">
                        <div className="notification-message text-start d-flex align-items-center justify-content-between w-100">
                          <p className="mb-0">Show profile on apply</p>
                          <span className="right-data me-3">Yes</span>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li>
                <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button className="border-0 d-flex align-items-center justify-content-between px-0 py-2">
                      <div className="notify-user d-flex  align-items-center w-100">
                        <div className="notification-message text-start d-flex align-items-center justify-content-between w-100">
                          <p className="mb-0">Show profile on message</p>
                          <span className="right-data me-3">Yes</span>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li>
                <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button className="border-0 d-flex align-items-center justify-content-between px-0 py-2">
                      <div className="notify-user d-flex  align-items-center w-100">
                        <div className="notification-message text-start d-flex align-items-center justify-content-between w-100">
                          <p className="mb-0">Messages</p>
                          <span className="right-data me-3">Yes</span>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="v-pills-settings"
            role="tabpanel"
            aria-labelledby="v-pills-settings-tab"
          >
            <p className="stat-title mb-3 position-relative">Notifications</p>
            <div className="tab-boxes settings pt-4 pb-0 px-4">
              <h4 className="text-start">Notifications</h4>
              <ul className=" list-unstyled pt-1 m-0">
                <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button
                      className="border-0 d-flex align-items-center justify-content-between px-0 py-2"
                    >
                      <div className="notify-user d-flex  align-items-center w-100">
                        <div className="notification-message text-start d-flex align-items-center justify-content-between w-100">
                          <p className="mb-0">Messages</p>
                          {/* <span className="right-data me-3">
                            {userData?.message_notification ?? "Off"}
                          </span> */}
                          <div className="form-check form-switch">
                            <input className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="message_notification"
                              checked={userData?.message_notification === 'On' ? true : false}
                              onChange={(e) =>
                                updateNotification({
                                  token: token,
                                  message_notification:
                                    e.target.checked ? "On" : "Off",
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li>
                <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button
                      className="border-0 d-flex align-items-center justify-content-between px-0 py-2"
                    >
                      <div className="notify-user d-flex  align-items-center w-100">
                        <div className="notification-message text-start d-flex align-items-center justify-content-between w-100">
                          <p className="mb-0">Comments</p>
                          {/* <span className="right-data me-3">
                            {userData?.comment_notification ?? "Off"}
                          </span> */}
                          <div className="form-check form-switch">
                            <input className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="comment_notification"
                              checked={userData?.comment_notification === 'On' ? true : false}
                              onChange={(e) =>
                                updateNotification({
                                  token: token,
                                  comment_notification:
                                    e.target.checked ? "On" : "Off",
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li>
                {/* <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button className="border-0 d-flex align-items-center justify-content-between px-0 py-2">
                      <div className="notify-user d-flex  align-items-center w-100">
                        <div className="notification-message text-start d-flex align-items-center justify-content-between w-100">
                          <p className="mb-0">Pages</p>
                          <span className="right-data me-3">On</span>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li> */}
                <li>
                  <div className="d-flex message-one m-0 px-0 py-0 align-items-center justify-content-between">
                    <button
                      className="border-0 d-flex align-items-center justify-content-between px-0 py-2"
                    >
                      <div className="notify-user d-flex  align-items-center w-100">
                        <div className="notification-message text-start d-flex align-items-center justify-content-between w-100">
                          <p className="mb-0">Events</p>
                          <div className="form-check form-switch">
                            <input className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="event_notification"
                              checked={userData?.event_notification === 'On' ? true : false}
                              onChange={(e) =>
                                updateNotification({
                                  token: token,
                                  event_notification:
                                    e.target.checked ? "On" : "Off",
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="horizontal-icon text-end">
                        <img src={rarrow} />
                      </div>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body px-3">
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3 text-start">
                <label htmlFor="exampleInputEmail1" className="form-label m-0">
                  Current Password
                </label>
                <input
                  type="password"
                  value={formik.values.currentPassword}
                  onChange={formik.handleChange}
                  name="currentPassword"
                  className="form-control"
                  id="firstname"
                  aria-describedby="firstname"
                />
                <span style={{ color: "red" }}>
                  {formik.touched.currentPassword &&
                    formik.errors.currentPassword ? (
                    <div>{formik.errors.currentPassword}</div>
                  ) : null}{" "}
                </span>
              </div>
              <div className="mb-3 text-start">
                <label htmlFor="exampleInputEmail1" className="form-label m-0">
                  New Password
                </label>
                <input
                  type="password"
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  name="newPassword"
                  className="form-control"
                  id="newPassword"
                  aria-describedby="firstname"
                />
                <span style={{ color: "red" }}>
                  {formik.touched.newPassword && formik.errors.newPassword ? (
                    <div>{formik.errors.newPassword}</div>
                  ) : null}{" "}
                </span>
              </div>
              {/* <div className="mb-3 text-start">
                <label htmlFor="exampleInputEmail1" className="form-label m-0">
                  Confirm Password
                </label>
                <input
                  type="text"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  name="confirmPassword"
                  className="form-control"
                  id="confirmPassword"
                  aria-describedby="firstname"
                />
                <span style={{ color: "red" }}>
                  {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword ? (
                    <div>{formik.errors.confirmPassword}</div>
                  ) : null}{" "}
                </span>
              </div> */}
              <div className="file-input">
                <button type="submit" className="btn primary-btn">
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <EditProfileModal
        show={editProfile}
        onClose={() => setEditProfile(false)}
        section={section}
      />
    </>
  );
}
