import React, { useEffect, useState } from "react";
import strokefire from "../Components/Assets/images/stroke-fire.svg";
import popularshow from "../Components/Assets/images/popularshow.svg";
import singlestar from "../Components/Assets/images/white-star.svg";
import toprated from "../Components/Assets/images/top-rated.svg";
import blog from "../Components/Assets/images/blog.png";
import thomas from "../Components/Assets/images/thomas.png";
import options from "../Components/Assets/images/options.svg";
import click from "../Components/Assets/images/clicked.svg";
import comment from "../Components/Assets/images/chat.svg";
import send from "../Components/Assets/images/share.svg";
import bookmark from "../Components/Assets/images/bookm.svg";
import user from "../Components/Assets/images/user-2.png";
import horiopt from "../Components/Assets/images/hz-option.svg";
import like from "../Components/Assets/images/like.svg";
import edit from "../Components/Assets/images/edit.svg";
import trash from "../Components/Assets/images/trash-bin.png";
import flag from "../Components/Assets/images/flag.png";
import logo from "../Components/Assets/images/logo.svg";
import * as Yup from "yup";
import Spinner from "react-bootstrap/Spinner";
import dislike from "../Components/Assets/images/dislike.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

import { useDispatch, useSelector } from "react-redux";
import {
  getPostsThunk,
  getTopRatedPostsThunk,
  postGetsByUserThunk,
  createPostPaymentThunk,
  createPostBookmarkThunk,
  deletePostBookmarkThunk,
  reportPostThunk,
} from "../Features/posts/postThunk";

import { createNotificationThunk } from "../Features/notification/notificationThunk";

import { authSelector } from "../Features/auth/authSelector";
import { postSelector } from "../Features/posts/postsSelector";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Formik, useFormik } from "formik";
import { createMessageThunk } from "../Features/message/messageThunk";

import StripeCheckout from "react-stripe-checkout";

import {
  Button,
  Card,
  Dropdown,
  Form,
  InputGroup,
  Modal,
  Placeholder,
} from "react-bootstrap";
import toast from "react-hot-toast";
import mic from "../Components/Assets/images/mic.svg";
import gallery from "../Components/Assets/images/gallery.svg";
import sendbtn from "../Components/Assets/images/send-btn.svg";
import Rating from "./Posts/Rating";

import EditPost from "./Posts/EditPost";
import moment from "moment";
import Loader from "../Common/Loader";

export default function Posttabs(props) {
  const { userPosts, topRatedPosts } = useSelector(postSelector);
  const { userData, token } = useSelector(authSelector);
  const [seeMore, setSeeMore] = useState(3);
  const [showCommentbox, setShowCommentbox] = useState("");
  const [replyCommentBox, setReplyCommentBox] = useState("");
  const [replyCommentBoxForm, setReplyCommentBoxForm] = useState("");
  const [replyCommentBoxShow, setReplyCommentBoxShow] = useState("");
  const [repliesOfComment, setRepliesOfComment] = useState();
  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  const [isTopRated, setIsTopRated] = useState(false);
  const [seeMoreDes, setSeeMoreDes] = useState(120);
  const [isLoading, setIsLoading] = useState(false);
  const [webinar, setWebinar] = useState(null);
  const [filesName, setFilesName] = useState(null);
  const [attachments, setAttachments] = useState("");
  const [editPost, setEditPost] = useState({});
  const [likeShow, setLikeShow] = useState(false);
  const [likeUsers, setLikeUsers] = useState([]);
  const [postNotFound, setPostNotFound] = useState(true);

  const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  function logoutUser() {
    // dispatch(setIsLoggedIn());
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/");
  }

  function checkLoginToken() {
    let token = JSON.parse(localStorage.getItem("token"));
    if (!token) {
      logoutUser();
    } else {
      return token;
    }
  }

  const callApi = async (token) => {
    const handleErrors = (error) => {
      if (error?.response?.status === 401) {
        navigate("/Login");
        localStorage.removeItem("token");
      }
      console.error("Error:", error);
    };
    try {
      if (isTopRated) {
        const res = await dispatch(
          getTopRatedPostsThunk({
            token: token,
            hashtag: props.hashtags.join(","),
            bookmark: props.bookmark,
            postType: props.postType,
            page: props.page,
          })
        );

        if (res?.payload?.status === 200) {
          ///console.log("res?.payload", res?.payload);
          // if(res?.payload?.data)
          // Handle success for top-rated posts
        } else {
          handleErrors(res?.payload?.error);
        }
      } else {
        const res = await dispatch(
          getPostsThunk({
            token: token,
            hashtag: props.hashtags.join(","),
            bookmark: props.bookmark,
            postType: props.postType,
            page: props.page,
          })
        );
        if (res?.payload?.status === 200) {
          if (res?.payload?.data?.body?.data?.length === 0) {
            setPostNotFound(false);
          }
          //console.log("res?.payload", res?.payload);
          // Handle success for regular posts
          setIsLoading(false);
        } else {
          handleErrors(res?.payload?.error);
        }
      }
    } catch (error) {
      // Handle errors outside the timeout
      handleErrors(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleReportComment = async (comment_id) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/post/reportComment`,
        { comment_id },
        { headers: { Authorization: token } }
      );

      if (res?.status == 200) {
        callApi(token);
        toast.success(res?.data?.message, {
          position: "bottom-left",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handaleEditComment = (_id) => {
    setShowCommentbox(_id);
  };
  useEffect(() => {
    callApi(token);
  }, [isTopRated, props.hashtags, props.bookmark, props.postType]);

  const handleLikeAndUnlike = async (data, post) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/post/likeUnlike`,
        {
          post_id: data.id,
          status: data.status,
        },
        {
          headers: {
            Authorization: checkLoginToken(),
          },
        }
      );

      if (res?.status == 200) {
        if (post?.user_id !== userData._id) {
          let status = data.status === true ? "liked" : "unliked";
          const apiNotificationResponse = await dispatch(
            createNotificationThunk({
              userId: post?.user_id,
              token: token,
              content:
                userData?.firstName +
                " " +
                userData?.lastName +
                " has " +
                status +
                " your post " +
                post?.title,
              parent_id: post?._id,
              type: "post",
            })
          );
        }
        //await dispatch(getPostsThunk(checkLoginToken()));
        callApi(token);
      } else {
      }
    } catch (error) {
      console.log({ error });
    }
  };
  const handleDeletePost = async (_id) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/post/delete/${_id}`,

        { headers: { Authorization: token } }
      );
      if (res?.status == 200) {
        toast.success(res?.data?.message, {
          position: "bottom-left",
        });

        callApi(token);
      } else {
        toast.error(res?.data?.message, {
          position: "bottom-left",
        });
      }
    } catch (error) {
      console.log(error);

      toast.error(error?.response?.data?.message, {
        position: "bottom-left",
      });
    }
  };
  const handleDeleteComment = async (_id) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/post/deleteComment/${_id}`,

        { headers: { Authorization: token } }
      );
      if (res?.status == 200) {
        toast.success(res?.data?.message, {
          position: "bottom-left",
        });
        //await dispatch(getPostsThunk(checkLoginToken()));
        callApi(token);
      } else {
        toast.error(res?.data?.message, {
          position: "bottom-left",
        });
      }
    } catch (error) {
      console.log(error);

      toast.error(error?.response?.data?.message, {
        position: "bottom-left",
      });
    }
  };

  const handleLike = async (item) => {
    try {
      const likeAndUlikeCommentsRes = await axios.post(
        `${process.env.REACT_APP_API_URL}/post/likeAndUlikeComments`,
        {
          comment_id: item?._id,
          post_id: item?.post_id,
          status: !item?.commentLiked,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (likeAndUlikeCommentsRes?.status == 200) {
        //await dispatch(getPostsThunk(checkLoginToken()));
        callApi(token);
        // handleReplyComment(item);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRatingChange = async (value) => {
    callApi(token);
  };
  const handleReplyComment = async (ele) => {
    try {
      const commentsRepliyres = await axios.post(
        `${process.env.REACT_APP_API_URL}/post/commentsReplies`,
        {
          parent_id: ele?._id,
          post_id: ele?.post_id,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (commentsRepliyres?.status === 200) {
        setRepliesOfComment(commentsRepliyres?.data?.body);
        setReplyCommentBox(ele?._id);
        setReplyCommentBoxShow(ele?.post_id);
        setReplyCommentBoxForm(ele?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewPeople = (_id) => {
    navigate(`/results/view-profile/${_id}`);
  };

  const handleChangePostImage = async (e) => {
    let token = JSON.parse(localStorage.getItem("token"));

    const files = e.target.files; // Array of selected files
    const imageFiles = [];

    for (const file of files) {
      let formData = new FormData();
      formData.append("folder", "demo");
      formData.append("file", file);

      try {
        let resp = await axios.post(
          `${process.env.REACT_APP_API_URL}/post/uploadFile`,
          formData,
          {
            headers: {
              Authorization: token,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (resp?.data?.success) {
          imageFiles.push(resp?.data?.body?.filename);
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          navigate("/Login");
          localStorage.removeItem("token");
        }
        console.log(error);
      }
    }
    setSelectedImage([...selectedImage, ...imageFiles]);
  };

  const formik = useFormik({
    initialValues: {
      description: editPost?.description,
      postType: editPost?.postType,
      opening: editPost?.opening,
      title: editPost?.title,
      salary: editPost?.salary,
      link: editPost?.link,
      _id: editPost?._id,
    },

    onSubmit: async (values, { resetForm }) => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/post/update`,
          { ...values, filename: selectedImage },
          {
            headers: {
              Authorization: token,
            },
          }
        );

        if (res?.status === 200) {
          setShow(false);
          callApi(token);
          resetForm();
          setSelectedImage([]);
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          navigate("/Login");
          localStorage.removeItem("token");
        }
        console.log(error);
      }
    },
  });

  const handleApplyPost = async (post) => {
    try {
      const apiResponse = await dispatch(
        createMessageThunk({
          token: token,
          sender_id: userData?._id,
          receiver_id: post?.user_id,
          content: "JOBAPPLIED",
          post_id: post?._id,
          image: "",
          attachment: "",
        })
      );

      if (apiResponse?.payload?.status === 200) {
        if (
          post?.user_id !== userData?._id &&
          post?.user?.message_notification === "On"
        ) {
          const apiNotificationResponse = await dispatch(
            createNotificationThunk({
              userId: post?.user_id,
              token: token,
              content:
                userData?.firstName +
                " " +
                userData?.lastName +
                " has applied on job " +
                post?.title,
              parent_id: post?._id,
              type: "post",
            })
          );
        }

        toast.success("Job applied successfully", {
          position: "bottom-left",
        });
        navigate("/Messages", {
          state: {
            defaultUser: {
              _id: post?.user_id,
              firstName: post?.user?.firstName,
              lastName: post?.user?.lastName,
            },
          },
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: "bottom-left",
      });
    }
  };

  const onToken = async (stripeToken, webinar) => {
    const apiResponse = await dispatch(
      createPostPaymentThunk({
        token: token,
        stripeToken: stripeToken.id,
        email: stripeToken.email,
        parent_id: webinar?._id,
        amount: 1000,
        productType: "webinar",
        description: "payment for webinar " + webinar?.title,
      })
    );

    if (apiResponse?.payload?.status === 200) {
      //await dispatch(getPostsThunk(checkLoginToken()));
      callApi(token);
    }
    // You can handle the token received from Stripe here.
    // Typically, you'll send this token to your server for payment processing.
  };

  const handlePostAttachments = (e) => {
    let files = [];
    let attachments = [];
    for (const file of e.target.files) {
      files.push(file.name);
      attachments.push(file);
    }
    setFilesName(files.join(", "));
    setAttachments(attachments);
  };

  const handleUpdata = () => {
    callApi(token);
  };

  const handlePostBookmark = async (postId) => {
    try {
      const apiResponse = await dispatch(
        createPostBookmarkThunk({
          token: token,
          post_id: postId,
        })
      );

      if (apiResponse?.payload?.status === 200) {
        toast.success(apiResponse?.payload?.data.message, {
          position: "bottom-left",
        });
        callApi(token);
      }
    } catch (error) {
      toast.error(error.message, {
        position: "bottom-left",
      });
    }
  };

  const removePostBookmark = async (bookmarkId) => {
    try {
      const apiResponse = await dispatch(
        deletePostBookmarkThunk({
          token: token,
          bookmarkId: bookmarkId,
        })
      );

      if (apiResponse?.payload?.status === 200) {
        toast.success(apiResponse?.payload?.data.message, {
          position: "bottom-left",
        });
        callApi(token);
      }
    } catch (error) {
      toast.error(error.message, {
        position: "bottom-left",
      });
    }
  };

  const handleReportPost = async (post_id) => {
    try {
      const apiResponse = await dispatch(
        reportPostThunk({
          token: token,
          post_id: post_id,
        })
      );

      if (apiResponse?.payload?.status === 200) {
        toast.success(apiResponse?.payload?.data.message, {
          position: "bottom-left",
        });
        callApi(token);
      }
    } catch (error) {
      toast.error(error.message, {
        position: "bottom-left",
      });
    }
  };

  return (
    <div className="side-tab second-tab post-tabs shadow-none">
      <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active border-0 justify-content-center align-items-center d-flex"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected="true"
            onClick={() => {
              setIsTopRated(false);
            }}
          >
            Popular <img className="ms-2 hide pb-1" src={strokefire} />
            <img className="ms-2 show pb-1" src={popularshow} />
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link border-0 justify-content-center align-items-center d-flex"
            id="profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#profile"
            type="button"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
            onClick={() => {
              setIsTopRated(true);
            }}
          >
            Top Rated <img className="ms-2 hide pb-1" src={singlestar} />
            <img className="ms-2 show pb-1" src={toprated} />
          </button>
        </li>
      </ul>
      {isLoading ? (
        <>
          <div className="card  rounded-4 py-4 px-4 mt-4">
            <div className="user-field d-flex align-items-center justify-content-between position-relative">
              <div className="user-thumb d-flex align-items-center w-100">
                <Placeholder
                  as="h3"
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    backgroundColor: "#dbdbdb",
                  }}
                  animation="wave"
                  className="me-2"
                />

                <div className="w-75">
                  <Placeholder as={Card.Title} animation="glow">
                    <Placeholder xs={6} />
                  </Placeholder>
                  <Placeholder as={Card.Text} animation="glow">
                    <Placeholder xs={7} />
                  </Placeholder>
                </div>
              </div>
              <Placeholder
                as={Card.Text}
                animation="glow"
                style={{ width: "15%" }}
              >
                <Placeholder xs={3} className="me-1" />
                <Placeholder xs={3} className="me-1" />
                <Placeholder xs={3} />
              </Placeholder>
            </div>

            <Placeholder as="p" animation="glow" className="w-100">
              <Placeholder xs={12} />
            </Placeholder>

            <Placeholder
              style={{
                width: "100%",
                height: "380px",
                borderRadius: "10px",
                backgroundColor: "rgb(225 225 225)",
              }}
              animation="wave"
            />

            <div className="button-group d-flex justify-content-between align-items-center mt-3">
              <Placeholder as={Card.Title} animation="glow" className="w-50">
                <Placeholder xs={2} className="me-2" />
                <Placeholder xs={2} className="me-2" />
                <Placeholder xs={2} />
              </Placeholder>
              <Placeholder
                as={Card.Title}
                animation="glow"
                className="w-50 text-end"
              >
                <Placeholder xs={1} className="me-2" />
              </Placeholder>
            </div>
            <Placeholder as={Card.Title} animation="glow" className="w-25">
              <Placeholder xs={12} className="me-2" />
            </Placeholder>

            <div className="user-thumb d-flex align-items-center">
              <Placeholder
                as="h3"
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  backgroundColor: "#dbdbdb",
                }}
                animation="wave"
                className="me-2 mb-0"
              />

              <Placeholder
                as={InputGroup}
                animation="glow"
                style={{ width: "90%", height: "35px" }}
              >
                <Placeholder xs={12} />
              </Placeholder>
            </div>

            {/* <CommentsForm values={item} /> */}
          </div>
        </>
      ) : (
        <>
          <div className="tab-content border-0  mt-0" id="myTabContent">
            {userPosts?.length > 0 ? (
              userPosts?.map((item, index) => {
                return (
                  <div
                    key={item?._id}
                    className="tab-pane border-0 show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="card border-0 py-4 px-4">
                      <div className="user-field d-flex align-items-center justify-content-between position-relative">
                        <div className="user-thumb d-flex align-items-center mb-3">
                          {item?.user?.image ? (
                            <img
                              className="me-3"
                              src={`${process.env.REACT_APP_API_URL}/uploads/users/${item?.user?.image}`}
                            />
                          ) : (
                            <div
                              className="me-3 avatar-text d-flex align-items-center justify-content-center"
                              style={{ background: "grey" }}
                            >
                              <span className="text-uppercase text-white">
                                {item?.user?.firstName?.slice(0, 1)}{" "}
                              </span>
                            </div>
                          )}

                          <div className="user-details">
                            <h3
                              className="text-start mb-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleViewPeople(item?.user_id)}
                            >
                              {item?.user?.firstName +
                                " " +
                                item?.user?.lastName}
                            </h3>
                            <p className="text-start mb-0">
                              {item?.user?.intro_details?.slice(0, 40)} ...
                            </p>
                            <p className="text-start mb-0">
                              {moment(item?.createdAt).fromNow()}
                            </p>
                          </div>
                        </div>
                        {/* rating */}

                        <div className="rating-box">
                          <Rating
                            value={item?.ratingOfPost}
                            item={item}
                            onChange={handleRatingChange}
                          />
                        </div>

                        <Dropdown>
                          <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <img src={options} />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {userData?._id === item?.user_id ? (
                              <>
                                <Dropdown.Item
                                  onClick={() => {
                                    setEditPost(item);
                                    setShow(true);
                                  }}
                                >
                                  Edit post
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleDeletePost(item._id)}
                                >
                                  Delete post
                                </Dropdown.Item>
                              </>
                            ) : (
                              ""
                            )}
                            <Dropdown.Item
                              onClick={() => handleReportPost(item._id)}
                            >
                              <img
                                src={flag}
                                className="me-2 report-post-icon"
                              />
                              Report post
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      {(item?.postType == "general" ||
                        item?.postType == "webinar") && (
                          <>
                            <div className="post-description text-start py-3">
                              {item?.description?.slice(0, seeMoreDes)}

                              {seeMoreDes === item?.description?.length ? (
                                <Link
                                  className="common-btn"
                                  style={{
                                    display:
                                      item?.description?.length > 120
                                        ? ""
                                        : "none",
                                  }}
                                  onClick={() => setSeeMoreDes(120)}
                                >
                                  Less more
                                </Link>
                              ) : (
                                <Link
                                  className="common-btn"
                                  onClick={() =>
                                    setSeeMoreDes(item?.description?.length)
                                  }
                                >
                                  {item?.description?.length > 120
                                    ? "See more "
                                    : ""}
                                </Link>
                              )}
                            </div>{" "}
                            <Swiper
                              pagination={true}
                              modules={[Pagination]}
                              className="mySwiper"
                            >
                              {item?.attachments?.map((ele) => {
                                return (
                                  <SwiperSlide key={ele?._id}>
                                    <img
                                      className="uploaded-image"
                                      src={`${process.env.REACT_APP_API_URL}/uploads/post/${ele?.filename}`}
                                    />
                                  </SwiperSlide>
                                );
                              })}
                            </Swiper>
                          </>
                        )}

                      {item?.postType == "webinar" && (
                        <>
                          <div className="mt-3">
                            {item?.payment?.status === "completed" ? (
                              <a
                                href={item?.link}
                                target="_blank"
                                className="save-changes btn btn-primary px-5"
                              >
                                Join
                              </a>
                            ) : (
                              <StripeCheckout
                                token={(e) => onToken(e, item)}
                                stripeKey={publishableKey}
                                amount={1000} // Amount in cents (e.g., $10)
                                name={item?.title}
                                description={item?.description}
                                image={
                                  item?.user?.image
                                    ? `${process.env.REACT_APP_API_URL}/uploads/users/${item?.user?.image}`
                                    : logo
                                }
                                label="Join"
                              />
                            )}
                          </div>
                        </>
                      )}

                      {item?.postType == "job" && (
                        <div className="job-card">
                          <h4 className="job-title">{item?.title}</h4>
                          <div className="d-flex gap-2 mb-2">
                            <p className="post-description mb-0">
                              No. of openings:
                            </p>
                            <p className="post-description fw-semibold mb-0">
                              {item?.opening}
                            </p>
                          </div>
                          <div className="d-flex gap-2">
                            <p className="post-description mb-0">Salary:</p>
                            <p className="post-description fw-semibold mb-0">
                              {item?.salary}
                            </p>
                          </div>
                          <div className="post-description text-start py-3">
                            {item?.description?.slice(0, seeMoreDes)}

                            {seeMoreDes === item?.description?.length ? (
                              <Link
                                className="common-btn"
                                style={{
                                  display:
                                    item?.description?.length > 500
                                      ? ""
                                      : "none",
                                }}
                                onClick={() => setSeeMoreDes(500)}
                              >
                                Less more
                              </Link>
                            ) : (
                              <Link
                                className="common-btn"
                                onClick={() =>
                                  setSeeMoreDes(item?.description?.length)
                                }
                              >
                                {item?.description?.length > 500
                                  ? "See more "
                                  : ""}
                              </Link>
                            )}
                          </div>{" "}
                          <div>
                            <button
                              type="button"
                              className="save-changes btn btn-primary px-5"
                              onClick={() => handleApplyPost(item)}
                            >
                              Apply Now
                            </button>
                          </div>
                        </div>
                      )}

                      <div className="button-group d-flex justify-content-between align-items-center mt-3">
                        <div className="set-one d-flex align-items-end">
                          <a className="me-4 position-relative">
                            {/* <div>
                            <svg
                              width="18"
                              height="26"
                              viewBox="0 0 18 26"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17.5888 24.7309L9.92006 8.83386C9.8136 8.61357 9.58937 8.47254 9.34267 8.47406C9.0962 8.4749 8.87052 8.61675 8.76214 8.83887L0.95551 24.8029C0.834278 25.0505 0.883067 25.3457 1.07612 25.5388C1.07816 25.5408 1.08043 25.5431 1.08247 25.5451C1.28076 25.7375 1.5812 25.7806 1.828 25.6511L9.285 21.7426L16.7084 25.5867C16.9541 25.714 17.2552 25.6685 17.4549 25.4742C17.6553 25.2797 17.709 24.9804 17.5888 24.7309ZM9.58829 20.4445C9.40255 20.3479 9.18021 20.3491 8.9934 20.4471L2.96972 23.6044L9.33351 10.591L15.5849 23.5498L9.58829 20.4445ZM12.0866 5.46457L14.3722 3.17894C14.6246 2.9266 15.0324 2.92484 15.2825 3.175C15.5327 3.42516 15.5309 3.83298 15.2786 4.08531L12.993 6.37095C12.7406 6.62328 12.3328 6.62505 12.0826 6.37489C11.8325 6.12473 11.8343 5.71691 12.0866 5.46457ZM10.003 4.60927C10.0014 4.96475 9.71208 5.25411 9.3566 5.25565C9.17864 5.25642 9.01828 5.1852 8.90226 5.06918C8.78625 4.95317 8.71503 4.79281 8.7158 4.61484L8.72973 1.39642C8.73127 1.04095 9.02063 0.751584 9.37611 0.750046C9.73158 0.748507 10.0185 1.03537 10.0169 1.39085L10.003 4.60927ZM5.71045 6.40248L3.44452 4.13654C3.19436 3.88638 3.19612 3.47856 3.44846 3.22623C3.70079 2.97389 4.10861 2.97213 4.35877 3.22229L6.6247 5.48822C6.87486 5.73838 6.8731 6.1462 6.62076 6.39853C6.36843 6.65087 5.96061 6.65263 5.71045 6.40248ZM5.47788 9.13437C5.47634 9.48985 5.18698 9.77921 4.8315 9.78075L1.61308 9.79468C1.43512 9.79545 1.27476 9.72424 1.15874 9.60822C1.04272 9.4922 0.971505 9.33184 0.972276 9.15388C0.973815 8.7984 1.26318 8.50904 1.61865 8.5075L4.83707 8.49357C5.19255 8.49203 5.47919 8.77867 5.47788 9.13437ZM13.2019 9.10093C13.2034 8.74546 13.4928 8.4561 13.8483 8.45456L17.0667 8.44062C17.4222 8.43908 17.709 8.72595 17.7075 9.08143C17.706 9.43691 17.4166 9.72627 17.0611 9.72781L13.8427 9.74174C13.6647 9.74251 13.5044 9.67129 13.3884 9.55528C13.2723 9.43926 13.2014 9.27867 13.2019 9.10093Z"
                                fill="black"
                              />
                            </svg>
                          </div> */}
                            <img
                              className={item?.isLiked ? "like_btn_click" : ""}
                              onClick={() =>
                                handleLikeAndUnlike(
                                  {
                                    id: item?._id,
                                    status: !item?.isLiked,
                                  },
                                  item
                                )
                              }
                              src={click}
                            />
                            <span
                              onClick={() => {
                                setLikeUsers(item);
                                setLikeShow(
                                  item?.likes?.length > 0 ? true : false
                                );
                              }}
                              className="cursor-pointer"
                            >
                              {item?.likes?.length}
                            </span>
                          </a>
                          {/* <a className="me-3 ms-2 position-relative">
                          <img src={comment} />
                        </a> */}
                          {/* <a className="me-2 ms-1 position-relative">
                          <img src={send} />
                        </a> */}
                        </div>
                        <div className="set-one">
                          {item?.bookmark ? (
                            <span
                              onClick={() =>
                                removePostBookmark(item?.bookmark?._id)
                              }
                              className="bookmarked"
                            >
                              <img src={bookmark} />
                            </span>
                          ) : (
                            <span onClick={() => handlePostBookmark(item?._id)}>
                              <img src={bookmark} />
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="comments text-start my-3">
                        <h4 className="position-relative">
                          Comments{" "}
                          <span className="round-yellow">
                            {item?.comments?.length}
                          </span>
                        </h4>

                        {item?.comments?.slice(0, seeMore)?.map((ele) => {
                          return (
                            <div key={ele?._id}>
                              {showCommentbox == ele?._id ? (
                                <div
                                  className="d-flex post-comment-field"
                                  key={ele?._id}
                                >

                                  <div className="user-field">
                                    {ele?.user?.image ? (
                                      <img
                                        className="user"
                                        src={`${process.env.REACT_APP_API_URL}/uploads/users/${ele?.user?.image}`}
                                      />
                                    ) : (
                                      <div
                                        className="me-3 avatar-text d-flex align-items-center justify-content-center"
                                        style={{ background: "grey" }}
                                      >
                                        <span className="text-uppercase text-white">
                                          {ele?.user?.firstName?.slice(
                                            0,
                                            1
                                          )}{" "}
                                        </span>
                                      </div>
                                    )}
                                  </div>

                                  {/* <div className="user-field">
                                    <img src={thomas} />
                                  </div> */}
                                  <div className="right-comment-form ms-4">
                                    <h5>{ele?.user?.firstName} </h5>
                                    <p>
                                      {ele?.user?.intro_details?.slice(
                                        0,
                                        25
                                      )}
                                      ...
                                    </p>

                                    {/* Edit comments */}
                                    <Formik
                                      key={index}
                                      initialValues={{
                                        comment: ele?.comment,
                                      }}
                                      onSubmit={async (
                                        values,
                                        { resetForm }
                                      ) => {
                                        try {
                                          const resComment = await axios.post(
                                            `${process.env.REACT_APP_API_URL}/post/addEditComment`,
                                            {
                                              ...values,
                                              post_id: ele?.post_id,
                                              comment_id: ele?._id,
                                            },
                                            {
                                              headers: {
                                                Authorization: token,
                                              },
                                            }
                                          );

                                          console.log(resComment)

                                          if (resComment?.status == 200) {

                                            if (
                                              ele?.user_id !==
                                              userData?._id &&
                                              ele?.user
                                                ?.comment_notification ===
                                              "On"
                                            ) {
                                              const apiNotificationResponse =
                                                await dispatch(
                                                  createNotificationThunk(
                                                    {
                                                      userId:
                                                        ele?.user_id,
                                                      token: token,
                                                      content:
                                                        userData?.firstName +
                                                        " " +
                                                        userData?.lastName +
                                                        " has comment on your post",
                                                      parent_id:
                                                        ele?._id,
                                                      type: "comment",
                                                    }
                                                  )
                                                );
                                            }

                                            callApi(token);
                                            resetForm();
                                            setShowCommentbox("");
                                          }
                                        } catch (error) {
                                          console.log(error);
                                        }
                                      }}
                                    >
                                      {({
                                        values,
                                        handleChange,
                                        handleSubmit,
                                      }) => (
                                        <form onSubmit={handleSubmit}>
                                          <input
                                            type="text"
                                            name="comment"
                                            placeholder="add a comment...."
                                            value={values.comment}
                                            onChange={handleChange}
                                          />
                                          <div>
                                            <button
                                              type="submit"
                                              className="btn post-save me-3"
                                            >
                                              Save changes
                                            </button>
                                            <button
                                              className="btn post-cancel"
                                              type="button"
                                              onClick={() =>
                                                setShowCommentbox("")
                                              }
                                            >
                                              Cancel
                                            </button>
                                          </div>
                                        </form>
                                      )}
                                    </Formik>
                                  </div>
                                </div>
                              ) : (
                                <div key={item._id}>
                                  <div className="d-flex message-one grey-cmnt justify-content-between my-3">
                                    <div className="user-info me-1">
                                      {ele?.user?.image ? (
                                        <img
                                          className="user"
                                          src={`${process.env.REACT_APP_API_URL}/uploads/users/${ele?.user?.image}`}
                                        />
                                      ) : (
                                        <div
                                          className="me-3 avatar-text d-flex align-items-center justify-content-center"
                                          style={{ background: "grey" }}
                                        >
                                          <span className="text-uppercase text-white">
                                            {ele?.user?.firstName?.slice(
                                              0,
                                              1
                                            )}{" "}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                    {/* comment user name */}
                                    <div className="sr-message">
                                      <b>
                                        {" "}
                                        <p
                                          className="mb-0"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleViewPeople(ele?.user_id)
                                          }
                                        >
                                          {ele?.user?.firstName}
                                        </p>
                                      </b>
                                      <p className="mb-1 user-profilee">
                                        {ele?.comment}
                                      </p>

                                      <div className="feedback d-flex align-items-center">
                                        <div onClick={() => handleLike(ele)}>
                                          <img
                                            className={
                                              ele?.commentLiked
                                                ? "liked-comment me-2"
                                                : " me-2"
                                            }
                                            src={like}
                                          />
                                        </div>
                                        <div className="position-relative">
                                          <button
                                            className="btn ms-2 reply-btnn"
                                            onClick={() => {
                                              handleReplyComment(ele);
                                            }}
                                          >
                                            Reply
                                          </button>
                                        </div>
                                      </div>
                                      {replyCommentBox == ele?._id ? (
                                        <div>
                                          <div className="write-message d-flex position-relative align-items-center reply-section-message">
                                            {userData?.image ? (
                                              <img
                                                className="border-thumb user-img me-2"
                                                src={`${process.env.REACT_APP_API_URL}/uploads/users/${userData?.image}`}
                                              />
                                            ) : (
                                              <div className="user-info me-1">
                                                <div
                                                  className="me-3 avatar-text d-flex align-items-center justify-content-center"
                                                  style={{ background: "grey" }}
                                                >
                                                  <span className="text-uppercase text-white">
                                                    {userData?.firstName?.slice(
                                                      0,
                                                      1
                                                    )}{" "}
                                                  </span>
                                                </div>
                                              </div>
                                            )}

                                            <Formik
                                              key={index}
                                              initialValues={{ comment: "" }}
                                              validationSchema={Yup.object().shape(
                                                {
                                                  comment:
                                                    Yup.string().required(
                                                      "Comment is required"
                                                    ),
                                                }
                                              )}
                                              onSubmit={async (
                                                values,

                                                { resetForm }
                                              ) => {
                                                try {
                                                  const resComment =
                                                    await axios.post(
                                                      `${process.env.REACT_APP_API_URL}/post/replyOnCommentCreateEdit`,
                                                      {
                                                        ...values,
                                                        post_id: ele?.post_id,
                                                        parent_id: ele?._id,
                                                      },
                                                      {
                                                        headers: {
                                                          Authorization: token,
                                                        },
                                                      }
                                                    );

                                                  if (
                                                    resComment?.status == 200
                                                  ) {
                                                    if (
                                                      ele?.user_id !==
                                                      userData?._id &&
                                                      ele?.user
                                                        ?.comment_notification ===
                                                      "On"
                                                    ) {
                                                      const apiNotificationResponse =
                                                        await dispatch(
                                                          createNotificationThunk(
                                                            {
                                                              userId:
                                                                ele?.user_id,
                                                              token: token,
                                                              content:
                                                                userData?.firstName +
                                                                " " +
                                                                userData?.lastName +
                                                                " has replied on your comment ",
                                                              parent_id:
                                                                ele?._id,
                                                              type: "comment",
                                                            }
                                                          )
                                                        );
                                                    }
                                                    handleReplyComment(ele);
                                                    resetForm();
                                                  }
                                                } catch (error) {
                                                  console.log(error);
                                                }
                                              }}
                                            >
                                              {({
                                                values,
                                                handleChange,
                                                handleSubmit,
                                                errors,
                                                touched,
                                              }) => (
                                                <form
                                                  onSubmit={handleSubmit}
                                                  className="w-100"
                                                >
                                                  <input
                                                    type="text"
                                                    name="comment"
                                                    placeholder="Write a comment"
                                                    value={values.comment}
                                                    onChange={handleChange}
                                                  />

                                                  <div className="sending-opt d-flex align-items-center">
                                                    <button
                                                      type="submit"
                                                      className="me-2 border-0 bg-transparent"
                                                    >
                                                      {" "}
                                                      <img src={sendbtn} />
                                                    </button>
                                                  </div>
                                                </form>
                                              )}
                                            </Formik>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="time-options text-end">
                                      <span className="time me-2">
                                        {ele?.createdAt?.slice(15, 19)}
                                      </span>
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          variant="light"
                                          id="dropdown-basic"
                                        >
                                          <img src={options} />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          {userData?._id === ele?.user_id && (
                                            <>
                                              <Dropdown.Item
                                                onClick={() =>
                                                  handaleEditComment(
                                                    ele._id,
                                                    ele
                                                  )
                                                }
                                              >
                                                <img
                                                  src={edit}
                                                  width={"20px"}
                                                  className="me-2"
                                                />
                                                Edit comment
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() =>
                                                  handleDeleteComment(ele?._id)
                                                }
                                              >
                                                <img
                                                  src={trash}
                                                  width={"20px"}
                                                  className="me-2"
                                                />
                                                Delete comment
                                              </Dropdown.Item>
                                            </>
                                          )}

                                          <Dropdown.Item
                                            onClick={() =>
                                              handleReportComment(ele?._id)
                                            }
                                          >
                                            <img
                                              src={flag}
                                              width={"20px"}
                                              className="me-2"
                                            />
                                            Report comment
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>

                                  {replyCommentBox == ele?._id &&
                                    repliesOfComment
                                      ?.slice(0, 5)
                                      ?.map((reply) => {
                                        return (
                                          <div
                                            key={reply._id}
                                            className="d-flex message-one grey-cmnt justify-content-between my-3 reply-comment-box"
                                          >
                                            <div className="user-info me-1">
                                              {reply?.user?.image ? (
                                                <img
                                                  className="user"
                                                  src={`${process.env.REACT_APP_API_URL}/uploads/users/${reply?.user?.image}`}
                                                />
                                              ) : (
                                                <div
                                                  className="me-3 avatar-text d-flex align-items-center justify-content-center"
                                                  style={{
                                                    background: "grey",
                                                  }}
                                                >
                                                  <span className="text-uppercase text-white">
                                                    {reply?.user?.firstName?.slice(
                                                      0,
                                                      1
                                                    )}{" "}
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                            <div className="sr-message">
                                              <b>
                                                {" "}
                                                <p
                                                  className="mb-0"
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleViewPeople(
                                                      reply?.user_id
                                                    )
                                                  }
                                                >
                                                  {reply?.user?.firstName}{" "}
                                                  {reply?.user?.lastName}
                                                </p>
                                              </b>
                                              <p className="mb-1 user-profilee">
                                                {reply?.comment}
                                              </p>

                                              <div className="feedback d-flex align-items-center">
                                                {/* <div
                                                    onClick={() => {
                                                      handleLike(reply);
                                                    }}
                                                  >
                                                    <img
                                                      className={
                                                        reply?.commentLiked
                                                          ? "liked-comment me-2"
                                                          : " me-2"
                                                      }
                                                      src={like}
                                                    />
                                                  </div> */}
                                              </div>
                                              {replyCommentBox == reply?._id ? (
                                                <div>
                                                  <div className="write-message d-flex position-relative align-items-center reply-section-message">
                                                    {userData?.image ? (
                                                      <img
                                                        className="border-thumb user-img me-2"
                                                        src={`${process.env.REACT_APP_API_URL}/uploads/users/${userData?.image}`}
                                                      />
                                                    ) : (
                                                      <div
                                                        className="me-3 avatar-text d-flex align-items-center justify-content-center"
                                                        style={{
                                                          background: "grey",
                                                        }}
                                                      >
                                                        <span className="text-uppercase text-white">
                                                          {userData?.firstName?.slice(
                                                            0,
                                                            1
                                                          )}{" "}
                                                        </span>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <div className="time-options text-end">
                                              <span className="time me-2">
                                                {reply?.createdAt?.slice(
                                                  15,
                                                  19
                                                )}
                                              </span>
                                              {/* <Dropdown>
                                                  <Dropdown.Toggle
                                                    variant="light"
                                                    id="dropdown-basic"
                                                  >
                                                    <img src={options} />
                                                  </Dropdown.Toggle>

                                                  <Dropdown.Menu>
                                                    <Dropdown.Item
                                                      onClick={() =>
                                                        handaleEditComment(
                                                          ele._id,
                                                          ele
                                                        )
                                                      }
                                                    >
                                                      Edit comment
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                      onClick={() =>
                                                        handleDeleteComment(
                                                          ele._id
                                                        )
                                                      }
                                                    >
                                                      Delete comment
                                                    </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown> */}
                                            </div>
                                          </div>
                                        );
                                      })}
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      {seeMore === item?.comments?.length ? (
                        <Link
                          className="common-btn text-start mb-2"
                          style={{
                            display: item?.comments?.length > 3 ? "" : "none",
                          }}
                          onClick={() => setSeeMore(3)}
                        >
                          Less more
                        </Link>
                      ) : (
                        <Link
                          className="common-btn text-start mb-2"
                          style={{
                            display: item?.comments?.length <= 2 ? "none" : "",
                          }}
                          onClick={() => setSeeMore(item?.comments?.length)}
                        >
                          See more <span>({item?.comments?.length - 3})</span>
                        </Link>
                      )}
                      <div>
                        <div className="write-message d-flex position-relative align-items-center ">
                          {userData?.image ? (
                            <img
                              className="border-thumb user-img me-2"
                              src={`${process.env.REACT_APP_API_URL}/uploads/users/${userData?.image}`}
                            />
                          ) : (
                            <div
                              className="me-3 avatar-text d-flex align-items-center justify-content-center"
                              style={{ background: "grey" }}
                            >
                              <span className="text-uppercase text-white">
                                {userData?.firstName?.slice(0, 1)}{" "}
                              </span>
                            </div>
                          )}

                          <Formik
                            key={index}
                            initialValues={{ comment: "" }}
                            validationSchema={Yup.object().shape({
                              comment: Yup.string().required(
                                "Comment is required"
                              ),
                            })}
                            onSubmit={async (values, { resetForm }) => {
                              try {
                                const resComment = await axios.post(
                                  `${process.env.REACT_APP_API_URL}/post/addEditComment`,
                                  {
                                    ...values,
                                    post_id: item._id,
                                  },
                                  {
                                    headers: {
                                      Authorization: token,
                                    },
                                  }
                                );


                                if (resComment?.status == 200) {
                                  if (
                                    item?.user_id !== userData?._id &&
                                    item?.user?.comment_notification === "On"
                                  ) {
                                    console.log(resComment, 'resComment>>>')
                                    const apiNotificationResponse =
                                      await dispatch(
                                        createNotificationThunk({
                                          userId: item?.user_id,
                                          token: token,
                                          content:
                                            userData?.firstName +
                                            " " +
                                            userData?.lastName +
                                            " has commented on your post " +
                                            item?.title,
                                          parent_id: item?._id,
                                          type: "comment",
                                        })
                                      );
                                  }

                                  callApi(token);
                                  resetForm();
                                }
                              } catch (error) {
                                console.log(error);
                              }
                            }}
                          >
                            {({
                              values,
                              handleChange,
                              handleSubmit,
                              errors,
                              touched,
                            }) => (
                              <div className="comment-input-wrapper">
                                <form onSubmit={handleSubmit} className="w-100">
                                  <input
                                    type="text"
                                    name="comment"
                                    placeholder="Write a comment"
                                    value={values.comment}
                                    onChange={handleChange}
                                  />

                                  <div className="sending-opt d-flex align-items-center">
                                    {/* <button className="me-2 border-0 bg-transparent">
                                  {" "}
                                  <img className="me-2" src={mic} />
                                </button>
                                <button className="me-2 border-0 bg-transparent">
                                  {" "}
                                  <img className="me-2" src={gallery} />{" "}
                                </button> */}
                                    <button
                                      type="submit"
                                      className="me-2 border-0 bg-transparent"
                                    >
                                      {" "}
                                      <img src={sendbtn} />
                                    </button>
                                  </div>
                                </form>
                              </div>
                            )}
                          </Formik>
                        </div>
                      </div>
                      {/* <CommentsForm values={item} /> */}
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                <div className="d-flex justify-content-center p-5">
                  <p>No Post Found</p>
                </div>
              </>
            )}
          </div>
        </>
      )}

      <Modal
        show={likeShow}
        onHide={() => setLikeShow(false)}
        className="add-post-modal"
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title>
            <div className="user-field d-flex align-items-center">
              Post like user list
            </div>
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="message-box position-relative">
              <div className="searched-users p-3 border rounded">
                <ul className="list-unstyled mb-0">
                  {likeUsers?.likes?.length > 0 &&
                    likeUsers?.likes.map((item) => {
                      return (
                        <li key={item?.likeuser?._id}>
                          <div className="user-details d-flex align-items-center gap-3">
                            {item?.likeuser?.image ? (
                              <div>
                                <img
                                  className="me-3"
                                  src={`${process.env.REACT_APP_API_URL}/uploads/users/${item?.likeuser?.image}`}
                                />
                              </div>
                            ) : (
                              <div
                                className="avatar-text d-flex align-items-center justify-content-center"
                                style={{ background: "grey" }}
                              >
                                <span className="text-uppercase text-white">
                                  {item?.likeuser?.firstName?.slice(0, 1)}{" "}
                                </span>
                              </div>
                            )}

                            <div>
                              <h4 className="mb-1 message">
                                {item?.likeuser?.firstName}{" "}
                                {item?.likeuser?.lastName ?? ""}{" "}
                              </h4>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className=""></Modal.Footer>
        </form>
      </Modal>

      {show && (
        <EditPost
          show={show}
          onClose={() => setShow(false)}
          editPost={editPost}
          fetchData={handleUpdata}
        />
      )}
    </div>
  );
}
