import React from "react";
import Myprofile from "../Myprofile";
import Education from "../../Components/Education";
import Stats from "../../Components/Stats";
import Followers from "../../Components/Followers";
import Footer from "../../Components/Footer"; 
import { useSelector } from "react-redux";
import { authSelector } from "../../Features/auth/authSelector";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Navbar from "../Header/Navbar";
import Profilepagetabs from "./Profilepagetabs";
export default function Profile() {
  const { isLoggedIn } = useSelector(authSelector);


  return (
    <div>
          <Navbar />
      <div className="container main profile-page">
        <div className="row">
          <div className="col-lg-9 col-md-12 col-sm-12 left">
            <Myprofile />
            <Profilepagetabs />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 center profile-right-bar">
            <Stats />
            <Followers />
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}
