// import Forget from "./Components/AuthModule/Forget/Forget";
import Profile from "./Components/Profile/Profile";
import Setting from "./Components/Settings/Settings";
import Information from "./Components/AuthModule/Forget/Information";
import PageNotFound from "./Components/PageNotFound/PageNotFound";
import ProtectedRoutes from "./Components/ProtectedRoutes/ProtectedRoutes";
import { Suspense, lazy } from "react";
import Loader from "./Common/Loader";

import "./Components/Assets/style/style.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Messages from "./Components/Messages/Messages";
import Error from "./Components/Errorpage";
import Offline from "./Components/Offline";

import toast, { Toaster } from "react-hot-toast";

import InvitationManage from "./Components/MyNetwork/InvitationManage";
import { useSelector } from "react-redux";
import { authSelector } from "./Features/auth/authSelector";
import TermsAndCondition from "./Components/Home/TermsAndCondition";
import FindColleagues from "./Components/FollowerUser/FindColleagues";

const Login = lazy(() => import("./Components/AuthModule/Login/Login"));
const Landing = lazy(() =>
  import("./Components/AuthModule/LandingPage/Landing")
);

const SearchResult = lazy(() =>
  import("./Components/SearchResult/SearchResult")
);
const AllActivities = lazy(() => import("./Components/Activity/AllActivities"));
const Home = lazy(() => import("./Components/Home/Home"));
const Register = lazy(() =>
  import("./Components/AuthModule/Register/Register")
);
const Forgot = lazy(() => import("./Components/AuthModule/Forget/Forgot"));
const Notification = lazy(() =>
  import("./Components/Notification/Notification")
);
const ResetPassword = lazy(() =>
  import("./Components/AuthModule/ResetPassword/ResetPassword")
);
const SearchProfileView = lazy(() =>
  import("./Components/SearchResult/SearchProfileView")
);

const Bookmarks = lazy(() => import("./Components/Bookmarks"));

const MyNetwork = lazy(() => import("./Components/MyNetwork/MyNetwork"));
const MyNetworkPosts = lazy(() =>
  import("./Components/MyNetwork/MyNetworkPosts")
);

const Connections = lazy(() => import("./Components/FollowerUser/connections"));

function App() {
  const { token } = useSelector(authSelector);

  return (
    <div className="App">
      {/* <Navbar /> */}
      {/* <Loader /> */}
      <Suspense fallback={<Loader />}>
        <Routes>
          {/* Protected Routes */}
          <Route
            path="/home"
            element={
              token ? <ProtectedRoutes Component={Home} /> : <Navigate to="/" />
            }
          />
          <Route
            path="/Settings"
            element={<ProtectedRoutes Component={Setting} />}
          />
          <Route
            path="/Profile"
            element={<ProtectedRoutes Component={Profile} />}
          />
          <Route
            path="/Notification"
            element={<ProtectedRoutes Component={Notification} />}
          />
          <Route
            path="/Messages"
            element={<ProtectedRoutes Component={Messages} />}
          />
          <Route
            path="/activities"
            element={<ProtectedRoutes Component={AllActivities} />}
          />
          <Route
            path="/mynetwork"
            element={<ProtectedRoutes Component={MyNetworkPosts} />}
          />
          <Route
            path="/mynetwork/invitaions"
            element={<ProtectedRoutes Component={InvitationManage} />}
          />

          <Route
            path="/bookmarks"
            element={<ProtectedRoutes Component={Bookmarks} />}
          />
          <Route
            path="/find"
            element={<ProtectedRoutes Component={FindColleagues} />}
          />
          {/* Auth Module Routes */}
          <Route path="/results" element={<SearchResult />} />
          <Route
            path="/results/view-profile/:_id"
            element={<SearchProfileView />}
          />

          <Route
            path="/connections"
            element={<ProtectedRoutes Component={Connections} />}
          />

          <Route
            path="/Login"
            element={token ? <Navigate to="/home" /> : <Login />}
          />

          <Route
            path="/Register"
            element={token ? <Navigate to="/home" /> : <Register />}
          />

          <Route
            path="/Forgot"
            element={token ? <Navigate to="/home" /> : <Forgot />}
          />
          <Route path="/ResetPassword" element={<ResetPassword />} />
          <Route
            path="/"
            element={token ? <Navigate to="/home" /> : <Landing />}
          />

          <Route path="/information" element={<Information />} />

          <Route path="*" element={<PageNotFound />} />
          <Route path="/no-result-found" element={<Error />} />

          <Route path="/terms-condition" element={<TermsAndCondition />} />
        </Routes>
      </Suspense>

      <Toaster />
    </div>
  );
}

export default App;
