import * as Yup from "yup";

export const loginValidationSchema = Yup.object({
  email: Yup.string().email("invalid email.").required(),
  password: Yup.string().required(),
});

export const changePasswordSchema = Yup.object({
  oldPassword: Yup.string().required("This field will be required"),
  newPassword: Yup.string().required("This field will be required"),
  confirmPassword: Yup.string().required("This field will be required"),
});
export const forgetPassword = Yup.object({
  email: Yup.string()
    .required("This field will be required")
    .email("invalid email address."),
});

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const singUpSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address.")
    .matches(
      /^[a-zA-Z0-9._@-]*$/,
      "Can only contains English alphabet characters, periods, dashes, underscores, and the @ sign"
    )
    .required("Email is required."),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/\d/, "Password must contain at least one number"),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Phone number must be at least 10 digits")
    .max(13, "Phone number can be at most 13 digits")
    .required("Phone number is required"),
  // passwordConfirm: Yup.string()
  //   .required("Confirm password is required.")
  //   .oneOf([Yup.ref("password"), null], "Passwords must match."),
  firstName: Yup.string().trim().required("Firstname is required."),
  lastName: Yup.string().trim().required("Lastname is required."),

  dob: Yup.string().required("DOB is required."),
  country: Yup.string().required("Country is required."),
  gender: Yup.string().required("Gender is required."),
});

export const postSchema = Yup.object({
  postType: Yup.string().required("Please select post type "),
  description: Yup.string().required("Please enter description"),
  // title: Yup.string().when("postType", {
  //   is: (value) => ["job", "webinar"].includes(value),
  //   then: Yup.string().required("Title is required"),
  //   otherwise: Yup.string().notRequired(),
  // }),
  // opening: Yup.string().when("postType", {
  //   is: "job",
  //   then: Yup.string().required("Opening is required"),
  //   otherwise: Yup.string().notRequired(),
  // }),
  // salary: Yup.string().when("postType", {
  //   is: "job",
  //   then: Yup.string().required("Salary is required"),
  //   otherwise: Yup.string().notRequired(),
  // }),
  // link: Yup.string().when("postType", {
  //   is: (value) => value == "webinar",
  //   then: Yup.string().required("Webinar link is required"),
  // }),
  // description: Yup.string().when("postType", {
  //   is: (value) => value === "general",
  //   then: Yup.string().required("Description is required"),
  // }),
});
