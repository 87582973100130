import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { authSelector } from "../Features/auth/authSelector";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import toast from "react-hot-toast";
import {
  getProfileThunk,
  updateProfileThunk,
} from "../Features/auth/authThunk";

export default function EditProfileModal(props) {
  const { userData, token } = useSelector(authSelector);

  const dispatch = useDispatch();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object({
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Invalid Phone Number.")
      .required("Invalid Phone Number."),

    firstName: Yup.string().required("Fullname is required."),
    dob: Yup.string().required("DOB is required."),

    gender: Yup.string().required("Gender is required."),
    country: Yup.string().required("Country is required."),
  });

  var currentDate = new Date();
  var max13YearsAgo = new Date(
    currentDate.getFullYear() - 13,
    currentDate.getMonth(),
    currentDate.getDate()
  )
    .toISOString()
    .split("T")[0];

  const formik = useFormik({
    initialValues: {
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      gender: userData?.gender,
      dob: userData?.dob,
      phone: userData?.phone,
      country: userData?.country,
      employment: userData?.employment,
      education: userData?.education,
      token: token,
      message_notification: userData?.message_notification,
      comment_notification: userData?.comment_notification,
      event_notification: userData?.event_notification,
    },
    validationSchema: validationSchema,
    onSubmit: async (body) => {
      try {
        //let token = token;

        const res = await dispatch(updateProfileThunk(body));

        if (res?.payload?.status === 200) {
          toast.success(res?.payload?.data?.message);
          await dispatch(getProfileThunk(token));

          props.onClose();
        } else {
          toast.error(res?.payload?.data?.message);
        }
      } catch (error) {
        toast.error(error?.payload?.response?.data?.message);
      }
    },
  });

  return (
    <div>
      <Modal
        show={props.show}
        onHide={() => props.onClose()}
        className="update-profile-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body px-3">
            <form onSubmit={formik.handleSubmit}>
              {props?.section === "profile" && (
                <>
                  <div className="mb-3 text-start">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label m-0"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      name="firstName"
                      className="form-control"
                      id="firstname"
                      aria-describedby="firstname"
                    />
                    <span style={{ color: "red" }}>
                      {" "}
                      {formik.touched.firstName && formik.errors.firstName ? (
                        <div>{formik.errors.firstName} </div>
                      ) : null}
                    </span>
                  </div>
                  <div className="mb-3 text-start">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label m-0"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      name="lastName"
                      className="form-control "
                      id="lastname"
                      aria-describedby="lastname"
                    />
                  </div>
                  <div className="mb-3 text-start">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label m-0"
                    >
                      Phone
                    </label>
                    <input
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      name="phone"
                      type="text"
                      className="form-control"
                    />
                    <span style={{ color: "red" }}>
                      {" "}
                      {formik.touched.phone && formik.errors.phone ? (
                        <div>{formik.errors.phone} </div>
                      ) : null}
                    </span>
                  </div>
                  <div className="mb-3 text-start">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label m-0"
                    >
                      Gender
                    </label>
                    <select
                      value={formik.values.gender}
                      onChange={formik.handleChange}
                      name="gender"
                      className="form-control"
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    <span style={{ color: "red" }}>
                      {" "}
                      {formik.touched.gender && formik.errors.gender ? (
                        <div>{formik.errors.gender} </div>
                      ) : null}
                    </span>
                  </div>
                </>
              )}
              {props?.section === "general" && (
                <>
                  <div className="mb-3 text-start">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label m-0"
                    >
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      value={formik.values.dob}
                      onChange={formik.handleChange}
                      name="dob"
                      className="form-control "
                      rows="4"
                      cols="50"
                      max={max13YearsAgo}
                    />
                    <span style={{ color: "red" }}>
                      {" "}
                      {formik.touched.dob && formik.errors.dob ? (
                        <div>{formik.errors.dob} </div>
                      ) : null}
                    </span>
                  </div>

                  <div className="mb-3 text-start">
                    <label htmlFor="country" className="form-label m-0">
                      Country
                    </label>
                    <input
                      type="text"
                      value={formik.values.country}
                      onChange={formik.handleChange}
                      name="country"
                      className="form-control "
                      id="country"
                      aria-describedby="country"
                    />
                  </div>
                  <div className="mb-3 text-start">
                    <label htmlFor="employment" className="form-label m-0">
                      Employment
                    </label>
                    <select
                      value={formik.values.employment}
                      onChange={formik.handleChange}
                      name="employment"
                      className="form-control"
                    >
                      <option value="">Select Employemnt</option>
                      <option value="Full-Time">Full-Time</option>
                      <option value="Part-Time">Part-Time</option>
                    </select>
                    <span style={{ color: "red" }}>
                      {" "}
                      {formik.touched.employment && formik.errors.employment ? (
                        <div>{formik.errors.employment} </div>
                      ) : null}
                    </span>
                  </div>
                  <div className="mb-3 text-start">
                    <label htmlFor="education" className="form-label m-0">
                      Education
                    </label>
                    <select
                      value={formik.values.education}
                      onChange={formik.handleChange}
                      name="education"
                      className="form-control"
                    >
                      <option value="">Select Education</option>
                      <option value="Post Graduate">Post Graduate</option>
                      <option value="Graduate">Graduate</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Metric">Metric</option>
                    </select>
                    <span style={{ color: "red" }}>
                      {" "}
                      {formik.touched.education && formik.errors.education ? (
                        <div>{formik.errors.education} </div>
                      ) : null}
                    </span>
                  </div>
                </>
              )}

              {props?.section === "notification" && (
                <>
                  <div className="mb-3 text-start">
                    <label
                      htmlFor="message_notification"
                      className="form-label m-0"
                    >
                      Message Notification
                    </label>
                    <select
                      value={formik.values.message_notification}
                      onChange={formik.handleChange}
                      name="message_notification"
                      className="form-control"
                    >
                      <option value="">Select Option</option>
                      <option value="On">On</option>
                      <option value="Off">Off</option>
                    </select>
                    <span style={{ color: "red" }}>
                      {" "}
                      {formik.touched.message_notification &&
                      formik.errors.message_notification ? (
                        <div>{formik.errors.message_notification} </div>
                      ) : null}
                    </span>
                  </div>

                  <div className="mb-3 text-start">
                    <label
                      htmlFor="comment_notification"
                      className="form-label m-0"
                    >
                      Comment Notification
                    </label>
                    <select
                      value={formik.values.comment_notification}
                      onChange={formik.handleChange}
                      name="comment_notification"
                      className="form-control"
                    >
                      <option value="">Select Option</option>
                      <option value="On">On</option>
                      <option value="Off">Off</option>
                    </select>
                    <span style={{ color: "red" }}>
                      {" "}
                      {formik.touched.comment_notification &&
                      formik.errors.comment_notification ? (
                        <div>{formik.errors.comment_notification} </div>
                      ) : null}
                    </span>
                  </div>
                  <div className="mb-3 text-start">
                    <label
                      htmlFor="event_notification"
                      className="form-label m-0"
                    >
                      Event Notification
                    </label>
                    <select
                      value={formik.values.event_notification}
                      onChange={formik.handleChange}
                      name="event_notification"
                      className="form-control"
                    >
                      <option value="">Select Option</option>
                      <option value="On">On</option>
                      <option value="Off">Off</option>
                    </select>
                    <span style={{ color: "red" }}>
                      {" "}
                      {formik.touched.event_notification &&
                      formik.errors.event_notification ? (
                        <div>{formik.errors.event_notification} </div>
                      ) : null}
                    </span>
                  </div>
                </>
              )}

              <div className="file-input">
                <button type="submit" className="save-changes">
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
