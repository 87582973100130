import { createSlice } from "@reduxjs/toolkit";
import {
  educationCreateThunk,
  experienceCreateThunk,
  getFollowersThunk,
  getFollowingsThunk,
  getUserEducationsThunk,
  getUserExperiencesThunk,
  getUserSkillsThunk,
  skillCreateThunk,
  getConnectionThunk,
} from "./profileThunk";

const initialState = {
  skill: {},
  skills: [],
  educations: [],
  experiences: [],
  errorMessage: "",
  isLoggingStatus: false,
  followers: [],
  followings: [],
  connections: [],
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // get skills
      .addCase(getUserSkillsThunk.fulfilled, (state, action) => {
        state.skills = action?.payload?.data?.body;
      })
      .addCase(skillCreateThunk.fulfilled, (state, action) => {
        state.skills = action?.payload?.data?.body;
      })

      // get experiences
      .addCase(getUserExperiencesThunk.fulfilled, (state, action) => {
        state.experiences = action?.payload?.data?.body;
      })
      .addCase(experienceCreateThunk.fulfilled, (state, action) => {
        state.skills = action?.payload?.data?.body;
      })

      //  get educations
      .addCase(getUserEducationsThunk.fulfilled, (state, action) => {
        state.educations = action?.payload?.data?.body;
      })

      .addCase(educationCreateThunk.fulfilled, (state, action) => {
        state.skills = action?.payload?.data?.body;
      })

      //  get followers
      .addCase(getFollowersThunk.fulfilled, (state, action) => {
        state.followers = action?.payload?.data?.body;
      })
      //  get followings
      .addCase(getFollowingsThunk.fulfilled, (state, action) => {
        state.followings = action?.payload?.data?.body;
      })

      .addCase(getConnectionThunk.fulfilled, (state, action) => {
        state.connections = action?.payload?.data?.body;
      });
  },
});

export default profileSlice.reducer;
