import React from "react";
import logo from "../Components/Assets/images/logo.svg";
export default function Loader() {
  return (
    <div>
      <div className="loader-box">
        <div className="logo-loader">
          <img className="mb-3" src={logo} />
          <span className="loader"></span>
        </div>
      </div>
    </div>
  );
}
