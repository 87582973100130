import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { authSelector } from "../Features/auth/authSelector";
import { profileSelector } from "../Features/profile/profileSelector";
import { getFollowersThunk, getFollowingsThunk } from "../Features/profile/profileThunk";

export default function Stats() {
  const { token } = useSelector(authSelector);
  const { followers, followings } = useSelector(profileSelector);
 
const dispatch= useDispatch()
    const callApi = async () => {
      try {
     const follow = await dispatch(getFollowersThunk(token))
     const following = await dispatch(getFollowingsThunk(token))

      } catch (error) {
        console.log({ error });
      }
    };
    useEffect(() => {
      callApi();
    }, []);
 
  return (
    <div className="side-tab stats second-tab py-4 px-4 ">
      <div className="title-with-icon d-flex justify-content-between position-relative">
        <div className="titel">
          <h3 className="mb-0">Profile Stats</h3>
          <p className="opacity-0 mb-0">Over the past 24 hours</p>
        </div>
        {/* <Link to='/' className='mt-1'>Go to stats</Link> */}
      </div>
      <ul className="stat-list list-unstyled p-0 text-start mb-0">
        <li className="position-relative">
          <h2 className="mb-1">{followers?.length}</h2>
          <p className="mb-0">Followers</p>
        </li>
        <li className="position-relative">
          <h2 className="mb-1">{followings?.length}</h2>
          <p className="mb-0">Followings</p>
        </li>
        {/* <li className="position-relative">
          <h2 className="mb-1">9</h2>
          <p className="mb-0">views today</p>
        </li> */}
        {/* <li className="position-relative">
          <h2 className="mb-1">36</h2>
          <p className="mb-0">posts views</p>
        </li> */}
      </ul>
    </div>
  );
}
