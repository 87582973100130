import React, { useEffect, useState, useRef } from "react";
import call from "../Components/Assets/images/call.svg";
import video from "../Components/Assets/images/video2.svg";
import thirdopt from "../Components/Assets/images/hz-third.svg";
import user1 from "../Components/Assets/images/user-2.png";
import active from "../Components/Assets/images/active.svg";
import check from "../Components/Assets/images/check.svg";
import thomas from "../Components/Assets/images/thomas.png";
import opt from "../Components/Assets/images/hzopt-icon.svg";
import pin from "../Components/Assets/images/pin.svg";
import sharepictre from "../Components/Assets/images/share-images.svg";
import io from "socket.io-client";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";

import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../Features/auth/authSelector";
import axios from "axios";
import Connections from "./Connections";
import { profileSelector } from "../Features/profile/profileSelector";
import {
  createMessageThunk,
  getUserMessageThunk,
} from "../Features/message/messageThunk";
import { messageSelector } from "../Features/message/messageSelector";
import moment from "moment";
import { createNotificationThunk } from "../Features/notification/notificationThunk";

export default function Messagebox(props) {
  const [socket, setSocket] = useState(null);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  //const [messages, setMessages] = useState([]);
  const { userData, token } = useSelector(authSelector);
  const [connectionUser, setConnectionUser] = useState("");
  const { followers } = useSelector(profileSelector);
  const { messages } = useSelector(messageSelector);
  const divRef = useRef();
  const [attachment, setAttachment] = useState("");
  const [image, setImage] = useState("");

  const validationSchema = Yup.object({
    content: Yup.string().required("Please enter message"),
    //receiver_id: Yup.string().required("This field is required"),
  });

  useEffect(() => {
    formik.setFieldValue("receiver_id", props.defaultUser?._id);
    if (props.defaultUser !== "") {
      getMessages();
    }
    divRef.current.scrollTop = divRef.current.scrollHeight;

    const socketInstance = io(process.env.REACT_APP_API_SERVER);

    // Join the room when the component mounts
    socketInstance.emit("join", {
      room: props.defaultRoom,
      userId: userData?._id,
      username: userData?.firstName,
    });

    // Listen for messages from the server
    socketInstance.on("sendMessageToClient", (data) => {
      //setMessages((prevMessages) => [...prevMessages, message]);

      if (data.room === props.defaultRoom) {
        getMessages();
      }
    });

    setSocket(socketInstance);

    // Clean up the socket connection when the component unmounts
    return () => {
      socketInstance.disconnect();
    };
  }, [props.defaultUser]);

  const formik = useFormik({
    initialValues: {
      content: "",
      receiver_id: props.defaultUser?._id,
      sender_id: userData?._id,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        let apiResponse = {};

        if (values.receiver_id === undefined || values.receiver_id === "") {
          toast.error("Please select user to send message", {
            position: "top-right",
          });
        } else {
          apiResponse = await dispatch(
            createMessageThunk({
              ...values,
              token: token,
              image: image,
              attachment: attachment,
            })
          );
        }

        if (apiResponse?.payload?.status === 200) {
          socket.emit("sendMessageToServer", {
            room: props.defaultRoom,
            message: apiResponse?.payload?.data?.body,
          });

          if (userData?.message_notification === 'On') {
            const apiNotificationResponse = await dispatch(
              createNotificationThunk({
                userId: values.receiver_id,
                token: token,
                content:
                  userData?.firstName +
                  " " +
                  userData?.lastName +
                  " has messaged you",
                parent_id: apiResponse?.payload?.data?.body?._id,
                type: "message",
              })
            );
          }
          getMessages();
          formik.setFieldValue("content", "");
          setAttachment("");
          setImage("");
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
        });
      }
    },
  });

  const getMessages = async () => {
    try {
      await dispatch(
        getUserMessageThunk({ token: token, userId: props.defaultUser?._id })
      );
    } catch (error) {
      console.log({ error });
    }
  };

  const DateToTime = (date) => {
    return moment(date).format("HH:mm A");
  };

  const downloadFile = async (attachment) => {
    const fileUrl =
      process.env.REACT_APP_API_URL +
      "/uploads/message/" +
      attachment?.filename;
    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = attachment?.filename; // Specify the desired file name
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const isValueEqualToPrevious = (item, index) => {
    if (index === 0) {
      // The first item has no previous item to compare to.
      return true;
    } else {
      // Compare the current item's "value" property with the previous item's "value" property.
      return (
        moment(item?.createdAt).format("YYYY-MM-DD") ===
        moment(messages[index - 1].createdAt).format("YYYY-MM-DD")
      );
      //return item.value === data[index - 1].value;
    }
  };

  return (
    <div>
      <div className="message-box position-relative">
        <div className="message-box-header d-flex align-items-center justify-content-between px-3 py-3 text-start">
          <h4 className="mb-0">
            Chat with{" "}
            <span>
              {props.defaultUser?.firstName} {props.defaultUser?.lastName}
            </span>
            <p className="mt-1 mb-0 current-status">
              {props.defaultUser?.login_time !== ""
                ? "Online"
                : "Last online: " +
                moment(props.defaultUser?.updatedAt).fromNow()}
            </p>
          </h4>
          <div className="edit-opt">
            <a href="#" className="me-3">
              <img src={call} />
            </a>
            <a href="#" className="me-3">
              <img src={video} />
            </a>
          </div>
        </div>
        <div className="flex-box py-3" ref={divRef}>
          {messages?.map((item, index) => {
            return (
              <div key={item?._id}>
                {!isValueEqualToPrevious(item, index) || index === 0 ? (
                  <div className="timing-divide border-bottom m-4 position-relative">
                    <span className="timing-pill">
                      {moment(item?.createdAt).format("MMM DD, YYYY")}
                    </span>
                  </div>
                ) : (
                  ""
                )}

                <div
                  className={
                    "recieved-messages mx-3 my-3 " +
                    (item?.sender_id === userData?._id ? "sender" : "")
                  }
                >
                  <div className="recieved d-flex">
                    <div className="user-stat position-relative me-3">
                      {item?.sender?.image ? (
                        <img
                          className="me-3"
                          src={`${process.env.REACT_APP_API_URL}/uploads/users/${item?.sender?.image}`}
                        />
                      ) : (
                        <div
                          className="me-1 avatar-text d-flex align-items-center justify-content-center"
                          style={{
                            background: "grey",
                          }}
                        >
                          <span className="text-uppercase text-white">
                            {item?.sender.firstName?.slice(0, 1)}{" "}
                          </span>
                        </div>
                      )}
                      <span className="status">
                        <img src={active} />
                      </span>
                    </div>
                    <div className="msg-check text-start">
                      {item?.content === "JOBAPPLIED" ? (
                        <div className="applied-job-discription py-2 px-2 mb-0">
                          <div className="row">
                            <div className="col-5">
                              <p className="fw-semibold mb-0">Job Title:</p>
                            </div>
                            <div className="col-7">
                              <p className="text-end mb-0">
                                {item?.post?.title}
                              </p>
                            </div>
                            <div className="col-12">
                              <div className="border-bottom my-3"></div>
                            </div>
                            <div className="col-5">
                              <p className="fw-semibold mb-0">Openings:</p>
                            </div>
                            <div className="col-7">
                              <p className="text-end mb-0">
                                {item?.post?.opening}
                              </p>
                            </div>
                            <div className="col-12">
                              <div className="border-bottom my-3"></div>
                            </div>
                            <div className="col-5">
                              <p className="fw-semibold mb-0">Salary:</p>
                            </div>
                            <div className="col-7">
                              <p className="text-end mb-0">
                                {item?.post?.salary}
                              </p>
                            </div>
                            <div className="col-12">
                              <div className="border-bottom my-3"></div>
                              <p className="fw-semibold ">Discription:</p>
                              <p className="mb-0">{item?.post?.description}</p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <p className="py-2 px-2 mb-0">{item?.content}</p>
                      )}

                      {item?.attachments.length > 0 &&
                        item?.attachments.map((attachment) => {
                          return (
                            <div key={attachment?._id}>
                              {attachment?.filetype === "image" && (
                                <div className="img-attachment">
                                  <img
                                    src={`${process.env.REACT_APP_API_URL}/uploads/message/${attachment?.filename}`}
                                  />
                                </div>
                              )}
                              {attachment?.filetype === "attachment" && (
                                <div className="doc-attachment">
                                  <div className="card d-flex gap-2 align-items-center flex-row">
                                    <div>
                                      <span>
                                        <svg
                                          width="32"
                                          height="42"
                                          viewBox="0 0 32 42"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M32 42H0V0H22L32 10V42Z"
                                            fill="#90CAF9"
                                          />
                                          <path
                                            d="M30.5 11H21V1.5L30.5 11Z"
                                            fill="#E1F5FE"
                                          />
                                          <path
                                            d="M8 18H25V20H8V18ZM8 22H21V24H8V22ZM8 26H25V28H8V26ZM8 30H21V32H8V30Z"
                                            fill="#1976D2"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="file-name mb-0">
                                      {attachment?.filename}
                                    </p>
                                    <div>
                                      <div className="dropdown">
                                        <button
                                          className="btn dropdown-toggle p-0"
                                          type="button"
                                          id="dropdownMenuButton1"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <svg
                                            width="14"
                                            height="5"
                                            viewBox="0 0 14 5"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M1.59763 4.12895C1.17391 4.12895 0.76755 3.95733 0.467936 3.65184C0.168322 3.34635 0 2.93202 0 2.49999C0 2.06797 0.168322 1.65363 0.467936 1.34814C0.76755 1.04265 1.17391 0.871033 1.59763 0.871033C2.02135 0.871033 2.42772 1.04265 2.72733 1.34814C3.02694 1.65363 3.19527 2.06797 3.19527 2.49999C3.19527 2.93202 3.02694 3.34635 2.72733 3.65184C2.42772 3.95733 2.02135 4.12895 1.59763 4.12895ZM6.92308 4.12895C6.49936 4.12895 6.09299 3.95733 5.79338 3.65184C5.49377 3.34635 5.32544 2.93202 5.32544 2.49999C5.32544 2.06797 5.49377 1.65363 5.79338 1.34814C6.09299 1.04265 6.49936 0.871033 6.92308 0.871033C7.3468 0.871033 7.75316 1.04265 8.05277 1.34814C8.35239 1.65363 8.52071 2.06797 8.52071 2.49999C8.52071 2.93202 8.35239 3.34635 8.05277 3.65184C7.75316 3.95733 7.3468 4.12895 6.92308 4.12895ZM12.2485 4.12895C11.8248 4.12895 11.4184 3.95733 11.1188 3.65184C10.8192 3.34635 10.6509 2.93202 10.6509 2.49999C10.6509 2.06797 10.8192 1.65363 11.1188 1.34814C11.4184 1.04265 11.8248 0.871033 12.2485 0.871033C12.6722 0.871033 13.0786 1.04265 13.3782 1.34814C13.6778 1.65363 13.8462 2.06797 13.8462 2.49999C13.8462 2.93202 13.6778 3.34635 13.3782 3.65184C13.0786 3.95733 12.6722 4.12895 12.2485 4.12895Z"
                                              fill="#000"
                                              fillOpacity="0.85"
                                            />
                                          </svg>
                                        </button>
                                        <ul
                                          className="dropdown-menu"
                                          aria-labelledby="dropdownMenuButton1"
                                        >
                                          <li>
                                            <a
                                              target="_blank"
                                              className="dropdown-item"
                                              href={`${process.env.REACT_APP_API_URL}/uploads/message/${attachment?.filename}`}
                                              download={attachment?.filename}
                                            >
                                              Open Link
                                            </a>
                                          </li>
                                          <li>
                                            <span
                                              className="dropdown-item"
                                              onClick={() =>
                                                downloadFile(attachment)
                                              }
                                            >
                                              Download
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}

                      <span className="d-flex align-items-center mt-1">
                        <img src={check} />
                        <p className="time mb-0 ms-1">
                          {DateToTime(item?.createdAt)}
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {props?.defaultUser ? (
          <div className="message-field py-3 px-3 mt-3">
            <form onSubmit={formik.handleSubmit}>
              <textarea
                name="content"
                onChange={formik.handleChange}
                value={formik.values.content}
                rows="4"
                cols="50"
                placeholder="Write your message"
                className="border-0 px-3 py-3"
              ></textarea>
              {/* {formik.touched.content && formik.errors.content ? (
<div style={{ color: "red" }}>{formik.errors.content} </div>
) : null} */}
              <div className="d-flex justify-content-between align-items-center mt-2">
                <div className="col-md-10 d-flex">
                  <div className="image-input position-relative overflow-hidden">
                    <input
                      type="file"
                      id="imageInput"
                      className="d-none"
                      accept=".doc, .docx, application/pdf"
                      onChange={(e) => setAttachment(e.target.files[0])}
                      name="attachment"
                    />
                    <label htmlFor="imageInput" className="image-button">
                      <img src={pin} />
                    </label>
                  </div>
                  <p className="img_name mb-0 ms-2">{attachment?.name}</p>
                  <div className="image-input position-relative overflow-hidden ms-2">
                    <input
                      type="file"
                      id="imageInput2"
                      className="d-none"
                      accept="image/*"
                      onChange={(e) => setImage(e.target.files[0])}
                      name="image"
                    />
                    <label htmlFor="imageInput2" className="image-button">
                      <img src={sharepictre} />
                    </label>
                  </div>
                  <p className="img_name mb-0 ms-2">{image?.name}</p>
                </div>
                <div className="col-md-2 text-end">
                  <button type="submit">Send</button>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="message-field py-3 px-3 mt-3">
            Please select user to send message.
          </div>
        )}
      </div>
    </div>
  );
}
