import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const api_url = process.env.REACT_APP_API_URL;

export const getUserThunk = createAsyncThunk("user/get", async (body) => {
  try {
    const data = await axios.get(`${api_url}/users`, {
      headers: {
        Authorization: body.token,
      },
      params: {
        keyword: body.keyword,
      },
    });

    return data;
  } catch (error) {
    return error;
  }
});

export const createUserConnectThunk = createAsyncThunk(
  "user/connect",
  async (body) => {
    try {
      const data = await axios.post(`${api_url}/connections/send-req`, body, {
        headers: {
          Authorization: body.token,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);
