import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import bioedit from "../Components/Assets/images/bio-edit.svg";
import { useFormik } from "formik";
import axios from "axios";
import Modal from "react-bootstrap/Modal"; // Import React Bootstrap Modal component
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import { authSelector } from "../Features/auth/authSelector";

export default function Profilebio() {
  const { token } = useSelector(authSelector);
const navigate=useNavigate()
  const [bioId, setBioId] = useState();
  const [bio, setBio] = useState();
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [seeMore, setSeeMore] = useState(120);

  const callAPI = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/bio/getByUser`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
    
      setBioId(res.data.body.bio);
    } catch (error) {
      console.log({error});
      if(error?.response?.status===401){
        navigate('/Login')
        
      }
    }
  };

  useEffect(() => {
    callAPI();
  }, []);
  const formik = useFormik({
    initialValues: {
      bio: "",
    },
    onSubmit: async (values, { resetForm }) => {
      try { 
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/bio/addAndEdit`,
          values,
          {
            headers: {
              Authorization: token,
            },
          }
        );
      
        callAPI();
        setShowModal(false);
        resetForm();
      } catch (error) {
    
      }
    },
  });

  const handleClose = () => {
    setShowModal(false);
  };

  const handleShow = () => {
    setShowModal(true);
  };
 
  return (
    <div>
      <div className="profile-bio my-4 px-4 py-4 text-start ">
        <div className="title-with-icon d-flex justify-content-between">
          <div className="titel">
            <h3 className="mb-1">Bio</h3>
          </div>
          <div className="icon">
            <button
              onClick={handleShow}
              type="button"
              className="border-0 bg-transparent"
            >
              <img src={bioedit} alt="Edit Bio" />
            </button>
            <Modal show={showModal} onHide={handleClose} centered>
              <Modal.Header closeButton>
                <Modal.Title>Update Bio</Modal.Title>
              </Modal.Header>
              <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                  <textarea
                    value={formik.values.bio}
                    onChange={formik.handleChange}
                    name="bio"
                    className="w-100 px-2 py-2"
                    rows="4"
                    cols="50"
                    placeholder="Write here."
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button type="submit" className="save-changes">
                    Save Changes
                  </Button>
                </Modal.Footer>
              </form>
            </Modal>
          </div>
        </div>
        <p className="my-2"> {bioId?.bio?.slice(0,seeMore)} </p>
        {seeMore === bioId?.bio?.length  ? (
          <Link
            className="common-btn"
            style={{ display: bioId?.bio?.length > 120 ? "" : "none" }}
            onClick={() => setSeeMore(120)}
          >
            Less more
          </Link>
        ) : (
          <Link
            className="common-btn"
            onClick={() => setSeeMore(bioId?.bio?.length)}
          >{
            bioId?.bio?"See more ":""
          }
            
           
          </Link>
        )}
  
      </div>
    </div>
  );
}
