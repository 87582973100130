import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const api_url = process.env.REACT_APP_API_URL;

export const getNotificationThunk = createAsyncThunk(
  "getAllNotifications",
  async (body) => {
    try {
      const data = await axios.get(`${api_url}/notification/`, {
        headers: {
          Authorization: body.token,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);

export const createNotificationThunk = createAsyncThunk(
  "notification/create",
  async (body) => {
    try {
      const data = await axios.post(`${api_url}/notification/create`, body, {
        headers: {
          Authorization: body.token,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);

export const deleteNotificationThunk = createAsyncThunk(
  "notification/delete",
  async (body) => {
    try {
      const data = await axios.delete(
        `${api_url}/notification/delete/${body.id}`,
        {
          headers: {
            Authorization: body.token,
          },
        }
      );

      return data;
    } catch (error) {
      return error;
    }
  }
);

export const updateNotificationThunk = createAsyncThunk(
  "notification/update",
  async (body) => {
    try {
      const data = await axios.put(
        `${api_url}/notification/update/${body.id}`,
        body,
        {
          headers: {
            Authorization: body.token,
          },
        }
      );

      return data;
    } catch (error) {
      return error;
    }
  }
);
