import React, { useEffect, useState } from "react";
import Navbar from "../Header/Navbar";
import Footer from "../Footer";
import axios from "axios";
import { authSelector } from "../../Features/auth/authSelector";
import { useSelector } from "react-redux";
import moment from "moment";

export default function InvitationManage() {
  const { token } = useSelector(authSelector);
  const [sentReq, setSentReq] = useState();
  const [invitations, setInvitations] = useState();

  const callAPI = async () => {
    try {
      const getInvitations = await axios.get(
        `${process.env.REACT_APP_API_URL}/connections/getSentReq`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (getInvitations?.status == 200) {
        setSentReq(getInvitations?.data?.body);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const callInvitationAPI = async () => {
    try {
      const getInvitations = await axios.get(
        `${process.env.REACT_APP_API_URL}/connections/get-invitaions`,
        {
          headers: {
            Authorization: token,
          },
        }
      ); 
      if (getInvitations?.status == 200) {
        setInvitations(getInvitations?.data?.body);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    callAPI();
    callInvitationAPI();
  }, []);

  const handleAcceptReq = async (item) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/connections/update-receive-req`,
        {
          from_user_id: item?.user?._id,
          status: "accepted",
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (res?.status == 200) {
        callAPI();
        callInvitationAPI();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleIgnoreReq = async (item) => {
    

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/connections/update-receive-req`,
        {
          from_user_id: item?.user?._id,
          status: "rejected",
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (res?.status == 200) {
        callAPI();
        callInvitationAPI();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleWithdraw = async (item) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/connections/withdrawConnection/${item?._id}`,

        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (res?.status == 200) {
        callAPI();
        callInvitationAPI();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        <Navbar />

        <div className="container main">
          <div className="row">
            <div className="col-lg-9 col-md-8 col-sm-12">
              <div className="card rounded-4 mt-4">
                <div className="card-header bg-transparent pb-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="fw-normal fs-6 mb-0">Manage Invitations</h4>
                    <div>
                      <button className="border-0 bg-transparent text-muted fs-6 h4 mb-0">
                        see all{" "}
                      </button>
                    </div>
                  </div>

                  <div className="shadow-none manage-invitation-profile">
                    <ul
                      className="nav nav-tabs border-0 mt-2"
                      id="myTab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active border-0 justify-content-center align-items-center d-flex"
                          id="recieved-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#recieved"
                          type="button"
                          role="tab"
                          aria-controls="recieved"
                          aria-selected="true"
                        >
                          Recieved ({invitations?.length})
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link border-0 justify-content-center align-items-center d-flex"
                          id="sent-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#sent"
                          type="button"
                          role="tab"
                          aria-controls="sent"
                          aria-selected="false"
                        >
                          Sent ({sentReq?.length})
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="invitation-list-wrapper p-0">
                  <div className="tab-content border-0  mt-0" id="myTabContent">
                    <div
                      className="tab-pane border-0 fade show active"
                      id="recieved"
                      role="tabpanel"
                      aria-labelledby="recieved-tab"
                    >
                      <ul className="list-group list-group-flush">
                        {invitations &&
                          invitations?.map((item) => {
                            return (
                              <>
                                <li className="list-group-item d-flex justify-content-between align-items-center py-3">
                                  <div className="user-field d-flex w-50 align-items-center">
                                    {item?.user?.image ? (
                                      <img
                                        className="me-3"
                                        src={`${process.env.REACT_APP_API_URL}/uploads/users/${item?.user?.image}`}
                                      />
                                    ) : (
                                      <div
                                        className="me-3 avatar-text d-flex align-items-center justify-content-center"
                                        style={{ background: "grey" }}
                                      >
                                        <span className="text-uppercase text-white">
                                          {item?.user?.firstName?.slice(0, 1)}{" "}
                                        </span>
                                      </div>
                                    )}
                                    <div className="company-detail ms-4">
                                      <h4 className="company-title fs-6 mb-0">
                                        {`${item?.user?.firstName} ${item?.user?.lastName}`}
                                      </h4>
                                      <div className="d-flex follower-area">
                                        <p clclassNameass="mb-0">
                                          {item?.user?.profession}
                                        </p>
                                      </div>
                                      <p className="text-muted date-wrapper">
                                        {moment(item?.createdAt).fromNow()}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="">
                                    <button
                                      className="connect view-page-btn me-2"
                                      onClick={() => handleIgnoreReq(item)}
                                    >
                                      Ignore{" "}
                                    </button>
                                    <button
                                      className="connect following-btn "
                                      onClick={() => handleAcceptReq(item)}
                                    >
                                      <span className="ms-1">Accept</span>
                                    </button>
                                  </div>
                                </li>
                              </>
                            );
                          })}
                      </ul>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="sent"
                      role="tabpanel"
                      aria-labelledby="sent-tab"
                    >
                      <ul className="list-group list-group-flush">
                        {sentReq &&
                          sentReq?.map((item) => {
                            return (
                              <>
                                <li className="list-group-item d-flex justify-content-between align-items-center py-3 flex-wrap">
                                  <div className="user-field d-flex w-50 align-items-center">
                                    {item?.user?.image ? (
                                      <img
                                        className="me-3"
                                        src={`${process.env.REACT_APP_API_URL}/uploads/users/${item?.user?.image}`}
                                      />
                                    ) : (
                                      <div
                                        className="me-3 avatar-text d-flex align-items-center justify-content-center"
                                        style={{ background: "grey" }}
                                      >
                                        <span className="text-uppercase text-white">
                                          {item?.user?.firstName?.slice(0, 1)}{" "}
                                        </span>
                                      </div>
                                    )}
                                    <div className="company-detail ms-4">
                                      <h4 className="company-title fs-6 mb-0">
                                        {`${item?.user?.firstName} ${item?.user?.lastName}`}
                                      </h4>
                                      <div className="follower-area">
                                        <p clclassNameass="mb-0">
                                          {item?.user?.profession}
                                        </p>
                                        <p className="text-muted date-wrapper">
                                          {moment(item?.createdAt).fromNow()}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <button
                                      className="connect view-page-btn me-2"
                                      onClick={() => handleWithdraw(item)}
                                    >
                                      Withdraw{" "}
                                    </button>
                                  </div>
                                </li>
                              </>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 right">
              <div className="sticky-side">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
