import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import bioedit from "../Components/Assets/images/bio-edit.svg";
import exp1 from "../Components/Assets/images/media.svg";
import exp2 from "../Components/Assets/images/ux.svg";
import plus from "../Components/Assets/images/plus.svg";
import { getUserExperiencesThunk } from "../Features/profile/profileThunk";
import { useDispatch, useSelector } from "react-redux";
import { profileSelector } from "../Features/profile/profileSelector";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import axios from "axios";
import * as Yup from "yup";
import { authSelector } from "../Features/auth/authSelector";

export default function Experience() {
  const { experiences } = useSelector(profileSelector);
  const { token } = useSelector(authSelector);
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(false);
  const [isAddMode, setIsAddMode] = useState(false);
  const [seeMore, setSeeMore] = useState(3);
  const [dataValue, setDataValue] = useState({
    title: "",
    company_name: "",
    location: "",
    location_type: "",
    currently_working: false,
    employement_type: "",
    description: "",
    startDate: "",
    endDate: "",
    _id: "",
  });
  const [show, setShow] = useState(false);

  const callApi = async (token) => {
    await dispatch(getUserExperiencesThunk(token));
  };

  const handleAddEdit = async (values) => {
    try {
      if (!isAddMode) {
        // Add experience
        try {
          delete values._id;
          const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/experience/create`,
            values,
            {
              headers: {
                Authorization: token,
              },
            }
          );

          if (res.status === 200) {
            toast.success(res?.data?.message);
            callApi(token);
            setShow(false);
            formik.resetForm();
          } else {
            toast.error(res?.data?.message);
          }
        } catch (error) {
          toast.error(error.response.data.message);
        }
      } else {
        // Edit experience
        const res = await axios.put(
          `${process.env.REACT_APP_API_URL}/experience/update`,
          values,
          {
            headers: {
              Authorization: token,
            },
          }
        );
        if (res.status === 200) {
          toast.success(res?.data?.message);
          callApi(token);
          setShow(false);
          setIsAddMode(false);
          formik.resetForm();
        }
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
    }
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("This field is required"),
    company_name: Yup.string().required("This field is required"),
    location: Yup.string().required("This field is required"),
    startDate: Yup.date().required("This field is required"),
    endDate: Yup.date()
    .min(
      Yup.ref('startDate'),
      'End Date must be later than or equal to Start Date'
    )
    // endDate: Yup.string().required("This field is required"),
  });

  const formik = useFormik({
    initialValues: dataValue,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      handleAddEdit(values);
      resetForm();
    },
  });

 

  useEffect(() => {
    callApi(token);
  }, []);

  return (
    <div>
      <div className="experience my-4 px-4 py-4 text-start">
        <div className="title-with-icon d-flex justify-content-between">
          <div className="titel">
            <h3 className="mb-1">Experience</h3>
          </div>
          <div className="icon">
            <button
              onClick={() => {
                setShow(true);
                setIsAddMode(false);
                formik.resetForm();
              }}
              type="button"
              className="border-0 bg-transparent"
            >
              <img src={plus} width={22} />
            </button>
          </div>
        </div>
        <ul className="list-unstyled my-4">
          {experiences &&
            experiences?.slice(0, seeMore)?.map((item) => (
              <li className="d-flex align-items-start mb-4 pb-4" key={item._id}>
                <img src={exp2} alt="Experience Icon" />
                <div className="details ms-4">
                  <h4>{item?.title}</h4>
                  <p className="designation mb-2">
                    {item?.company_name ? item?.company_name : "----"}
                  </p>
                  <p className="years-months mb-2">
                    {item?.startDate} —{" "}
                    {item?.currently_working ? "Persent" : item?.endDate}
                  </p>
                  <p className="description">{item?.description}</p>
                </div>
                <div className="icon ms-auto">
                  <button
                    onClick={() => {
                      setIsAddMode(true); // Set edit mode
                      formik.setValues({
                        title: item.title,
                        company_name: item.company_name,
                        location: item.location,
                        location_type: item.location_type,
                        currently_working: false,
                        employement_type: item.employement_type,
                        description: item.description,
                        startDate: item.startDate,
                        endDate: item.endDate,
                        _id: item._id,
                      });
                      setShow(true);
                    }}
                    type="button"
                    className="border-0 bg-transparent"
                  >
                    <img src={bioedit} alt="Edit Icon" />
                  </button>
                </div>
              </li>
            ))}
        </ul>
        {seeMore === experiences?.length ? (
          <Link
            className="common-btn"
            style={{ display: experiences?.length > 3 ? "" : "none" }}
            onClick={() => setSeeMore(3)}
          >
            Less more
          </Link>
        ) : (
          <Link
            className="common-btn"
            style={{ display: experiences?.length <= 2 ? "none" : "" }}
            onClick={() => setSeeMore(experiences?.length)}
          >
            See more
          </Link>
        )}
      </div>

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{!isAddMode ? "Add" : "Edit"} Experience</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="modal-body px-3">
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3 text-start">
                <label htmlFor="exampleInputEmail1" className="form-label m-0">
                  Title <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  name="title"
                  className="form-control"
                  id="title"
                  aria-describedby="title"
                />
                <span style={{ color: "red" }}>
                  {" "}
                  {formik.touched.title && formik.errors.title ? (
                    <div>{formik.errors.title} </div>
                  ) : null}
                </span>
              </div>
              <div className="mb-3 text-start">
                <label htmlFor="exampleInputEmail1" className="form-label m-0">
                  Employment type
                </label>
                <select
                  className="form-select"
                  value={formik.values.employement_type}
                  onChange={formik.handleChange}
                  name="employement_type"
                >
                  <option disabled>Please Select</option>
                  <option value="Full time">Full time</option>
                  <option value="Part time">Part time</option>
                  <option value="Self employed">Self employed</option>
                </select>
                <span style={{ color: "red" }}>
                  {" "}
                  {formik.touched.employement_type &&
                  formik.errors.employement_type ? (
                    <div>{formik.errors.employement_type} </div>
                  ) : null}
                </span>
              </div>
              <div className="mb-3 text-start">
                <label htmlFor="exampleInputEmail1" className="form-label m-0">
                  Company Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  value={formik.values.company_name}
                  onChange={formik.handleChange}
                  name="company_name"
                  className="form-control "
                  id="company_name"
                  aria-describedby="company_name"
                />
                <span style={{ color: "red" }}>
                  {" "}
                  {formik.touched.company_name && formik.errors.company_name ? (
                    <div>{formik.errors.company_name} </div>
                  ) : null}
                </span>
              </div>
              <div className="mb-3 text-start">
                <label htmlFor="exampleInputEmail1" className="form-label m-0">
                  Location <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  value={formik.values.location}
                  onChange={formik.handleChange}
                  name="location"
                  className="form-control "
                  id="location"
                  aria-describedby="location"
                />
                <span style={{ color: "red" }}>
                  {" "}
                  {formik.touched.location && formik.errors.location ? (
                    <div>{formik.errors.location} </div>
                  ) : null}
                </span>
              </div>
              <div className="mb-3 text-startform-check">
                <input
                  className="form-check-input me-3"
                  type="checkbox"
                  name="currently_working"
                  id="flexCheckDefault"
                  checked={formik.values.currently_working ? true : false}
                  onChange={formik.handleChange}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  I am currently working in this role
                </label>
              </div>
              <div className="mb-3 text-start">
                <label htmlFor="exampleInputEmail1" className="form-label m-0">
                  Start date <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  value={formik.values.startDate}
                  onChange={formik.handleChange}
                  name="startDate"
                  className="form-control "
                  rows="4"
                  cols="50"
                />
                <span style={{ color: "red" }}>
                  {" "}
                  {formik.touched.startDate && formik.errors.startDate ? (
                    <div>{formik.errors.startDate} </div>
                  ) : null}
                </span>
              </div>
              {formik.values.currently_working ? (
                ""
              ) : (
                <div className="mb-3 text-start">
                  <label htmlFor="exampleInputEmail1" className="form-label m-0">
                    End date <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    value={formik.values.endDate}
                    onChange={formik.handleChange}
                    name="endDate"
                    className="form-control "
                    disabled={formik.values.currently_working ? true : false}
                    rows="4"
                    cols="50"
                  />
                  <span style={{ color: "red" }}>
                    {" "}
                    {formik.touched.endDate && formik.errors.endDate ? (
                      <div>{formik.errors.endDate} </div>
                    ) : null}
                  </span>
                </div>
              )}

              <div className="mb-3 text-start">
                <label htmlFor="exampleInputEmail1" className="form-label m-0">
                  Description
                </label>
                <textarea
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  name="description"
                  className="w-100 px-2 py-2"
                  rows="4"
                  cols="50"
                  placeholder="Write here."
                />
              </div>
              <div className="file-input">
                <button type="submit" className="save-changes">
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
