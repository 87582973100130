import React, { useEffect, useState } from "react";
import plus from "../Components/Assets/images/plus.svg";
import { Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";
import { hashTagsSelector } from "../Features/hashtags/hashTagSelector";
import { useDispatch } from "react-redux";
import {
  createHashtagThunk,
  getAllHashtagsThunk,
} from "../Features/hashtags/hashTagThunk";
import { authSelector } from "../Features/auth/authSelector";
import { useFormik } from "formik";
import axios from "axios";
import toast from "react-hot-toast";

export default function Filters(props) {
  const { hashTags } = useSelector(hashTagsSelector);
  const { token, userData } = useSelector(authSelector);
  const [config] = useState({
    token,
  });
  const dispatch = useDispatch();

  const callApi = async () => {
    try {
      const getHashTags = await dispatch(getAllHashtagsThunk(token));
    } catch (error) {
      console.log({ error });
    }
  };
  useEffect(() => {
    callApi();
  }, []);
  const formik = useFormik({
    initialValues: {
      title: "",
    },
    onSubmit: async (values) => {
      let resp = await axios.post(
        `${process.env.REACT_APP_API_URL}/hashtag/createHashtag`,
        { title: values?.title },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (resp?.status === 200) {
        callApi(token);
        formik.resetForm();

        toast.success(resp?.data?.message, {
          position: "top-center",
        });
      }
      console.log(resp);
    },
  });
  return (
    <>
      <Accordion>
        <Accordion.Item
          eventKey="0"
          className="side-tab popular-posts second-tab"
        >
          <Accordion.Header>
            <div className="title-with-icon d-flex justify-content-between position-relative mb-0 border-0">
              <div className="titel">
                <h3 className="mb-1">Filter By Hashtag</h3>
                {/* <p className="opacity-0 mb-0">hidden-text</p> */}
              </div>
              {/* <div className="icon">
          <img src={plus} />
        </div> */}
            </div>
          </Accordion.Header>
          <Accordion.Body className="px-0 pt-2">
            <form onSubmit={formik.handleSubmit}>
              <div className="d-flex align-items-center seach-filter-byhash">
                <input
                  type="text"
                  className="form-control"
                  id="exampleInputPassword1"
                  name="title"
                  onChange={formik.handleChange}
                  value={formik.values.title}
                />
                <button
                  type="submit"
                  className="seach-filter-byhash-icon ms-2 border d-flex align-items-center"
                >
                  <i className="fa-regular fa-pen-to-square"></i>
                </button>
              </div>
            </form>

            <div className="hashtags-list d-flex justify-content-center flex-wrap">
              {hashTags?.length > 0 &&
                hashTags?.map((item, index) => {
                  return (
                    <div key={index}>
                      <input
                        type="checkbox"
                        id={"item" + index}
                        name="hashtags"
                        className="d-none"
                        onClick={() => props.handleHashtag(item?.title)}
                      />
                      <label htmlFor={"item" + index}>#{item?.title}</label>
                    </div>
                  );
                })}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
