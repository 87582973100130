import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import blogedit from "../Components/Assets/images/blog-edit.svg";

import { useDispatch, useSelector } from "react-redux";
import { postSelector } from "../Features/posts/postsSelector";
import { postGetsByUserThunk } from "../Features/posts/postThunk";
import moment from "moment";
export default function Posts() {
  const { posts, userPosts } = useSelector(postSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [seeMore, setSeeMore] = useState(3);

  const callAPI = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    await dispatch(postGetsByUserThunk(token));
  };

  useEffect(() => {
    callAPI();
  }, []);

  const navigateToActivity = (item) => {
    navigate("/activities", { state: item });
  };

  return (
    <div>
      <div className="profile-bio profile-posts my-4 px-4 py-4 text-start ">
        <div className="title-with-icon d-flex justify-content-between">
          <div className="titel">
            <h3 className="mb-1">Posts</h3>
          </div>
          <div className="icon">
            <Link>
              <img src={blogedit} />
            </Link>
          </div>
        </div>
        <div className="row my-3">
          {posts &&
            posts?.map((post) => {
              return (
                <div
                  className="col-md-12"
                  onClick={() => navigate("/activities", { state: post })}
                  key={post?._id}
                >
                  <p className="m-0">
                    <span className="category">
                      <b className="text-dark">{`${post?.user[0]?.firstName} ${post?.user[0]?.lastName}`}</b>{" "}
                    </span>
                    , Posted this -{" "}
                    <span className="date text-muted">
                      {moment(post?.createdAt).fromNow()}
                    </span>
                  </p>
                  <div className="d-flex align-items-center gap-3 mb-3">
                    <div className="">
                      {post?.files[0]?.filename == null ? (
                        ""
                      ) : (
                        <img
                          className="my-1 rounded"
                          width={64}
                          height={64}
                          src={`${process.env.REACT_APP_API_URL}/uploads/demo/${post?.files[0]?.filename}`}
                        />
                      )}
                    </div>
                    <div>
                      <p className="m-0 ">{post?.description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        {posts?.length !== 0 ? (
          <Link to="/activities" className="common-btn">
            See all
          </Link>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
