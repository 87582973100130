import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import bioedit from "../Assets/images/bio-edit.svg";
import exp1 from "../Assets/images/media.svg";
import exp2 from "../Assets/images/ux.svg";
import plus from "../Assets/images/plus.svg";
import {
  getUserGoalsThunk,
  createGoalThunk,
  updateGoalThunk,
} from "../../Features/goal/goalThunk";
import { useDispatch, useSelector } from "react-redux";
import { goalSelector } from "../../Features/goal/goalSelector";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import axios from "axios";
import * as Yup from "yup";
import { authSelector } from "../../Features/auth/authSelector";

export default function Goal() {
  const { goals } = useSelector(goalSelector);
  const { token, userData } = useSelector(authSelector);
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(false);

  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [seeMore, setSeeMore] = useState(3);

  const callApi = async (token) => {
    await dispatch(getUserGoalsThunk(token));
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("This field is required"),
    type: Yup.string().required("This field is required"),
    description: Yup.string().required("This field is required"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      type: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const apiResponse = await dispatch(
          createGoalThunk({
            ...values,
            token: token,
          })
        );
        if (apiResponse?.payload?.status === 200) {
          toast.success("Your goal has been added successfully", {
            position: "top-right",
            
          
          });
          resetForm();
          setShow(false);
          callApi(token);
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          
         
        });
      }
    },
  });

  const editFormik = useFormik({
    initialValues: {
      title: "",
      type: "",
      description: "",
      id: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const apiResponse = await dispatch(
          updateGoalThunk({
            ...values,
            token: token,
          })
        );
        if (apiResponse?.payload?.status === 200) {
          toast.success("Your goal details has updated successfully", {
            position: "top-right",
            
             
          });
          resetForm();
          callApi(token);
          setEditShow(false);
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          
           
        });
      }
    },
  });

  useEffect(() => {
    callApi(token);
  }, []);

  const handleEditGoal = (item) => {
    editFormik.setFieldValue("title", item?.title);
    editFormik.setFieldValue("type", item?.type);
    editFormik.setFieldValue("description", item?.description);
    editFormik.setFieldValue("id", item?._id);
    setEditShow(true);
  };

  return (
    <div>
      <div className="experience my-4 px-4 py-4 text-start">
        <div className="title-with-icon d-flex justify-content-between">
          <div className="titel">
            <h3 className="mb-1">Goals</h3>
          </div>
          <div className="icon">
            <button
              onClick={() => setShow(true)}
              type="button"
              className="border-0 bg-transparent"
            >
              <img src={plus} width={22} />
            </button>
          </div>
        </div>
        <ul className="list-unstyled my-4">
          {goals?.length > 0 &&
            goals?.slice(0, seeMore)?.map((item) => (
              <li
                className="d-flex align-items-start mb-4 pb-4"
                key={item?._id}
              >
                <img src={exp2} alt="Experience Icon" />
                <div className="details ms-4">
                  <h4>{item?.title}</h4>
                  <p className="designation mb-2">
                    {item?.type === "short" ? "Short Term" : "Long Term"}
                  </p>
                  <p className="description">{item?.description}</p>
                </div>
                <div className="icon ms-auto">
                  <button
                    onClick={() => handleEditGoal(item)}
                    type="button"
                    className="border-0 bg-transparent"
                  >
                    <img src={bioedit} alt="Edit Icon" />
                  </button>
                </div>
              </li>
            ))}
        </ul>
        {seeMore === goals?.length ? (
          <Link
            className="common-btn"
            style={{ display: goals?.length > 3 ? "" : "none" }}
            onClick={() => setSeeMore(3)}
          >
            Less more
          </Link>
        ) : (
          <Link
            className="common-btn"
            style={{ display: goals?.length <= 3 ? "none" : "" }}
            onClick={() => setSeeMore(goals?.length)}
          >
            See more
          </Link>
        )}
      </div>

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Goal</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="modal-body px-3">
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3 text-start">
                <label htmlFor="title" className="form-label m-0">
                  Title <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  name="title"
                  className="form-control"
                  id="title"
                  aria-describedby="title"
                />
                <span style={{ color: "red" }}>
                  {" "}
                  {formik.touched.title && formik.errors.title ? (
                    <div>{formik.errors.title} </div>
                  ) : null}
                </span>
              </div>
              <div className="mb-3 text-start">
                <label htmlFor="type" className="form-label m-0">
                  Type
                </label>
                <select
                  className="form-select"
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  name="type"
                >
                  <option value="">Please Select</option>
                  <option value="long">Long term</option>
                  <option value="short">Short term</option>
                </select>
                <span style={{ color: "red" }}>
                  {" "}
                  {formik.touched.type && formik.errors.type ? (
                    <div>{formik.errors.type} </div>
                  ) : null}
                </span>
              </div>
              <div className="mb-3 text-start">
                <label htmlFor="description" className="form-label m-0">
                  Description
                </label>
                <textarea
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  name="description"
                  className="w-100 px-2 py-2"
                  rows="4"
                  cols="50"
                  placeholder="Write here."
                />
                <span style={{ color: "red" }}>
                  {" "}
                  {formik.touched.description && formik.errors.description ? (
                    <div>{formik.errors.description} </div>
                  ) : null}
                </span>
              </div>
              <div className="file-input">
                <button type="submit" className="save-changes">
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={editShow}
        onHide={() => {
          setEditShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Goal</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="modal-body px-3">
            <form onSubmit={editFormik.handleSubmit}>
              <div className="mb-3 text-start">
                <label htmlFor="title" className="form-label m-0">
                  Title <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  value={editFormik.values.title}
                  onChange={editFormik.handleChange}
                  name="title"
                  className="form-control"
                  id="title"
                  aria-describedby="title"
                />
                <span style={{ color: "red" }}>
                  {" "}
                  {editFormik.touched.title && editFormik.errors.title ? (
                    <div>{editFormik.errors.title} </div>
                  ) : null}
                </span>
              </div>
              <div className="mb-3 text-start">
                <label htmlFor="type" className="form-label m-0">
                  Type
                </label>
                <select
                  className="form-select"
                  value={editFormik.values.type}
                  onChange={editFormik.handleChange}
                  name="type"
                >
                  <option value="">Please Select</option>
                  <option value="long">Long term</option>
                  <option value="short">Short term</option>
                </select>
                <span style={{ color: "red" }}>
                  {" "}
                  {formik.touched.type && formik.errors.type ? (
                    <div>{formik.errors.type} </div>
                  ) : null}
                </span>
              </div>
              <div className="mb-3 text-start">
                <label htmlFor="description" className="form-label m-0">
                  Description
                </label>
                <textarea
                  value={editFormik.values.description}
                  onChange={editFormik.handleChange}
                  name="description"
                  className="w-100 px-2 py-2"
                  rows="4"
                  cols="50"
                  placeholder="Write here."
                />
                <span style={{ color: "red" }}>
                  {" "}
                  {editFormik.touched.description &&
                  editFormik.errors.description ? (
                    <div>{editFormik.errors.description} </div>
                  ) : null}
                </span>
              </div>
              <div className="file-input">
                <button type="submit" className="save-changes">
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
