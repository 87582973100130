export const profileSelector = (state) => {
  const {
    skills,
    educations,
    experiences,
    errorMessage,
    isLoggingStatus,
    followers,
    followings,
    connections,
  } = state.profile;
  return {
    skills,
    educations,
    experiences,
    errorMessage,
    isLoggingStatus,
    followers,
    followings,
    connections,
  };
};
