import React from "react";
import popular from "../Components/Assets/images/popluar.svg";
import thumb from "../Components/Assets/images/post-thumbnail.png";
import three_stars from "../Components/Assets/images/three-stars.svg";
import one_star from "../Components/Assets/images/one-star.svg";
import { useSelector, useDispatch } from "react-redux";
import { postSelector } from "../Features/posts/postsSelector";
import { getPopularPostsThunk } from "../Features/posts/postThunk";
import { authSelector } from "../Features/auth/authSelector";
import { useEffect } from "react";
import Rating from "./Posts/Rating";
import { Accordion } from "react-bootstrap";
export default function Popularposts() {
  const { popularPosts } = useSelector(postSelector);
  const { token } = useSelector(authSelector);

  const dispatch = useDispatch();
  const callApi = async () => {
    try {
      const res = await dispatch(getPopularPostsThunk(token));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    callApi();
  }, []);

  return (
    <>
      <Accordion>
        <Accordion.Item
          eventKey="0"
          className="side-tab popular-posts second-tab"
        >
          <Accordion.Header>
            <div className="title-with-icon d-flex justify-content-between position-relative mb-0 border-0">
              <div className="titel">
                <h3 className="mb-1">Popular Posts</h3>
                <p className="text-start mb-1">Over the past 24 hours</p>
              </div>
              <div className="icon">
                <img src={popular} />
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <ul className="posts-list list-unstyled p-0">
              {popularPosts?.map((post) => {
                return (
                  <li className="position-relative" key={post?._id}>
                    <div className="d-flex align-items-center">
                      {post?.user?.image ? (
                        <img
                          className="post-thumb me-2"
                          src={`${process.env.REACT_APP_API_URL}/uploads/users/${post?.user?.image}`}
                        />
                      ) : (
                        <div
                          className="me-3 avatar-text d-flex align-items-center justify-content-center"
                          style={{ background: "grey" }}
                        >
                          <span className="text-uppercase text-white">
                            {post?.user?.firstName?.slice(0, 1)}{" "}
                          </span>
                        </div>
                      )}

                      <div className="post-content text-start">
                        <h4>{post?.description?.slice(0, 15)}</h4>
                        <p className="mb-0">{post?.user?.firstName}</p>
                      </div>
                      {/* <div className="stars-quantity d-flex">
                    <img src={three_stars} />
                  </div> */}

                      <Rating value={post?.ratingOfPost} onChange={() => ""} />
                    </div>
                  </li>
                );
              })}
            </ul>{" "}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1"></Accordion.Item>
      </Accordion>
    </>
  );
}
