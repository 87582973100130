import React, { useEffect, useState } from "react";
import Navbar from "../Header/Navbar";
import { useSelector } from "react-redux";
import { authSelector } from "../../Features/auth/authSelector";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function MyNetwork() {
  const { token } = useSelector(authSelector);
  const [sentReq, setSentReq] = useState();
  const [invitations, setInvitations] = useState([]);
  const navigate = useNavigate();
  const callAPI = async () => {
    try {
      const getInvitations = await axios.get(
        `${process.env.REACT_APP_API_URL}/connections/get-invitaions`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (getInvitations?.status == 200) {
        setInvitations(getInvitations?.data?.body);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    callAPI();
  }, []);

  const handleAcceptReq = async (item) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/connections/update-receive-req`,
        {
          from_user_id: item?.to_user_id,

          status: "accepted",
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (res?.status == 200) {
        callAPI();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleIgnoreReq = async (item) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/connections/update-receive-req`,
        {
          from_user_id: item?.user?._id,
          status: "rejected",
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (res?.status == 200) {
        callAPI();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        <Navbar />

        <div className="container main">
          <div className="row">
            {/* <div className="col-lg-3 col-md-4 col-sm-12 left">
              <div className="sticky-side">
                <div className="side-tab p-3 position-relative">
                  <div className="text-start mb-3">
                    <h5 className="mb-2 fs-6">Manage my network</h5>
                  </div>
                  <ul className="list-unstyled mb-0">
                    <li className="mb-3 position-relative">
                      <a href="/home" className="d-flex">
                        <p className="mb-0  ms-3">Connections</p>
                      </a>
                    </li>
                    <li className="mb-3 position-relative">
                      <a href="/home" className="d-flex">
                        <p className="mb-0  ms-3">Following & followers</p>
                      </a>
                    </li>
                    <li className="mb-3 position-relative">
                      <a href="/home" className="d-flex">
                        <p className="mb-0 ms-3">Group</p>
                      </a>
                    </li>
                    <li className="mb-3 position-relative">
                      <a href="/home" className="d-flex">
                        <p className="mb-0  ms-3">Events</p>
                      </a>
                    </li>
                    <li className="mb-3 position-relative">
                      <a href="/home" className="d-flex">
                        <p className="mb-0  ms-3">Pages</p>
                        <span className="new">2</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="col-lg-12 col-md-12 col-sm-12 center">
              <div className="card rounded-4">
                <div className="card-header bg-transparent">
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="fw-normal fs-6 mb-0">Invitations</h4>
                    <div>
                      <button
                        className="border-0 bg-transparent text-muted fs-6 h4 mb-0"
                        onClick={() => navigate("/mynetwork/invitaions")}
                      >
                        see all{" "}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="invitation-list-wrapper p-0">
                  <ul className="list-group list-group-flush">
                    {invitations?.length > 0 ? (
                      invitations?.map((item) => {
                        return (
                          <li
                            className="list-group-item d-flex justify-content-between align-items-center py-3"
                            key={item?._id}
                          >
                            <div className="user-field d-flex w-50 align-items-center">
                              {item?.user?.image ? (
                                <img
                                  className="border-thumb"
                                  src={`${process.env.REACT_APP_API_URL}/uploads/users/${item?.user?.image}`}
                                />
                              ) : (
                                <div
                                  className="me-3 avatar-text d-flex align-items-center justify-content-center"
                                  style={{ background: "grey" }}
                                >
                                  <span className="text-uppercase text-white">
                                    {item?.user?.firstName?.slice(0, 1)}{" "}
                                  </span>
                                </div>
                              )}

                              <div className="company-detail ms-4">
                                <h4 className="company-title fs-6 mb-0">
                                  {`${item?.user?.firstName} ${item?.user?.lastName}`}
                                </h4>
                                <div className="d-flex follower-area">
                                  <p clclassNameass="mb-0">
                                    {item?.user?.profession}
                                  </p>
                                </div>
                                <p className="text-muted date-wrapper">
                                  {moment(item?.createdAt).fromNow()}
                                </p>
                              </div>
                            </div>
                            <div className="">
                              <button
                                className="connect view-page-btn me-2"
                                onClick={() => handleIgnoreReq(item)}
                              >
                                Ignore{" "}
                              </button>
                              <button
                                className="connect following-btn "
                                onClick={() => handleAcceptReq(item)}
                              >
                                <span className="ms-1">Accept</span>
                              </button>
                            </div>
                          </li>
                        );
                      })
                    ) : (
                      <li className="list-group-item d-flex justify-content-between align-items-center py-3">
                        <div className="user-field d-flex w-50 align-items-center text-center">
                          No connection found
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
