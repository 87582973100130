import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import mobilelogo from "../Assets/images/Connexus_Logo_Mobile.png";
import home from "../Assets/images/home.svg";
import post from "../Assets/images/post.svg";
import messageicon from "../Assets/images/message.svg";
import thomas from "../Assets/images/thomas.png";
import notifications from "../Assets/images/notify.svg";
import network from "../Assets/images/group.png";
import user4 from "../Assets/images/user4.png";

import badge from "../Assets/images/premium.png";
import search from "../Assets/images/search.svg";
import lightarr from "../Assets/images/light-arrow.svg";
import arrow from "../Assets/images/white-arrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../Features/auth/authSelector";
import { useFormik } from "formik";
import axios from "axios";
import { searchResultThunk } from "../../Features/auth/authThunk";
import { Dropdown } from "react-bootstrap";
import toast from "react-hot-toast";
import MyNetwork from "../MyNetwork/MyNetwork";
import classNames from "classnames";
import {
  getMessageConnectionThunk,
  getUserMessageThunk,
  createMessageThunk,
} from "../../Features/message/messageThunk";
import { messageSelector } from "../../Features/message/messageSelector";
import io from "socket.io-client";
import moment from "moment";
import pin from "../Assets/images/pin.svg";
import sharepictre from "../Assets/images/share-images.svg";
import * as Yup from "yup";
import { getNotificationThunk } from "../../Features/notification/notificationThunk";
import { notificationSelector } from "../../Features/notification/notificationSelector";
import { getUserThunk } from "../../Features/user/userThunk";
import { userSelector } from "../../Features/user/userSelector";
import logo from "../Assets/images/Connexus_Logo.png";
import { createNotificationThunk } from "../../Features/notification/notificationThunk";

export default function Navbar(props) {
  const inputRef = useRef(null);
  const [socket, setSocket] = useState(null);
  const socketInstance = io(process.env.REACT_APP_API_SERVER);
  const [show, setShow] = useState(false);
  const { connections, messages } = useSelector(messageSelector);
  const [defaultUser, setDefaultUser] = useState("");
  const { latestNotifications } = useSelector(notificationSelector);

  const [attachment, setAttachment] = useState("");
  const [image, setImage] = useState("");

  const [showMsg, setShowMsg] = useState(true);
  const [showSearch, setShowSearch] = useState(false);

  const [isActive, setIsActive] = useState(false);

  const { token, userData } = useSelector(authSelector);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [input, setInput] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchHistory, setSearchHistory] = useState(
    JSON.parse(localStorage.getItem("searchHistory")) || []
  );
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { users } = useSelector(userSelector);
  const [userSelected, setUserSelected] = useState("");

  const fetchData = async (value) => {
    navigate("/results", {
      state: {
        keyword: value,
      },
    });

    // const res = await dispatch(searchResultThunk({ value, token }));

    // if (res?.payload?.status === 200) {
    //   if (res?.payload?.data?.body?.length > 0) {
    //     navigate("/results", {
    //       state: {
    //         keyword: value,
    //         data: res?.payload?.data?.body,
    //         loading: isLoading,
    //       },
    //     });
    //   } else {
    //     toast.warn("Data not found");
    //     formik.resetForm();
    //     navigate("/no-result-found");
    //    }
    // }
  };

  const handleSearch = () => {
    const updatedHistory = [searchTerm, ...searchHistory];

    if (updatedHistory.length > 5) {
      updatedHistory.pop();
    }

    setSearchHistory(updatedHistory);
    localStorage.setItem("searchHistory", JSON.stringify(updatedHistory));
    localStorage.setItem("searchTerm", searchTerm);
  };

  const formik = useFormik({
    initialValues: {
      search: searchTerm,
    },
    onSubmit: async (values, { resetForm }) => {

      console.log("pathname", pathname);
      if (pathname === "/results") {
        const searchHistory = JSON.parse(localStorage.getItem("searchHistory"));
        let value = searchHistory[0];
        await dispatch(searchResultThunk({ value: values.search, token }));
      } else {
        navigate("/results", {
          state: {
            keyword: values.search,
          },
        });
        resetForm();
      }
      handleSearch();
    },
  });

  useEffect(() => {
    setSearchTerm(localStorage.getItem("searchTerm"));
    formik.setFieldValue("search", localStorage.getItem("searchTerm"));
    const socketInstance = io(process.env.REACT_APP_API_SERVER);

    if (props.collegueLink && props.collegueLink === 1) {
      inputRef.current.focus();
    }

    socketInstance.emit("join", {
      room: defaultUser?.uniquekey,
      userId: userData?._id,
      username: userData?.firstName,
    });

    socketInstance.on("sendMessageToClient", async (data) => {
      if (data.room === defaultUser?.uniquekey) {
        await dispatch(
          getUserMessageThunk({
            token: token,
            userId: defaultUser?.other_user_id,
          })
        );
      }
    });

    setSocket(socketInstance);

    callApi();

    return () => {
      socketInstance.disconnect();
    };
  }, [defaultUser, props.collegueLink]);

  const callApi = async () => {
    try {
      await dispatch(getMessageConnectionThunk({ token: token, keyword: "" }));
      await dispatch(getNotificationThunk({ token: token }));
    } catch (error) {
      console.log({ error });
    }
  };

  const getMessages = async (item) => {
    setDefaultUser(item);
    try {
      await dispatch(
        getUserMessageThunk({
          token: token,
          userId: item?.other_user_id,
        })
      );
    } catch (error) {
      console.log({ error });
    }
  };

  const downloadFile = async (attachment) => {
    const fileUrl =
      process.env.REACT_APP_API_URL +
      "/uploads/message/" +
      attachment?.filename;
    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = attachment?.filename; // Specify the desired file name
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const validationSchema = Yup.object({
    content: Yup.string().required("Please enter message"),
    //receiver_id: Yup.string().required("This field is required"),
  });

  const formik2 = useFormik({
    initialValues: {
      content: "",
      receiver_id: defaultUser?.other_user_id,
      sender_id: userData?._id,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        let apiResponse = {};
        if (
          defaultUser?.other_user_id === undefined ||
          defaultUser?.other_user_id === ""
        ) {
          toast.error("Please select user to send message", {
            position: "top-right",
          });
        } else {
          apiResponse = await dispatch(
            createMessageThunk({
              ...values,
              receiver_id: defaultUser?.other_user_id,
              token: token,
              image: image,
              attachment: attachment,
            })
          );
        }

        if (apiResponse?.payload?.status === 200) {
          socket.emit("sendMessageToServer", {
            room: defaultUser?.uniquekey,
            message: apiResponse?.payload?.data?.body,
          });

          if (userData?.message_notification === 'On') {
            const apiNotificationResponse = await dispatch(
              createNotificationThunk({
                userId: values.receiver_id,
                token: token,
                content:
                  userData?.firstName +
                  " " +
                  userData?.lastName +
                  " has messaged you",
                parent_id: apiResponse?.payload?.data?.body?._id,
                type: "message",
              })
            );
          }

          await dispatch(
            getUserMessageThunk({
              token: token,
              userId: defaultUser?.other_user_id,
            })
          );
          formik2.setFieldValue("content", "");
          setAttachment("");
          setImage("");
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
        });
      }
    },
  });

  const DateToTime = (date) => {
    return moment(date).format("HH:mm A");
  };

  const handleSearchOption = async (keyword) => {
    setUserSearchTerm(keyword);
    setUserSelected("");
    await dispatch(getUserThunk({ token: token, keyword: keyword }));
  };

  const handleSelectedUser = async (user) => {
    setUserSelected(user?._id);
    setSearchTerm(user?.firstName + " " + user?.lastName);
    setDefaultUser({ otherUserDetail: user, other_user_id: user?._id });
    formik2.setFieldValue("receiver_id", user?._id);
    setShowMsg(true);
    setShowSearch(false);
    try {
      await dispatch(getUserMessageThunk({ token: token, userId: user?._id }));
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light py-2.5 hdr">
        <div className="container flex-nowrap">
          <Link className="navbar-brand" to="/home">
            <div style={{ width: "90px" }}>
              <img
                src={require("../Assets/images/logo07.svg").default}
                className="desktop-logo d-none d-md-block"
              />
              <img
                src={require("../Assets/images/logo07.svg").default}
                className="mobile-logo d-block d-md-none"
              />
            </div>
          </Link>

          <form
            onSubmit={formik.handleSubmit}
            className=" mx-5 search-bar position-relative"
          >
            <div className="search-bar-inner-wrapper">
              <img src={search} />

              <input
                className="form-control me-2 "
                type="text"
                placeholder="Find"
                autoComplete="off"
                name="search"
                value={formik.values.search}
                // onBlur={handleSearch}
                onChange={(e) => {
                  formik.handleChange(e);
                  setSearchTerm(e.target.value);
                }}
                ref={inputRef}
              />
            </div>

            <button type="submit"></button>
          </form>

          <div className=" navbar-collapse d-flex" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 flex-row">
              <li className="nav-item mx-3">
                <Link
                  to="/home"
                  className={
                    "nav-link d-flex flex-column align-items-center " +
                    (pathname === "/home" ? "active" : "")
                  }
                  aria-current="page"
                >
                  <img
                    className={pathname === "/home" ? "active" : ""}
                    src={home}
                    width={20}
                  />
                  <span className="d-block mt-2">Explore</span>
                </Link>
              </li>
              <li className="nav-item  mx-3">
                <Link
                  to="/mynetwork"
                  className={
                    "nav-link d-flex flex-column align-items-center " +
                    (pathname === "/mynetwork" ? "active" : "")
                  }
                >
                  <img src={network} width={20} className="net-img" />
                  <span className="d-block mt-2">My Network</span>
                </Link>
              </li>

              <li className="nav-item  mx-3">
                <Link
                  to="/Messages"
                  className={
                    "nav-link d-flex flex-column align-items-center " +
                    (pathname === "/Messages" ? "active" : "")
                  }
                >
                  <img
                    className={pathname === "/Messages" ? "active" : ""}
                    src={messageicon}
                    width={20}
                  />
                  <span className="d-block mt-2">Messages</span>
                </Link>
              </li>
              <li className="nav-item position-relative mx-3">
                <Link
                  to="/Notification"
                  className={
                    "nav-link d-flex flex-column align-items-center " +
                    (pathname === "/Notification" ? "active" : "")
                  }
                >
                  <img
                    className={pathname === "/Notification" ? "active" : ""}
                    src={notifications}
                    width={20}
                  />
                  <span className="d-block mt-2">Notifications</span>
                  {latestNotifications?.length > 0 && (
                    <span className="count">{latestNotifications?.length}</span>
                  )}
                </Link>
              </li>
            </ul>
            {userData ? (
              <>
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0 profile-menu">
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div className="profile d-flex align-items-center">
                        {/* <img className="thumb" src={thomas} />{" "} */}
                        {userData?.image ? (
                          <img
                            className="me-3 profile-img"
                            src={`${process.env.REACT_APP_API_URL}/uploads/users/${userData?.image}`}
                          />
                        ) : (
                          <div
                            className="me-3 avatar-text d-flex align-items-center justify-content-center"
                            style={{ background: "grey" }}
                          >
                            <span className="text-uppercase text-white font-lg">
                              {userData?.firstName?.slice(0, 1)}{" "}
                            </span>
                          </div>
                        )}
                        <div className="d-flex align-items-center">
                          <p className="mx-2 my-0">
                            {userData?.firstName} {userData?.lastName}
                          </p>{" "}
                          <img className="arrow" src={arrow} />
                        </div>
                      </div>
                    </a>
                    <ul
                      className="dropdown-menu p-0"
                      aria-labelledby="navbarDropdown"
                    >
                      <div className="opt-profile px-4 py-4">
                        <div className="profile-opt d-flex  align-items-center">
                          {userData?.image ? (
                            <img
                              className="user"
                              src={`${process.env.REACT_APP_API_URL}/uploads/users/${userData?.image}`}
                            />
                          ) : (
                            <div
                              className="me-3 avatar-text d-flex align-items-center justify-content-center"
                              style={{ background: "grey" }}
                            >
                              <span className="text-uppercase text-white font-lg">
                                {userData?.firstName?.slice(0, 1)}{" "}
                              </span>
                            </div>
                          )}
                          {/* <img src={`${process.env.REACT_APP_API_URL}/uploads/users/${userData?.image}`} /> */}
                          <div className="user-detail ps-2">
                            <a href="/">
                              <h3 className="mb-1">
                                {userData?.firstName} {userData?.lastName}
                              </h3>
                              <p className="mb-0 d-flex align-items-center">
                                {userData?.profession
                                  ? userData?.profession
                                  : ""}
                                {/* <img className="premium ms-1" src={badge} /> */}
                              </p>
                            </a>
                          </div>
                        </div>
                        <Link to="/Profile">
                          <button
                            type="button"
                            className="btn btn-outline-darkblue mt-3"
                          >
                            View Profile
                          </button>
                        </Link>
                        <div className="account-menu mt-4">
                          {/* <label>Account</label> */}
                          <ul className="list-unstyled p-0 mt-2">
                            {/* <li className=" mb-2">
                              <Link className="dropdown-item d-flex justify-content-between px-2 py-1">
                                <p className="mb-0">Account</p>{" "}
                                <img src={lightarr} />
                              </Link>
                            </li> */}
                            <li className=" mb-2">
                              <Link
                                className="dropdown-item d-flex justify-content-between px-2 py-1"
                                to="/settings"
                                state={{ tab: "v-pills-home-tab" }}
                              >
                                <p className="mb-0"> Account Preferences </p>{" "}
                                <img src={lightarr} />
                              </Link>
                            </li>
                            <li className=" mb-2">
                              <Link
                                className="dropdown-item d-flex justify-content-between px-2 py-1"
                                to="/settings"
                                state={{ tab: "v-pills-profile-tab" }}
                              >
                                <p className="mb-0">Sign in & Security </p>{" "}
                                <img src={lightarr} />
                              </Link>
                            </li>
                            <li className=" mb-2">
                              <Link
                                className="dropdown-item d-flex justify-content-between px-2 py-1"
                                to="/settings"
                                state={{ tab: "v-pills-settings-tab" }}
                              >
                                <p className="mb-0"> Notification </p>{" "}
                                <img src={lightarr} />
                              </Link>
                            </li>
                            {token ? (
                              ""
                            ) : (
                              <li className=" mb-2">
                                <Link
                                  className="dropdown-item d-flex justify-content-between px-2 py-1"
                                  to="/"
                                >
                                  <p className="mb-0">Sign In</p>{" "}
                                  <img src={lightarr} />
                                </Link>
                              </li>
                            )}
                          </ul>
                          <button
                            type="button"
                            onClick={() => {
                              window.location.reload();
                              navigate("/");
                              localStorage.removeItem("token");
                              localStorage.removeItem("user");
                              localStorage.removeItem("loggedin");
                            }}
                            className="btn btn-darkblue mt-3"
                          >
                            Logout
                          </button>
                        </div>
                      </div>
                    </ul>
                  </li>
                </ul>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </nav>

      <div className={classNames("rightside-popup-wrapper", { show: show })}>
        <button className="open-button d-flex align-items-center justify-content-between w-100">
          <div
            className="d-flex align-items-center"
            onClick={() => setShow(!show)}
          >
            <div className="chat-img me-3 position-relative">
              {userData?.image ? (
                <img
                  className="me-3 profile-img"
                  src={`${process.env.REACT_APP_API_URL}/uploads/users/${userData?.image}`}
                />
              ) : (
                <div
                  className="me-3 avatar-text d-flex align-items-center justify-content-center"
                  style={{ background: "grey" }}
                >
                  <span className="text-uppercase text-white font-lg">
                    {userData?.firstName?.slice(0, 1)}{" "}
                  </span>
                </div>
              )}
              <span className="active-status-dot"></span>
            </div>
            <h4 className="mb-0 messaging-heading">Messaging</h4>
          </div>

          <ul className="chat-svg-listing d-flex align-items-center mb-0">
            {/* <li className="me-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={14}
                height={4}
                viewBox="0 0 14 4"
                fill="none"
              >
                <path
                  d="M2.47954 3.64579C2.08326 3.64579 1.7032 3.48528 1.42299 3.19957C1.14277 2.91386 0.985352 2.52636 0.985352 2.1223C0.985352 1.71825 1.14277 1.33074 1.42299 1.04503C1.7032 0.759326 2.08326 0.598816 2.47954 0.598816C2.87582 0.598816 3.25588 0.759326 3.53609 1.04503C3.81631 1.33074 3.97373 1.71825 3.97373 2.1223C3.97373 2.52636 3.81631 2.91386 3.53609 3.19957C3.25588 3.48528 2.87582 3.64579 2.47954 3.64579ZM7.46017 3.64579C7.06389 3.64579 6.68383 3.48528 6.40362 3.19957C6.1234 2.91386 5.96598 2.52636 5.96598 2.1223C5.96598 1.71825 6.1234 1.33074 6.40362 1.04503C6.68383 0.759326 7.06389 0.598816 7.46017 0.598816C7.85645 0.598816 8.23651 0.759326 8.51672 1.04503C8.79694 1.33074 8.95436 1.71825 8.95436 2.1223C8.95436 2.52636 8.79694 2.91386 8.51672 3.19957C8.23651 3.48528 7.85645 3.64579 7.46017 3.64579ZM12.4408 3.64579C12.0445 3.64579 11.6645 3.48528 11.3843 3.19957C11.104 2.91386 10.9466 2.52636 10.9466 2.1223C10.9466 1.71825 11.104 1.33074 11.3843 1.04503C11.6645 0.759326 12.0445 0.598816 12.4408 0.598816C12.8371 0.598816 13.2171 0.759326 13.4974 1.04503C13.7776 1.33074 13.935 1.71825 13.935 2.1223C13.935 2.52636 13.7776 2.91386 13.4974 3.19957C13.2171 3.48528 12.8371 3.64579 12.4408 3.64579Z"
                  fill="white"
                  fillOpacity="0.72"
                />
              </svg>
            </li> */}
            <li
              className="me-3 cursor-pointer"
              onClick={() => setShowSearch(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={12}
                height={13}
                viewBox="0 0 12 13"
                fill="none"
              >
                <path
                  d="M1.5046 12.0576H9.719C9.91094 12.0576 10.101 12.0195 10.2783 11.9455C10.4557 11.8715 10.6168 11.763 10.7525 11.6263C10.8883 11.4896 10.9959 11.3272 11.0694 11.1486C11.1428 10.9699 11.1806 10.7785 11.1806 10.5851V7.46348C11.1806 7.30727 11.119 7.15746 11.0094 7.04701C10.8997 6.93655 10.751 6.8745 10.596 6.8745C10.4409 6.8745 10.2922 6.93655 10.1826 7.04701C10.0729 7.15746 10.0113 7.30727 10.0113 7.46348V10.5851C10.0113 10.6632 9.98052 10.7381 9.9257 10.7933C9.87088 10.8486 9.79653 10.8796 9.719 10.8796H1.5046C1.42707 10.8796 1.35272 10.8486 1.2979 10.7933C1.24308 10.7381 1.21228 10.6632 1.21228 10.5851V2.30986C1.21228 2.23176 1.24308 2.15685 1.2979 2.10163C1.35272 2.0464 1.42707 2.01537 1.5046 2.01537H4.60327C4.75833 2.01537 4.90704 1.95332 5.01668 1.84286C5.12633 1.73241 5.18792 1.5826 5.18792 1.42639C5.18792 1.27018 5.12633 1.12037 5.01668 1.00991C4.90704 0.899456 4.75833 0.837402 4.60327 0.837402H1.5046C1.11695 0.837402 0.745182 0.992536 0.471072 1.26868C0.196962 1.54482 0.0429688 1.91934 0.0429688 2.30986V10.5851C0.0429688 10.9756 0.196962 11.3501 0.471072 11.6263C0.745182 11.9024 1.11695 12.0576 1.5046 12.0576Z"
                  fill="white"
                  fillOpacity="0.72"
                />
                <path
                  d="M10.6691 0.616544C10.3927 0.341386 10.0198 0.187073 9.63129 0.187073C9.24275 0.187073 8.86987 0.341386 8.59353 0.616544L3.55089 5.69654L3.2001 7.86106C3.17522 8.0005 3.18344 8.14389 3.22408 8.27953C3.26473 8.41516 3.33664 8.53918 3.43396 8.64146C3.60439 8.80214 3.82904 8.89161 4.06246 8.89178H4.20862L6.35723 8.53839L11.3999 3.44367C11.673 3.16528 11.8262 2.78964 11.8262 2.39822C11.8262 2.0068 11.673 1.63116 11.3999 1.35277L10.6691 0.616544ZM9.83592 1.45585L10.5667 2.19208C10.6083 2.23347 10.6372 2.28594 10.6501 2.34332C10.6631 2.4007 10.6595 2.46061 10.6398 2.51602L9.51436 1.38222H9.63129C9.66871 1.37808 9.70657 1.38255 9.74202 1.39531C9.77746 1.40806 9.80957 1.42876 9.83592 1.45585ZM5.80181 7.41932L4.41325 7.65491L4.64711 6.25607L8.66661 2.2068L9.8213 3.37005L5.80181 7.41932Z"
                  fill="white"
                  fillOpacity="0.72"
                />
              </svg>
            </li>
            <li className="downChev_icon" onClick={() => setShow(!show)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={12}
                height={7}
                viewBox="0 0 12 7"
                fill="none"
              >
                <path
                  d="M11.4881 5.96975C11.3359 6.12074 11.1296 6.20556 10.9145 6.20556C10.6993 6.20556 10.493 6.12074 10.3409 5.96975L6.325 1.98309L2.30912 5.96975C2.15611 6.11646 1.95118 6.19764 1.73846 6.1958C1.52574 6.19397 1.32226 6.10927 1.17184 5.95994C1.02142 5.81062 0.936099 5.60861 0.93425 5.39745C0.932401 5.18628 1.01418 4.98283 1.16196 4.83094L5.75142 0.274868C5.90356 0.123882 6.10988 0.0390623 6.325 0.0390623C6.54013 0.0390623 6.74645 0.123882 6.89859 0.274868L11.4881 4.83094C11.6401 4.98197 11.7256 5.18678 11.7256 5.40034C11.7256 5.6139 11.6401 5.81872 11.4881 5.96975Z"
                  fill="white"
                  fillOpacity="0.72"
                />
              </svg>
            </li>
          </ul>
        </button>
        <div className={show ? "chat-popup-screen show" : "hide"}>
          <form>
            <ul className="ps-0 mb-0 chat-messaging-list">
              {connections?.length > 0 &&
                connections?.map((item) => {
                  return (
                    item?.otherUserDetail !== null && (
                      <li
                        className={
                          "d-flex align-items-center border-bottom justify-content-between position-relative " +
                          (defaultUser?.uniquekey === item?.uniquekey
                            ? "active"
                            : "")
                        }
                        key={item?.uniquekey}
                        onClick={() => getMessages(item)}
                      >
                        <div className="d-flex align-items-center">
                          <div className="chat-img me-3 position-relative">
                            {item?.otherUserDetail?.image ? (
                              <img
                                className="me-3"
                                src={`${process.env.REACT_APP_API_URL}/uploads/users/${item?.otherUserDetail?.image}`}
                              />
                            ) : (
                              <div
                                className="me-3 avatar-text d-flex align-items-center justify-content-center"
                                style={{ background: "grey" }}
                              >
                                <span className="text-uppercase text-white">
                                  {item?.otherUserDetail?.firstName?.slice(
                                    0,
                                    1
                                  )}{" "}
                                </span>
                              </div>
                            )}
                            <span className="active-status-dot"></span>
                          </div>
                          <div>
                            <h4 className="mb-0 messaging-heading">
                              {item?.otherUserDetail?.firstName}{" "}
                              {item?.otherUserDetail?.lastName}
                            </h4>
                            {/* <p className="mb-0 sub-heading">
                          Smith Dean: Hi Thomas!
                        </p> */}
                          </div>
                        </div>
                        {/* <Dropdown>
                      <Dropdown.Toggle>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={11}
                          height={3}
                          viewBox="0 0 11 3"
                          fill="none"
                        >
                          <path
                            d="M1.96904 2.4892C1.6873 2.4892 1.4171 2.37509 1.21788 2.17196C1.01866 1.96883 0.906738 1.69334 0.906738 1.40607C0.906738 1.11881 1.01866 0.843306 1.21788 0.640179C1.4171 0.437052 1.6873 0.322937 1.96904 0.322937C2.25078 0.322937 2.52098 0.437052 2.7202 0.640179C2.91943 0.843306 3.03135 1.11881 3.03135 1.40607C3.03135 1.69334 2.91943 1.96883 2.7202 2.17196C2.52098 2.37509 2.25078 2.4892 1.96904 2.4892ZM5.51006 2.4892C5.22831 2.4892 4.95811 2.37509 4.75889 2.17196C4.55967 1.96883 4.44775 1.69334 4.44775 1.40607C4.44775 1.11881 4.55967 0.843306 4.75889 0.640179C4.95811 0.437052 5.22831 0.322937 5.51006 0.322937C5.7918 0.322937 6.062 0.437052 6.26122 0.640179C6.46044 0.843306 6.57236 1.11881 6.57236 1.40607C6.57236 1.69334 6.46044 1.96883 6.26122 2.17196C6.062 2.37509 5.7918 2.4892 5.51006 2.4892ZM9.05107 2.4892C8.76933 2.4892 8.49913 2.37509 8.29991 2.17196C8.10069 1.96883 7.98876 1.69334 7.98876 1.40607C7.98876 1.11881 8.10069 0.843306 8.29991 0.640179C8.49913 0.437052 8.76933 0.322937 9.05107 0.322937C9.33281 0.322937 9.60301 0.437052 9.80223 0.640179C10.0015 0.843306 10.1134 1.11881 10.1134 1.40607C10.1134 1.69334 10.0015 1.96883 9.80223 2.17196C9.60301 2.37509 9.33281 2.4892 9.05107 2.4892Z"
                            fill="#666666"
                          />
                        </svg>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={18}
                            height={18}
                            viewBox="0 0 18 18"
                            fill="none"
                            className="me-2"
                          >
                            <path
                              d="M7.91219 3.95023C7.9654 3.90065 8.00808 3.84086 8.03768 3.77443C8.06728 3.708 8.0832 3.63629 8.08448 3.56357C8.08576 3.49086 8.07239 3.41863 8.04515 3.35119C8.01791 3.28376 7.97737 3.2225 7.92594 3.17107C7.87452 3.11965 7.81326 3.0791 7.74582 3.05187C7.67839 3.02463 7.60616 3.01125 7.53344 3.01254C7.46073 3.01382 7.38901 3.02973 7.32258 3.05933C7.25615 3.08893 7.19636 3.13161 7.14678 3.18482L2.63372 7.69788C2.53231 7.79942 2.47534 7.93707 2.47534 8.08058C2.47534 8.2241 2.53231 8.36175 2.63372 8.46329L7.14678 12.9763C7.19636 13.0296 7.25615 13.0722 7.32258 13.1018C7.38901 13.1314 7.46073 13.1473 7.53344 13.1486C7.60616 13.1499 7.67839 13.1365 7.74582 13.1093C7.81326 13.0821 7.87452 13.0415 7.92594 12.9901C7.97737 12.9387 8.01791 12.8774 8.04515 12.81C8.07239 12.7425 8.08576 12.6703 8.08448 12.5976C8.0832 12.5249 8.06728 12.4532 8.03768 12.3867C8.00808 12.3203 7.9654 12.2605 7.91219 12.2109L4.32341 8.62215H12.5841C12.8922 8.62196 13.1974 8.68248 13.4821 8.80026C13.7668 8.91804 14.0255 9.09077 14.2435 9.30857C14.4614 9.52638 14.6343 9.78499 14.7522 10.0696C14.8702 10.3543 14.9309 10.6594 14.9309 10.9675V14.2183C14.9309 14.362 14.988 14.4997 15.0895 14.6013C15.1911 14.7028 15.3288 14.7599 15.4725 14.7599C15.6161 14.7599 15.7538 14.7028 15.8554 14.6013C15.957 14.4997 16.014 14.362 16.014 14.2183V10.9675C16.014 10.5171 15.9253 10.0712 15.7529 9.65514C15.5805 9.23908 15.3279 8.86107 15.0093 8.54268C14.6908 8.2243 14.3127 7.97179 13.8966 7.79958C13.4804 7.62737 13.0345 7.53883 12.5841 7.53902H4.32341L7.91219 3.95023Z"
                              fill="#3C3C3C"
                            />
                          </svg>
                          Reply
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={18}
                            height={19}
                            viewBox="0 0 18 19"
                            fill="none"
                            className="me-2"
                          >
                            <path
                              d="M7.89039 10.6435L3.55786 14.976M11.501 3.06152L15.4723 7.03283L12.0171 10.3234C11.911 10.4246 11.8265 10.5462 11.7688 10.681C11.7111 10.8158 11.6813 10.9609 11.6814 11.1076V13.1271C11.6814 13.6091 11.0981 13.8512 10.7569 13.51L5.02388 7.77694C4.6827 7.43576 4.92423 6.85249 5.40677 6.85249H7.42627C7.57291 6.85252 7.71802 6.82277 7.85281 6.76506C7.98761 6.70734 8.10928 6.62285 8.21046 6.51672L11.501 3.06152Z"
                              stroke="#3C3C3C"
                              strokeWidth="1.01544"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          Pin{" "}
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={18}
                            height={18}
                            viewBox="0 0 18 18"
                            fill="none"
                            className="me-2"
                          >
                            <path
                              d="M3.86745 5.4246H2.93905C2.81594 5.4246 2.69787 5.3757 2.61081 5.28865C2.52376 5.2016 2.47485 5.08354 2.47485 4.96043C2.47485 4.83733 2.52376 4.71927 2.61081 4.63222C2.69787 4.54517 2.81594 4.49627 2.93905 4.49627H6.65265V3.10284C6.65265 2.97973 6.70156 2.86167 6.78861 2.77462C6.87567 2.68758 6.99374 2.63867 7.11685 2.63867H10.8305C10.9536 2.63867 11.0716 2.68758 11.1587 2.77462C11.2457 2.86167 11.2947 2.97973 11.2947 3.10284V4.49627H15.0083C15.1314 4.49627 15.2494 4.54517 15.3365 4.63222C15.4235 4.71927 15.4725 4.83733 15.4725 4.96043C15.4725 5.08354 15.4235 5.2016 15.3365 5.28865C15.2494 5.3757 15.1314 5.4246 15.0083 5.4246H14.0799V15.1721C14.0799 15.2952 14.0309 15.4133 13.9439 15.5003C13.8568 15.5874 13.7388 15.6363 13.6157 15.6363H4.33165C4.20854 15.6363 4.09047 15.5874 4.00341 15.5003C3.91636 15.4133 3.86745 15.2952 3.86745 15.1721V5.4246ZM10.3663 4.49627V3.56793H7.58105V4.49627H10.3663ZM4.79585 14.7079H13.1515V5.4246H4.79585V14.7079ZM7.58105 12.8513C7.45794 12.8513 7.33987 12.8024 7.25281 12.7153C7.16576 12.6283 7.11685 12.5102 7.11685 12.3871V7.74544C7.11685 7.62233 7.16576 7.50427 7.25281 7.41722C7.33987 7.33017 7.45794 7.28127 7.58105 7.28127C7.70417 7.28127 7.82224 7.33017 7.90929 7.41722C7.99635 7.50427 8.04525 7.62233 8.04525 7.74544V12.3871C8.04525 12.5102 7.99635 12.6283 7.90929 12.7153C7.82224 12.8024 7.70417 12.8513 7.58105 12.8513ZM10.3663 12.8513C10.2431 12.8513 10.1251 12.8024 10.038 12.7153C9.95096 12.6283 9.90205 12.5102 9.90205 12.3871V7.74544C9.90205 7.62233 9.95096 7.50427 10.038 7.41722C10.1251 7.33017 10.2431 7.28127 10.3663 7.28127C10.4894 7.28127 10.6074 7.33017 10.6945 7.41722C10.7815 7.50427 10.8305 7.62233 10.8305 7.74544V12.3871C10.8305 12.5102 10.7815 12.6283 10.6945 12.7153C10.6074 12.8024 10.4894 12.8513 10.3663 12.8513Z"
                              fill="#3C3C3C"
                            />
                          </svg>
                          Delete
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={15}
                            height={15}
                            viewBox="0 0 15 15"
                            fill="none"
                            className="me-2"
                          >
                            <path
                              d="M11.777 4.20148L5.4694 10.5091L2.60229 7.642"
                              stroke="#3C3C3C"
                              strokeWidth="0.920663"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          Mark as Read
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
                      </li>
                    )
                  );
                })}
            </ul>
          </form>
        </div>
      </div>

      <div className={`message_box ${defaultUser !== "" ? "show" : "hide"}`}>
        <button className="open-button d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <div className="chat-img me-3 position-relative">
              {defaultUser?.otherUserDetail?.image ? (
                <img
                  className="me-3"
                  src={`${process.env.REACT_APP_API_URL}/uploads/users/${defaultUser?.otherUserDetail?.image}`}
                />
              ) : (
                <div
                  className="me-1 avatar-text d-flex align-items-center justify-content-center"
                  style={{ background: "grey" }}
                >
                  <span className="text-uppercase text-white">
                    {defaultUser?.otherUserDetail?.firstName?.slice(0, 1)}{" "}
                  </span>
                </div>
              )}
              <span className="active-status-dot"></span>
            </div>
            <h4 className="mb-0 messaging-heading">
              {" "}
              {userData?.firstName} {userData?.lastName}
            </h4>
          </div>

          <ul className="chat-svg-listing d-flex align-items-center mb-0">
            <li className="downChev_icon" onClick={() => setDefaultUser("")}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 384 512"
                width={12}
                fill="none"
              >
                <path
                  d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                  fill="white"
                  fillOpacity="0.72"
                />
              </svg>
            </li>
          </ul>
        </button>

        <div className={showMsg ? "chat-popup-screen show" : "hide"}>
          <div className="flex-box py-3">
            {messages?.map((item) => {
              return (
                <div
                  className={
                    "recieved-messages mx-3 my-3 " +
                    (item?.sender_id === userData?._id ? "sender" : "")
                  }
                  key={item?._id}
                >
                  <div className="recieved d-flex">
                    <div className="user-stat position-relative me-3">
                      {item?.sender?.image ? (
                        <img
                          className="me-3"
                          src={`${process.env.REACT_APP_API_URL}/uploads/users/${item?.sender?.image}`}
                        />
                      ) : (
                        <div
                          className="me-1 avatar-text d-flex align-items-center justify-content-center"
                          style={{ background: "grey" }}
                        >
                          <span className="text-uppercase text-white">
                            {item?.sender.firstName?.slice(0, 1)}{" "}
                          </span>
                        </div>
                      )}
                      <span className="status">
                        <img src="/static/media/active.2fd399eee3e03b35d8c24ca917ba1b50.svg" />
                      </span>
                    </div>
                    <div className="msg-check text-start">
                      <p className="py-2 px-2 mb-0">{item?.content} </p>
                      {item?.attachments.length > 0 &&
                        item?.attachments.map((attachment) => {
                          return (
                            <div key={attachment?._id}>
                              {attachment?.filetype === "image" && (
                                <div className="img-attachment">
                                  <img
                                    src={`${process.env.REACT_APP_API_URL}/uploads/message/${attachment?.filename}`}
                                  />
                                </div>
                              )}
                              {attachment?.filetype === "attachment" && (
                                <div className="doc-attachment">
                                  <div className="card d-flex gap-2 align-items-center flex-row">
                                    <div>
                                      <span>
                                        <svg
                                          width="32"
                                          height="42"
                                          viewBox="0 0 32 42"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M32 42H0V0H22L32 10V42Z"
                                            fill="#90CAF9"
                                          />
                                          <path
                                            d="M30.5 11H21V1.5L30.5 11Z"
                                            fill="#E1F5FE"
                                          />
                                          <path
                                            d="M8 18H25V20H8V18ZM8 22H21V24H8V22ZM8 26H25V28H8V26ZM8 30H21V32H8V30Z"
                                            fill="#1976D2"
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                    <p className="file-name mb-0">
                                      {attachment?.filename}
                                    </p>
                                    <div>
                                      <div className="dropdown">
                                        <button
                                          className="btn dropdown-toggle p-0"
                                          type="button"
                                          id="dropdownMenuButton1"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <svg
                                            width="14"
                                            height="5"
                                            viewBox="0 0 14 5"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M1.59763 4.12895C1.17391 4.12895 0.76755 3.95733 0.467936 3.65184C0.168322 3.34635 0 2.93202 0 2.49999C0 2.06797 0.168322 1.65363 0.467936 1.34814C0.76755 1.04265 1.17391 0.871033 1.59763 0.871033C2.02135 0.871033 2.42772 1.04265 2.72733 1.34814C3.02694 1.65363 3.19527 2.06797 3.19527 2.49999C3.19527 2.93202 3.02694 3.34635 2.72733 3.65184C2.42772 3.95733 2.02135 4.12895 1.59763 4.12895ZM6.92308 4.12895C6.49936 4.12895 6.09299 3.95733 5.79338 3.65184C5.49377 3.34635 5.32544 2.93202 5.32544 2.49999C5.32544 2.06797 5.49377 1.65363 5.79338 1.34814C6.09299 1.04265 6.49936 0.871033 6.92308 0.871033C7.3468 0.871033 7.75316 1.04265 8.05277 1.34814C8.35239 1.65363 8.52071 2.06797 8.52071 2.49999C8.52071 2.93202 8.35239 3.34635 8.05277 3.65184C7.75316 3.95733 7.3468 4.12895 6.92308 4.12895ZM12.2485 4.12895C11.8248 4.12895 11.4184 3.95733 11.1188 3.65184C10.8192 3.34635 10.6509 2.93202 10.6509 2.49999C10.6509 2.06797 10.8192 1.65363 11.1188 1.34814C11.4184 1.04265 11.8248 0.871033 12.2485 0.871033C12.6722 0.871033 13.0786 1.04265 13.3782 1.34814C13.6778 1.65363 13.8462 2.06797 13.8462 2.49999C13.8462 2.93202 13.6778 3.34635 13.3782 3.65184C13.0786 3.95733 12.6722 4.12895 12.2485 4.12895Z"
                                              fill="#000"
                                              fillOpacity="0.85"
                                            />
                                          </svg>
                                        </button>
                                        <ul
                                          className="dropdown-menu"
                                          aria-labelledby="dropdownMenuButton1"
                                        >
                                          <li>
                                            <a
                                              target="_blank"
                                              className="dropdown-item"
                                              href={`${process.env.REACT_APP_API_URL}/uploads/message/${attachment?.filename}`}
                                              download={attachment?.filename}
                                            >
                                              Open Link
                                            </a>
                                          </li>
                                          <li>
                                            <span
                                              className="dropdown-item"
                                              onClick={() =>
                                                downloadFile(attachment)
                                              }
                                            >
                                              Download
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}

                      <span className="d-flex align-items-center mt-1">
                        <img src="/static/media/check.dbeb8ca394598682c38a98dfdd8e4aec.svg" />
                        <p className="time mb-0 ms-1">
                          {DateToTime(item?.createdAt)}
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <form
            className="w-100 chat-message-bar mt-4 p-2 position-relative"
            onSubmit={formik2.handleSubmit}
          >
            <input
              type="text"
              placeholder="Send Message"
              name="content"
              onChange={formik2.handleChange}
              value={formik2.values.content}
            />

            {defaultUser?.otherUserDetail?.image ? (
              <img
                className="chat-user-img"
                src={`${process.env.REACT_APP_API_URL}/uploads/users/${defaultUser?.otherUserDetail?.image}`}
              />
            ) : (
              <div
                className="ps-2 pt-1 chat-user-img text-uppercase text-white"
                style={{ background: "grey" }}
              >
                {defaultUser?.otherUserDetail?.firstName?.slice(0, 1)}{" "}
              </div>
            )}

            <div className="sending-opt d-flex align-items-center">
              <div className="image-input position-relative overflow-hidden">
                <input
                  type="file"
                  id="imageInput"
                  className="d-none"
                  accept=".doc, .docx, application/pdf"
                  onChange={(e) => setAttachment(e.target.files[0])}
                  name="attachment"
                />
                <label htmlFor="imageInput" className="image-button">
                  <img src={pin} />
                </label>
              </div>

              <div className="image-input position-relative overflow-hidden ms-2">
                <input
                  type="file"
                  id="imageInput2"
                  className="d-none"
                  accept="image/*"
                  onChange={(e) => setImage(e.target.files[0])}
                  name="image"
                />
                <label htmlFor="imageInput2" className="image-button">
                  <img src={sharepictre} />
                </label>
              </div>

              <button type="submit" className="me-3 border-0 bg-transparent">
                <img src="/static/media/send-btn.8fb5c6857de1348b454bb3fcedb9d498.svg" />
              </button>
            </div>
            <p className="img_name mb-0 ms-2">
              {attachment?.name} {image?.name}
            </p>
          </form>
        </div>
      </div>

      <div className={`search_user_box ${showSearch ? "show" : "hide"}`}>
        <button className="open-button d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            {/* <div className="chat-img me-3 position-relative">
              <img
                className="me-3"
                src={require("https://i.pinimg.com/originals/7d/34/d9/7d34d9d53640af5cfd2614c57dfa7f13.png")}
              />
              <span className="active-status-dot"></span>
            </div> */}
            <h4 className="mb-0 messaging-heading">Search User</h4>
          </div>

          <ul className="chat-svg-listing d-flex align-items-center mb-0">
            <li
              className="downChev_icon px-2"
              onClick={() => setShowSearch(false)}
            >
              <i className="fa-solid fa-xmark"></i>
            </li>
          </ul>
        </button>

        <div className={showSearch ? "chat-popup-screen show" : "hide"}>
          <div className="flex-box py-3">
            <div className="messages">
              <ul className="list-unstyled p-0">
                {users.length > 0 &&
                  users.map((item) => {
                    return (
                      <li
                        className="p-0"
                        onClick={() => handleSelectedUser(item)}
                        key={item?._id}
                      >
                        <a className="position-relative d-flex align-items-center gap-3 px-3 py-2 ">
                          <div className="user-stat position-relative">
                            {item?.image ? (
                              <div>
                                <img
                                  className="me-3"
                                  src={`${process.env.REACT_APP_API_URL}/uploads/users/${item?.image}`}
                                />
                              </div>
                            ) : (
                              <div
                                className="avatar-text d-flex align-items-center justify-content-center"
                                style={{ background: "grey" }}
                              >
                                <span className="text-uppercase text-white">
                                  {item?.firstName?.slice(0, 1)}{" "}
                                </span>
                              </div>
                            )}
                            <span className="status">
                              <img src="/static/media/not-active.c57c43ac2ce25ee2668264aa2c88f658.svg" />
                            </span>
                          </div>
                          <div className="name-message text-start">
                            <h4 className="mb-1 message">
                              {item?.firstName} {item?.lastname ?? ""}
                            </h4>
                            <p className="mb-0 message"></p>
                          </div>
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>

          <form className="w-100 chat-message-bar mt-4 p-2 position-relative">
            <input
              type="text"
              placeholder="Search here"
              name="content"
              className="ps-3 pe-5"
              onChange={(e) => handleSearchOption(e.target.value)}
            />

            {/* <div className="sending-opt d-flex align-items-center">
              <button type="search" className="me-3 border-0 bg-transparent">
                <img src="/static/media/send-btn.8fb5c6857de1348b454bb3fcedb9d498.svg" />
              </button>
            </div> */}
            <p className="img_name mb-0 ms-2">
              {attachment?.name} {image?.name}
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}
