import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const api_url = process.env.REACT_APP_API_URL;

export const getConnectionThunk = createAsyncThunk(
  "connection/get",
  async (body) => {
    try {
      const data = await axios.get(
        `${api_url}/connections/getAllUserConnections`,
        {
          headers: {
            Authorization: body.token,
          },
          params: {
            keyword: body.keyword,
          },
        }
      );

      return data;
    } catch (error) {
      return error;
    }
  }
);

export const getMessageConnectionThunk = createAsyncThunk(
  "connection/get",
  async (body) => {
    try {
      const data = await axios.get(
        `${api_url}/connections/getAllMessageConnections`,
        {
          headers: {
            Authorization: body.token,
          },
          params: {
            keyword: body.keyword,
          },
        }
      );

      return data;
    } catch (error) {
      return error;
    }
  }
);

export const createMessageThunk = createAsyncThunk(
  "message/create",
  async (body) => {
    try {
      const data = await axios.post(`${api_url}/messages/create`, body, {
        headers: {
          Authorization: body.token,
          "Content-Type": "multipart/form-data",
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);

export const getUserMessageThunk = createAsyncThunk(
  "message/get",
  async (body) => {
    try {
      const data = await axios.get(`${api_url}/messages`, {
        headers: {
          Authorization: body.token,
        },
        params: {
          userId: body.userId,
        },
      });

      return data;
    } catch (error) {
      return error;
    }
  }
);

export const deleteMessageThunk = createAsyncThunk(
  "message/remove",
  async (body) => {
    try {
      const data = await axios.delete(
        `${api_url}/messages/delete/${body.messageId}`,
        body,
        {
          headers: {
            Authorization: body.token,
          },
        }
      );

      return data;
    } catch (error) {
      return error;
    }
  }
);
