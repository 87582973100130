import React, { useEffect, useState, useRef } from "react";
import call from "../Components/Assets/images/call.svg";
import video from "../Components/Assets/images/video2.svg";
import thirdopt from "../Components/Assets/images/hz-third.svg";
import user1 from "../Components/Assets/images/user-2.png";
import active from "../Components/Assets/images/active.svg";
import check from "../Components/Assets/images/check.svg";
import thomas from "../Components/Assets/images/thomas.png";
import pin from "../Components/Assets/images/pin.svg";
import sharepictre from "../Components/Assets/images/share-images.svg";
import io from "socket.io-client";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { getUserThunk } from "../Features/user/userThunk";

import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../Features/auth/authSelector";
import axios from "axios";
import Connections from "./Connections";
import { profileSelector } from "../Features/profile/profileSelector";
import {
  createMessageThunk,
  getUserMessageThunk,
} from "../Features/message/messageThunk";
import { messageSelector } from "../Features/message/messageSelector";
import moment from "moment";
import { getMessageConnectionThunk } from "../Features/message/messageThunk";
import { userSelector } from "../Features/user/userSelector";
import { createNotificationThunk } from "../Features/notification/notificationThunk";

export default function SearchMessageUser(props) {
  const [socket, setSocket] = useState(null);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  //const [messages, setMessages] = useState([]);
  const { userData, token } = useSelector(authSelector);
  const [connectionUser, setConnectionUser] = useState("");
  const { followers } = useSelector(profileSelector);
  const { messages } = useSelector(messageSelector);
  const { users } = useSelector(userSelector);
  const [searchTerm, setSearchTerm] = useState(props.searchTerm);
  const [userSelected, setUserSelected] = useState(props.userSelected);
  const divRef = useRef();
  const [attachment, setAttachment] = useState("");
  const [image, setImage] = useState("");

  const validationSchema = Yup.object({
    content: Yup.string().required("This field is required"),
    //receiver_id: Yup.string().required("This field is required"),
  });

  useEffect(() => { }, []);

  const handleSearchOption = async (keyword) => {
    setSearchTerm(keyword);
    setUserSelected("");
    await dispatch(getUserThunk({ token: token, keyword: keyword }));
  };

  const formik = useFormik({
    initialValues: {
      content: "",
      receiver_id: userSelected,
      sender_id: userData?._id,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        let apiResponse = {};

        if (userSelected === "") {
          toast.error("Please select user to send message", {
            position: "top-right",


          });
        } else {
          apiResponse = await dispatch(
            createMessageThunk({
              ...values,
              token: token,
              image: image,
              //receiver_id: userSelected,
              attachment: attachment,
            })
          );
        }


        if (apiResponse?.payload?.status === 200) {
          // socket.emit("sendMessageToServer", {
          //   room: props.defaultRoom,
          //   message: apiResponse?.payload?.data?.body,
          // });

          if (userData?.message_notification === 'On') {
            const apiNotificationResponse = await dispatch(
              createNotificationThunk({
                userId: values.receiver_id,
                token: token,
                content:
                  userData?.firstName +
                  " " +
                  userData?.lastName +
                  " has messaged you",
                parent_id: apiResponse?.payload?.data?.body?._id,
                type: "message",
              })
            );
          }

          await dispatch(
            getMessageConnectionThunk({ token: token, keyword: "" })
          );

          props.handleUserSearch(false);

          props.handleUserMsg(
            props.defaultUser,
            apiResponse?.payload?.data?.body?._id
          );

          // await dispatch(
          //   getUserMessageThunk({
          //     token: token,
          //     userId: userSelected,
          //   })
          // );

          formik.setFieldValue("content", "");
          setAttachment("");
          setImage("");
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",


        });
      }
    },
  });

  const getMessages = async () => {
    try {
      await dispatch(
        getUserMessageThunk({ token: token, userId: props.defaultUser?._id })
      );
    } catch (error) {
      console.log({ error });
    }
  };

  const DateToTime = (date) => {
    return moment(date).format("HH:mm A");
  };

  const handleSelectedUser = (user) => {
    setUserSelected(user?._id);
    setSearchTerm(user?.firstName + " " + user?.lastName);
    formik.setFieldValue("receiver_id", user?._id);
  };

  return (
    <div>
      <div className="message-box position-relative">
        <div className="message-box-header d-flex align-items-center justify-content-between px-3 py-3 text-start">
          <h4 className="mb-0">New Messages</h4>
        </div>
        <div className="flex-box p-3" ref={divRef}>
          <div className="mb-2">
            <input
              type="search"
              value={searchTerm}
              className="form-control"
              placeholder="Search.."
              onChange={(e) => handleSearchOption(e.target.value)}
            />
          </div>

          {userSelected === "" && users.length > 0 && (
            <div className="searched-users p-3 border rounded">
              <ul className="list-unstyled mb-0">
                {users.map((item) => {
                  return (
                    <li key={item?._id}>
                      <div
                        onClick={() => handleSelectedUser(item)}
                        className="user-details d-flex align-items-center gap-3"
                      >
                        {item?.image ? (
                          <div>
                            <img
                              className="me-3"
                              src={`${process.env.REACT_APP_API_URL}/uploads/users/${item?.image}`}
                            />
                          </div>
                        ) : (
                          <div
                            className="avatar-text d-flex align-items-center justify-content-center"
                            style={{ background: "grey" }}
                          >
                            <span className="text-uppercase text-white">
                              {item?.firstName?.slice(0, 1)}{" "}
                            </span>
                          </div>
                        )}

                        <div>
                          <h4 className="mb-1 message">
                            {item?.firstName} {item?.lastName ?? ""}
                          </h4>
                          {/* <p className="mb-0 text-muted">Account Exicutive</p> */}
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        <div className="message-field py-3 px-3 mt-3">
          <form onSubmit={formik.handleSubmit}>
            <textarea
              name="content"
              onChange={formik.handleChange}
              value={formik.values.content}
              rows="4"
              cols="50"
              placeholder="Write your message"
              className="border-0 px-3 py-3"
            ></textarea>
            <div className="d-flex justify-content-between align-items-center mt-2">
              <div className="col-md-10 d-flex">
                <div className="image-input position-relative overflow-hidden">
                  <input
                    type="file"
                    id="imageInput"
                    className="d-none"
                    accept=".doc, .docx, application/pdf"
                    onChange={(e) => setAttachment(e.target.files[0])}
                    name="attachment"
                  />
                  <label htmlFor="imageInput" className="image-button">
                    <img src={pin} />
                  </label>
                </div>
                <p className="img_name mb-0 ms-2">{attachment?.name}</p>
                <div className="image-input position-relative overflow-hidden ms-2">
                  <input
                    type="file"
                    id="imageInput2"
                    className="d-none"
                    accept="image/*"
                    onChange={(e) => setImage(e.target.files[0])}
                    name="image"
                  />
                  <label htmlFor="imageInput2" className="image-button">
                    <img src={sharepictre} />
                  </label>
                </div>
                <p className="img_name mb-0 ms-2">{image?.name}</p>
              </div>
              <div className="col-md-2 text-end">
                <button type="submit">Send</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
