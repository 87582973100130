import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const api_url = process.env.REACT_APP_API_URL;

//login thunk
export const loginThunk = createAsyncThunk("auth/login", async (body) => {
  try {
    const data = await axios.post(`${api_url}/login`, body);

    // localStorage.setItem("tempToken", JSON.stringify(data.data.data.token));

    return data;
  } catch (error) {
    return error;
  }
});

//login thunk
export const socialLoginThunk = createAsyncThunk(
  "auth/socialLogin",
  async (body) => {
    try {
      const data = await axios.post(`${api_url}/socialLogin`, body);

      // localStorage.setItem("tempToken", JSON.stringify(data.data.data.token));

      return data;
    } catch (error) {
      return error;
    }
  }
);
// sign up thunk
export const registerThunk = createAsyncThunk("auth/register", async (body) => {
  try {
    const data = await axios.post(`${api_url}/signup`, body);
    return data;
  } catch (error) {
    return error;
  }
});
// forgot password
export const forgotPasswordThunk = createAsyncThunk(
  "auth/forgotPassword",
  async (body) => {
    try {
      const data = await axios.post(`${api_url}/forgotPassword`, body);
      return data;
    } catch (error) {
      return error;
    }
  }
);
// user profile
export const userProfileThunk = createAsyncThunk(
  "auth/userProfile",
  async (token) => {
    try {
      const data = await axios.get(`${api_url}/getProfile`, {
        headers: {
          Authorization: token,
        },
      });
      return data;
    } catch (error) {
      return error;
    }
  }
);

// get profile
export const getProfileThunk = createAsyncThunk(
  "auth/getProfile",
  async (token) => {
    try {
      const data = await axios.get(`${api_url}/getProfile`, {
        headers: {
          Authorization: token,
        },
      });
      return data;
    } catch (error) {
      return error;
    }
  }
);

// get profile
export const getFindColleaguesThunk = createAsyncThunk(
  "auth/findColleagues",
  async (token) => {
    try {
      const data = await axios.get(`${api_url}/findColleagues`, {
        headers: {
          Authorization: token,
        },
      });
      return data;
    } catch (error) {
      return error;
    }
  }
);

// update profile
export const updateProfileThunk = createAsyncThunk(
  "auth/updateProfile",
  async (body) => {
    const config = {
      headers: {
        Authorization: body.token,
      },
    };
    try {
      const data = await axios.put(`${api_url}/updateProfile`, body, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);

// pinned user
export const pinUserThunk = createAsyncThunk("auth/pinUser", async (body) => {
  const config = {
    headers: {
      Authorization: body.token,
    },
  };

  try {
    const data = await axios.put(`${api_url}/pinUser`, body, config);
    return data;
  } catch (error) {
    return error;
  }
});
// delete user chat
export const deleteUserChatThunk = createAsyncThunk(
  "auth/deleteUserChat",
  async (body) => {
    const config = {
      headers: {
        Authorization: body.token,
      },
    };

    try {
      const data = await axios.put(
        `${api_url}/messages/deleteChat`,
        body,
        config
      );
      return data;
    } catch (error) {
      return error;
    }
  }
);

// //change password
export const changePasswordThunk = createAsyncThunk(
  "auth/updatePass",
  async ({ body }) => {
    const config = {
      headers: {
        Authorization: body.token,
      },
    };
    try {
      const data = await axios.post(`${api_url}/changePassword`, body, config);
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const searchResultThunk = createAsyncThunk(
  "result/searchResult",
  async ({ value, token }) => {
    const config = {
      headers: {
        Authorization: token,
      },
    };
    try {
      const data = await axios.post(
        `${api_url}/searchPeople`,
        { searchQuery: value },
        config
      );
      return data;
    } catch (error) {
      return error;
    }
  }
);
