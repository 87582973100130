import React, { useEffect, useState } from "react";
import SettingTabs from "../../Components/Settingtabs";
import Settingscontent from "../../Components/Settingscontent";
import { useDispatch, useSelector } from "react-redux";
import { userProfileThunk } from "../../Features/auth/authThunk";
import { authSelector } from "../../Features/auth/authSelector";
import { useNavigate, useLocation } from "react-router-dom";

import Navbar from "../Header/Navbar";
export default function Settings() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = useSelector(authSelector);
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("token"));
  const [data, setData] = useState(user);

  const getProfile = async (token) => {
    try {
      const res = await dispatch(userProfileThunk(token));
      
      if (res.payload.status !== 200) {
        navigate("/Login");
      }
    } catch (error) {
      console.log({ error });
    }
  };
  useEffect(() => {
    getProfile(token);
    setData(user);

    if (location?.state?.tab) {
      document.getElementById(location?.state?.tab).click();
    }
  }, [user, location?.state?.tab]);

  return (
    <div>
      <Navbar />
      <div className="container main setting-tabs">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-12 left">
            <SettingTabs />
          </div>
          <div className="col-lg-9 col-md-8 col-sm-12 center">
            <Settingscontent user={data} />
          </div>
        </div>
      </div>
    </div>
  );
}
