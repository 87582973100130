import React, { useEffect, useState } from "react";
import opt from "../Components/Assets/images/hzopt-icon.svg";
import edit from "../Components/Assets/images/message-edit.svg";
import search from "../Components/Assets/images/search-icon.svg";
import filter from "../Components/Assets/images/filter-icon.svg";
import user1 from "../Components/Assets/images/user-2.png";
import vopt from "../Components/Assets/images/vertical-opt.svg";
import user2 from "../Components/Assets/images/user3.png";
import user3 from "../Components/Assets/images/user2.png";
import user4 from "../Components/Assets/images/user4.png";
import active from "../Components/Assets/images/active.svg";
import notactive from "../Components/Assets/images/not-active.svg";
import { getFollowersThunk } from "../Features/profile/profileThunk";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../Features/auth/authSelector";
import { profileSelector } from "../Features/profile/profileSelector";
import { messageSelector } from "../Features/message/messageSelector";
import { getMessageConnectionThunk } from "../Features/message/messageThunk";
import io from "socket.io-client";
import { Dropdown } from "react-bootstrap";
import { deleteUserChatThunk, pinUserThunk } from "../Features/auth/authThunk";

export default function UserList(props) {
  const dispatch = useDispatch();
  const api_url = process.env.REACT_APP_API_URL;

  const socket = io(process.env.REACT_APP_API_SERVER);
  const { userData, token } = useSelector(authSelector);
  const { connections } = useSelector(messageSelector);
  const [searchTerm, setSearchTerm] = useState("");
  const [pinnedUser, setPinnedUser] = useState([]);
  const [pinnedUserIds, setPinnedUserIds] = useState([]);
  const [users, setUsers] = useState(connections);

  const callApi = async () => {
    try {
      await dispatch(
        getMessageConnectionThunk({ token: token, keyword: searchTerm })
      );
    } catch (error) {
      console.log({ error });
    }
  };

  const handleSearchOption = async (keyword) => {
    setSearchTerm(keyword);
    await dispatch(
      getMessageConnectionThunk({ token: token, keyword: keyword })
    );
  };

  useEffect(() => {
    callApi();
  }, [props.defaultUser]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const filterUsers = userData?.pinned_by_users?.map((item) =>
          connections?.filter((ele) => item == ele?._id)
        );
        const flattenedUsers = filterUsers.flat();

        setPinnedUser([...new Set(flattenedUsers)]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userData?.pinned_by_users, connections, props.defaultUser]);

  const handlePinUser = async (item, status) => {
    try {
      const res = await dispatch(
        pinUserThunk({ user_id: item?._id, token, status })
      );
      callApi();
    } catch (error) {
      console.error(error);
    }
  };
  const handleDeleteChat = async (item, status) => {
    try {
      const res = await dispatch(
        deleteUserChatThunk({ user_id: item?._id, token })
      );

      callApi();
    } catch (error) {
      console.error(error);
    }
  };

  const filteredConnections = connections.filter(
    (connection) =>
      !userData?.pinned_by_users?.includes(connection?.otherUserDetail?._id)
  );

  return (
    <div>
      <div className="message-list mb-4 mb-md-0">
        <div className="message-header d-flex align-items-center justify-content-between px-3 py-2">
          <h4 className="mb-0">Messages</h4>
          <div className="edit-opt">
            <span onClick={() => props.handleUserSearch(true)}>
              <img src={edit} />
            </span>
          </div>
        </div>
        <div className="search mt-3 mb-0 pb-3 position-relative px-3">
          <div className="search-icon">
            <img src={search} />
          </div>
          <input
            type="search"
            placeholder="Find"
            className="py-2 px-4"
            onChange={(e) => handleSearchOption(e.target.value)}
          />
          <div className="search-filter">
            <img src={filter} />
          </div>
        </div>

        <p className="mx-2 mb-2 mt-2">
          <small>Pinned</small>
        </p>
        <div className="messages">
          <ul className="list-unstyled p-0">
            {pinnedUser?.map((item) => {
              return (
                item?.otherUserDetail !== null && (
                  <li key={item?.uniquekey}>
                    <a
                      key={item?.uniquekey}
                      onClick={() => {
                        props.handleUserMsg(
                          item?.otherUserDetail,
                          item?.uniquekey
                        );
                      }}
                      className={
                        "position-relative d-flex align-items-center justify-content-between px-3 py-2 " +
                        (props.defaultUser?._id === item?.other_user_id
                          ? "active"
                          : "")
                      }
                    >
                      <div className="user-stat position-relative">
                        {item?.otherUserDetail?.image ? (
                          <img
                            className="me-3"
                            src={`${process.env.REACT_APP_API_URL}/uploads/users/${item?.otherUserDetail?.image}`}
                            onError={(e) => {
                              e.target.src = ""; // Set an empty source to prevent infinite loop
                              e.target.onerror = null; // Remove the error event listener to prevent infinite loop
                              const parentElement = e.target.parentElement;
                              if (parentElement) {
                                parentElement.innerHTML = `<div
                                className="me-3 avatar-text d-flex align-items-center justify-content-center"
                                style="background: grey;"
                              ><span className="text-uppercase text-white">${item?.otherUserDetail?.firstName?.slice(
                                0,
                                1
                              )}</span> </div>`;
                              }
                            }}
                          />
                        ) : (
                          <div
                            className="me-3 avatar-text d-flex align-items-center justify-content-center"
                            style={{ background: "grey" }}
                          >
                            <span className="text-uppercase text-white">
                              {item?.otherUserDetail?.firstName?.slice(0, 1)}{" "}
                            </span>
                          </div>
                        )}
                        <span className="status">
                          <img src={notactive} />
                        </span>
                      </div>
                      <div className="name-message text-start">
                        <h4 className="mb-1 message">
                          {item?.otherUserDetail?.firstName}{" "}
                          {item?.otherUserDetail?.lastName}
                        </h4>
                        <p className="mb-0 message">
                          {item?.latestMessage?.content}
                        </p>
                      </div>
                      <div className="message-option">
                        <Dropdown>
                          <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <img src={vopt} />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => handlePinUser(item, 0)}
                            >
                              <i className="fa-solid fa-thumbtack me-2"></i>
                              Unpin
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleDeleteChat(item)}
                            >
                              <i className="fa-solid fa-trash-can me-2"></i>
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </a>
                  </li>
                )
              );
            })}
          </ul>
        </div>

        <p className="mx-2 mb-2">
          <small>Recent</small>
        </p>

        <div className="messages">
          <ul className="list-unstyled p-0">
            {filteredConnections?.map((item) => {
              return (
                item?.otherUserDetail !== null && (
                  <li key={item?.uniquekey}>
                    <a
                      key={item?.uniquekey}
                      onClick={() => {
                        props.handleUserMsg(
                          item?.otherUserDetail,
                          item?.uniquekey
                        );
                      }}
                      className={
                        "position-relative d-flex align-items-center justify-content-between px-3 py-2 " +
                        (props.defaultUser?._id === item?.other_user_id
                          ? "active"
                          : "")
                      }
                    >
                      <div className="user-stat position-relative">
                        {item?.otherUserDetail?.image ? (
                          <img
                            className="me-3"
                            src={`${process.env.REACT_APP_API_URL}/uploads/users/${item?.otherUserDetail?.image}`}
                            onError={(e) => {
                              e.target.src = ""; // Set an empty source to prevent infinite loop
                              e.target.onerror = null; // Remove the error event listener to prevent infinite loop
                              const parentElement = e.target.parentElement;
                              if (parentElement) {
                                parentElement.innerHTML = `<div
                                className="me-3 avatar-text d-flex align-items-center justify-content-center"
                                style="background: grey;"
                              ><span className="text-uppercase text-white">${item?.otherUserDetail?.firstName?.slice(
                                0,
                                1
                              )}</span> </div>`;
                              }
                            }}
                          />
                        ) : (
                          <div
                            className="me-3 avatar-text d-flex align-items-center justify-content-center"
                            style={{ background: "grey" }}
                          >
                            <span className="text-uppercase text-white">
                              {item?.otherUserDetail?.firstName?.slice(0, 1)}{" "}
                            </span>
                          </div>
                        )}
                        <span className="status">
                          <img src={notactive} />
                        </span>
                      </div>
                      <div className="name-message text-start">
                        <h4 className="mb-1 message">
                          {item?.otherUserDetail?.firstName}{" "}
                          {item?.otherUserDetail?.lastName}
                        </h4>
                        <p className="mb-0 message">
                          {item?.latestMessage?.content}
                        </p>
                      </div>
                      <div className="message-option">
                        <Dropdown>
                          <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <img src={vopt} />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => handlePinUser(item, 1)}
                            >
                              <i className="fa-solid fa-thumbtack me-2"></i>
                              Pin
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleDeleteChat(item)}
                            >
                              <i className="fa-solid fa-trash-can me-2"></i>
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </a>
                  </li>
                )
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
