import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import bgload from "../Components/Assets/images/upload-graphic.svg";
import bgupload from "../Components/Assets/images/upload-bg.svg";
import empty from "../Components/Assets/images/empty-image.svg";
import editpf from "../Components/Assets/images/edit-profile.svg";
import profiles from "../Components/Assets/images/profiles.png";
import vopt from "../Components/Assets/images/profile-vopt.svg";
import thomas from "../Components/Assets/images/thomas.png";
import location from "../Components/Assets/images/location-arrow.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import bioedit from "../Components/Assets/images/bio-edit.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfileThunk,
  updateProfileThunk,
} from "../Features/auth/authThunk";
import { Button, Modal } from "react-bootstrap";
import { authSelector } from "../Features/auth/authSelector";
import toast from "react-hot-toast";

import EditProfileModal from "./EditProfileModal";

import { getConnectionThunk } from "../Features/profile/profileThunk";
import { profileSelector } from "../Features/profile/profileSelector";

export default function Myprofile() {
  const { userData, token } = useSelector(authSelector);
  const [showModal, setShowModal] = useState(false);

  const [editval, setEditVal] = useState();
  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedCoveImage, setSelectedCoverImage] = useState(null);

  const [showUploadButton, setShowUploadButton] = useState(false);
  const [file, setFile] = useState();
  const [fileDataURL, setFileDataURL] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showCoverModal, setShowCoverModal] = useState(false);
  const { connections } = useSelector(profileSelector);

  const navigate = useNavigate();
  const handleClose = () => {
    setShowModal(false);
  };

  const handleShow = () => {
    setShowModal(true);
  };
  function logoutUser() {
    // dispatch(setIsLoggedIn());
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/");
  }

  function checkLoginToken() {
    let token = localStorage.getItem("token");
    if (!token) {
      logoutUser();
    } else {
      return token;
    }
  }
  const dispatch = useDispatch();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object({
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Invalid Phone Number.")
      .required("Invalid Phone Number."),

    firstName: Yup.string().required("Fullname is required."),
    dob: Yup.string().required("DOB is required."),

    gender: Yup.string().required("Gender is required."),
  });
  const formik = useFormik({
    initialValues: {
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      gender: userData?.gender,
      dob: userData?.dob,
      phone: userData?.phone,
      token: JSON.parse(checkLoginToken()),
    },
    validationSchema: validationSchema,
    onSubmit: async (body) => {
      try {
        let token = JSON.parse(checkLoginToken());

        const res = await dispatch(updateProfileThunk(body));

        if (res?.payload?.status === 200) {
          toast.success(res?.payload?.data?.message);
          await dispatch(getProfileThunk(token));

          setShow(false);
        } else {
          toast.error(res?.payload?.data?.message);
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          navigate("/Login");
          localStorage.removeItem("token");
        }
        console.log(error);
        toast.error(error?.payload?.response?.data?.message);
      }
    },
  });
  const handleChangeCoverImage = async (e) => {
    let token = JSON.parse(localStorage.getItem("token"));

    const file = e.target.files[0];

    let formData = new FormData();

    formData.append("folder", "users");

    formData.append("image", file);
    try {
      let resp = await axios.post(
        `${process.env.REACT_APP_API_URL}/uploadFile`,
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (resp?.data?.success) {
        setSelectedCoverImage(resp?.data?.body?.filename);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/Login");
        localStorage.removeItem("token");
      }
      console.log(error);
    }
  };
  const coverImageFormik = useFormik({
    initialValues: {
      cover_image: "",
      token: JSON.parse(localStorage.getItem("token")),
    },
    validationSchema: Yup.object({
      cover_image: Yup.string().required(),
    }),
    onSubmit: async (body, { resetForm }) => {
      try {
        let token = JSON.parse(localStorage.getItem("token"));

        const res = await dispatch(
          updateProfileThunk({ ...body, cover_image: selectedCoveImage })
        );

        if (res?.payload?.status === 200) {
          toast.success("Cover image updated");
          await dispatch(getProfileThunk(token));

          setShowCoverModal(false);
          resetForm();
        } else {
          toast.error(res?.payload?.data?.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleChange = async (e) => {
    let token = JSON.parse(localStorage.getItem("token"));

    const file = e.target.files[0];

    let formData = new FormData();

    formData.append("folder", "users");

    formData.append("image", file);
    try {
      let resp = await axios.post(
        `${process.env.REACT_APP_API_URL}/uploadFile`,
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (resp?.data?.success) {
        setSelectedImage(resp?.data?.body?.filename);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/Login");
        localStorage.removeItem("token");
      }
      console.log(error);
    }
  };
  const profileFormik = useFormik({
    initialValues: {
      image: "",
      token: JSON.parse(localStorage.getItem("token")),
    },
    onSubmit: async (body, { resetForm }) => {
      try {
        const res = await dispatch(
          updateProfileThunk({ ...body, image: selectedImage })
        );

        if (res?.payload?.status === 200) {
          toast.success("Profile image updated");
          await dispatch(getProfileThunk(token));

          // setShow(false);
          setShowProfileModal(false);
          resetForm();
        } else {
          toast.error(res?.payload?.data?.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
  });
  var currentDate = new Date();
  var max13YearsAgo = new Date(
    currentDate.getFullYear() - 13,
    currentDate.getMonth(),
    currentDate.getDate()
  )
    .toISOString()
    .split("T")[0];

  const formikIntro = useFormik({
    initialValues: {
      intro_details: "",
      // token: localStorage.getItem("token"),
    },
    onSubmit: async (body, { resetForm }) => {
      try {
        let token = JSON.parse(localStorage.getItem("token"));

        const res = await dispatch(
          updateProfileThunk({ ...body, token: token })
        );

        if (res?.payload?.status === 200) {
          toast.success(res?.payload?.data?.message);
          await dispatch(getProfileThunk(token));

          setShowModal(false);
          resetForm();
        } else {
          toast.error(res?.payload?.data?.message);
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          navigate("/Login");
          localStorage.removeItem("token");
        }
        console.log(error);
      }
    },
  });

  const getConnections = async () => {
    try {
      await dispatch(getConnectionThunk(token));
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getConnections();
  }, []);

  return (
    <div>
      <div className="edit-profile">
        <div
          className="bg-buttons position-relative"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_API_URL}/uploads/users/${userData?.cover_image})`,
          }}
        >
          {/* <Link
            className="upload-bg d-flex align-items-center justify-content-center"
            type="button"
            onClick={() => setShowCoverModal(true)}
          >
            <img src={bgupload} />
          </Link> */}
          <Modal show={showCoverModal} onHide={() => setShowCoverModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Cover Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedCoveImage ? (
                <img
                  src={`${process.env.REACT_APP_API_URL}/uploads/users/${selectedCoveImage}`}
                  height="290px"
                  className="mb-3 img-fluid"
                />
              ) : (
                <img
                  src={`${process.env.REACT_APP_API_URL}/uploads/users/${userData?.cover_image}`}
                  height="290px"
                  className="mb-3 img-fluid"
                />
              )}
            </Modal.Body>
            <Modal.Footer>
              <form onSubmit={coverImageFormik.handleSubmit} className="w-100">
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div className="file-input">
                    <input
                      type="file"
                      name="cover_image"
                      id="file-input"
                      className="file-input__input"
                      onChange={(e) => {
                        coverImageFormik.handleChange(e);
                        handleChangeCoverImage(e);
                      }}
                    />
                    <label className="file-input__label" htmlFor="file-input">
                      <span>Edit profile background</span>
                    </label>
                  </div>
                  <div>
                    <button
                      className="btn btn-outline-submit"
                      type="submit"
                      style={{
                        borderRadius: "6px",
                        border: "1px solid #1B5052",
                        textDecoration: "none",
                        color: "#000",
                        textAlign: "center",
                        fontSize: "13px",
                        fontWeight: "400",
                        padding: "12px 16px",
                        transition: ".3s all ease",
                        lineHeight: "normal",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </Modal.Footer>
          </Modal>

          {/* <Link
            className="ed-profile d-flex align-items-center"
            onClick={() => {
              setEditVal({});
              setShow(true);
            }}
          >
            <img className="me-2" src={editpf} /> Edit Profile
          </Link> */}
          <div>
            <EditProfileModal show={show} onClose={() => setShow(false)} />
          </div>

          <div className="nav-item dropdown options">
            <a
              className="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              href="#"
              role="button"
              aria-expanded="false"
            >
              <img src={vopt} />
            </a>

            <ul className="dropdown-menu p-0">
              <li>
                <button
                  className="dropdown-item px-3 py-2"
                  type="button"
                  onClick={() => setShowCoverModal(true)}
                // data-bs-toggle="modal"
                // data-bs-target="#exampleModal"
                >
                  Change Image
                </button>
              </li>
              <li>
                <a className="dropdown-item px-3 py-2" href="#">
                  Delete image
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-12">
          {/* Profile image */}

          <div className="profile-data d-flex align-items-center px-3 py-3 position-relative">
            <div className="profile-image">
              <div
                className="upload-bg d-flex align-items-center justify-content-center"
                type="button"
                onClick={() => setShowProfileModal(true)}
              >
                {userData?.image ? (
                  <img
                    className="border-thumb"
                    src={`${process.env.REACT_APP_API_URL}/uploads/users/${userData?.image}`}
                  />
                ) : (
                  <div
                    className="me-3 avatar-profile-image d-flex align-items-center justify-content-center"
                    style={{ background: "grey" }}
                  >
                    <span className="text-uppercase text-white">
                      {userData?.firstName?.slice(0, 1)}{" "}
                    </span>
                  </div>
                )}
              </div>

              <Modal
                show={showProfileModal}
                onHide={() => setShowProfileModal(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Edit Profile Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {!selectedImage ? (
                    <img
                      src={`${process.env.REACT_APP_API_URL}/uploads/users/${userData?.image}`}
                      height="290px"
                      className="mb-3 img-fluid"
                    />
                  ) : (
                    <img
                      src={`${process.env.REACT_APP_API_URL}/uploads/users/${selectedImage}`}
                      height="290px"
                      className="mb-3 img-fluid"
                    />
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <form onSubmit={profileFormik.handleSubmit} className="w-100">
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div className="file-input">
                        <input
                          type="file"
                          name="image"
                          id="file-input"
                          className="file-input__input"
                          onChange={(e) => {
                            profileFormik.handleChange(e);
                            handleChange(e);
                          }}
                        />
                        <label
                          className="file-input__label"
                          htmlFor="file-input"
                        >
                          <span>Edit profile Image</span>
                        </label>
                      </div>
                      <div>
                        <button
                          className="btn btn-outline-submit"
                          type="submit"
                          style={{
                            borderRadius: "6px",
                            border: "1px solid #1B5052",
                            textDecoration: "none",
                            color: "#000",
                            textAlign: "center",
                            fontSize: "13px",
                            fontWeight: "400",
                            padding: "12px 16px",
                            transition: ".3s all ease",
                            lineHeight: "normal",
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </Modal.Footer>
              </Modal>
            </div>
            <div className="name-location text-start ps-4 flex-grow-1">
              <div className="name-rating d-flex align-items-center justify-content-between">
                <h4 className="m-0 d-flex align-items-center justify-content-between">
                  {userData?.firstName + " " + userData?.lastName}
                  <div className="total-rating ms-3">
                    <div className="stars">
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      <i className="fa-solid fa-star"></i>
                      {/* <span className="ms-1">(24)</span> */}
                    </div>
                  </div>
                </h4>

                <div className="location-icon d-flex align-items-center justify-content-center">
                  <img className="location" src={location} />
                  {userData?.country}
                </div>
              </div>

              {/* Intro details */}

              <div className="p-description">
                <div className="d-flex justify-content-between">
                  <p className="py-2 mb-0 me-3">{userData?.intro_details}</p>
                  <div className="icon">
                    <button
                      onClick={handleShow}
                      type="button"
                      className="border-0 bg-transparent"
                    >
                      <img src={bioedit} alt="Edit intro_details" />
                    </button>
                  </div>
                </div>

                <Modal show={showModal} onHide={handleClose} centered>
                  <Modal.Header closeButton>
                    <Modal.Title>Update Intro</Modal.Title>
                  </Modal.Header>
                  <form onSubmit={formikIntro.handleSubmit}>
                    <Modal.Body>
                      <textarea
                        value={formikIntro.values.intro_details}
                        onChange={formikIntro.handleChange}
                        name="intro_details"
                        className="w-100 px-2 py-2"
                        rows="4"
                        cols="50"
                        placeholder="Write here."
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                      <Button type="submit" className="save-changes">
                        Save Changes
                      </Button>
                    </Modal.Footer>
                  </form>
                </Modal>

                <div className="contact-connections mt-2">
                  <Link className="contact-info me-2">Contact info</Link>
                  <Link className="connections" to={"/connections"}>
                    {connections?.length} connections
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
