import React from 'react'
 
 
import noresult from "../Components/Assets/images/nodata-found.jpg";
import Navbar from './Header/Navbar';
import Footer from './Footer';
 
export default function Errorpage() {
  return (
    <div>
          <div>
                <Navbar />

                <div className='container main'>
                    <div className='row'>
                        <div className="col-lg-12 col-md-9 col-sm-12 right">
                            <div className="result-not-found-wrapper card p-3 text-center rounded-4">
                                <img
                                    src={noresult}
                                    width="300px" className="m-auto" />


                                <h4 className="fw-normal">
                                    No result found
                                </h4>
                                <p className="fs-6">
                                    Try shortening or rephrasing your search.
                                </p>
                            </div>
                        </div>

                    </div>

                    <Footer />
                </div>
            </div>
    </div>
  )
}
