import { createSlice } from "@reduxjs/toolkit";
 
import { createHashtagThunk, getAllHashtagsThunk } from "./hashTagThunk";

const initialState = {
  hashTags: [],
};

const hashTagSlice = createSlice({
  name: "hashTag",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // create
      .addCase(getAllHashtagsThunk.fulfilled, (state, action) => {
        state.hashTags = action?.payload?.data?.body;
      })
      .addCase(createHashtagThunk.fulfilled, (state, action) => {
        state.hashTags = [...action?.payload?.data?.body];
      });
  },
});

export default hashTagSlice.reducer;
