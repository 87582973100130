import React, { useEffect, useState } from "react";
import Bio from "../Profilebio";
import Skills from "../Skills";
import Filter from "../Filters";
import Posttabs from "../Posttabs";
import Popularpost from "../Popularposts";
import Post from "../Posts";
import Study from "../Education";
import Videoconf from "../Videoconference";
import Experience from "../Experience";
import UserList from "../UserList";
import PostsByAuthUser from "./PostsByAuthUser";
import Goal from "./Goal";

export default function Profilepagetabs() {
  const [hashtags, setHashtags] = useState([]);
  const [defaultUser, setDefaultUser] = useState("");
  const [defaultRoom, setDefaultRoom] = useState("");

  const handleHashtag = (value) => {
    if (hashtags.includes(value)) {
      setHashtags(hashtags.filter((item) => item !== value));
    } else {
      setHashtags([...hashtags, value]);
    }
  };

  const handleUserMsg = (user, connectionId) => {
    setDefaultUser(user);
    setDefaultRoom(connectionId);
  };

  return (
    <div className="my-4">
      <nav>
        <ul
          className="nav nav-tabs profile-tabs border-0 align-items-center justify-content-between"
          id="myTab"
          role="tablist"
        >
          <li className="nav-item col-md-6" role="presentation">
            <button
              className="nav-link active"
              id="one-tab"
              data-bs-toggle="tab"
              data-bs-target="#one"
              type="button"
              role="tab"
              aria-controls="one"
              aria-selected="true"
            >
              Profile
            </button>
          </li>
          <li className="nav-item col-md-6" role="presentation">
            <button
              className="nav-link"
              id="two-tab"
              data-bs-toggle="tab"
              data-bs-target="#two"
              type="button"
              role="tab"
              aria-controls="two"
              aria-selected="false"
            >
              Recent posts
            </button>
          </li>
          {/* <li className="nav-item col-md-4" role="presentation">
            <button
              className="nav-link "
              id="three-tab"
              data-bs-toggle="tab"
              data-bs-target="#three"
              type="button"
              role="tab"
              aria-controls="three"
              aria-selected="false"
            >
              Conference rooms
            </button>
          </li> */}
        </ul>
      </nav>
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="one"
          role="tabpanel"
          aria-labelledby="one-tab"
        >
          <div className="bio">
            <Bio />
            <Goal />
            <Post />
            <Skills />
            <Experience />
            <Study />
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="two"
          role="tabpanel"
          aria-labelledby="two-tab"
        >
          <div className="row">
            <div className="col-md-4">
              <Popularpost />
              <Filter handleHashtag={handleHashtag} />
            </div>
            <div className="col-md-8">
              {/* <Posttabs  /> */}
              <PostsByAuthUser hashtags={hashtags} />
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="three"
          role="tabpanel"
          aria-labelledby="three-tab"
        >
          <div className="row">
            <div className="col-md-4">
              <div className="messages mt-4">
                <UserList
                  defaultUser={defaultUser}
                  handleUserMsg={handleUserMsg}
                  defaultRoom={defaultRoom}
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="messages mt-4">
                <Videoconf />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
