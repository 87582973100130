import React, { useEffect, useState } from "react";
import steve from "../Components/Assets/images/steve-jobs.png";
import axios from "axios";
import { useSelector } from "react-redux";
import { authSelector } from "../Features/auth/authSelector";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { getFindColleaguesThunk } from "../Features/auth/authThunk";

export default function Connections() {
  const dispatch = useDispatch();
  const { token, userData, findColleagues } = useSelector(authSelector);
  const [people, setPeople] = useState([]);
  const navigate = useNavigate();

  const callApi = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/recommendedToProfessionPeople`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setPeople(res?.data?.body || []);
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/Login");
        localStorage.removeItem("token");
      }
      console.log(error);
    }

    await dispatch(getFindColleaguesThunk(token));
  };

  useEffect(() => {
    callApi();
  }, [token]); // Call the API when the token changes

  const handleFollowAndUnfollow = async (item) => {
    try {
      const action = "follow";

      const followUserResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/follow/followUnfollowUser`,
        {
          action,
          follow_user_id: item._id,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      // Update the follow status in the local state
      const updatedPeople = people.map((p) =>
        p._id === item._id
          ? {
            ...p,
            follower: action === "follow" ? [{}] : [], // Assuming the follower array structure
          }
          : p
      );

      setPeople(updatedPeople);
      if (followUserResponse?.status === 200) {
        toast.success(
          `Now ${action}ing ${item?.firstName} ${item?.lastName}.`
        );
        callApi();
      } else {
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/Login");
        localStorage.removeItem("token");
      }
      console.log({ error });
      toast.error(error?.response?.data?.message, {
        position: "bottom-left",
      });
    }
  };

  const handleViewPeople = (_id) => {
    navigate(`/results/view-profile/${_id}`);
  };

  return (
    <div className="side-tab people right-pannel mt-0">
      {/* ... Your JSX code ... */}
      <div className="title-with-icon d-flex justify-content-between position-relative">
        <div className="titel text-start">
          <h3 className="mb-1">People you may know:</h3>
          <p className="opacity-0 mb-0">Hidden-text</p>
        </div>
      </div>
      <ul className="connection-list list-unstyled p-0">
        {people?.length > 0 ? (
          people?.map?.((item) => {
            return (
              <li className="position-relative pe-2" key={item?._id}>
                <a className="d-flex align-items-center justify-content-between">
                  {/* ... Your JSX code ... */}
                  <div className="detials d-flex align-items-center justify-content-between">
                    {item?.image ? (
                      <img
                        className="border-thumb"
                        src={`${process.env.REACT_APP_API_URL}/uploads/users/${item?.image}`}
                      />
                    ) : (
                      <div
                        className="avatar-text d-flex align-items-center justify-content-center"
                        style={{ background: "grey" }}
                      >
                        <span className="text-uppercase text-white">
                          {item?.firstName?.slice(0, 1)}{" "}
                        </span>
                      </div>
                    )}
                    {/* <img className="me-3" src={`${process.env.REACT_APP_API_URL}/uploads/users/${item?.image}`} /> */}
                    <div className="connection-detail text-start ms-2">
                      <h3 className="mb-1">
                        {item?.firstName ?? item?.username}
                      </h3>
                      <p className="mb-0">{item?.profession}</p>
                    </div>
                  </div>
                  <button
                    className="connect"
                    onClick={() => handleFollowAndUnfollow(item)}
                  >
                    Follow
                  </button>
                </a>
              </li>
            );
          })
        ) : (
          <>
            {findColleagues.slice(0, 3).map((item) => {
              return (
                <li className="position-relative pe-2" key={item?._id}>
                  <a className="d-flex align-items-center justify-content-between">
                    {/* ... Your JSX code ... */}
                    <div className="detials d-flex align-items-center justify-content-between">
                      {item?.image ? (
                        <img
                          className="border-thumb"
                          src={`${process.env.REACT_APP_API_URL}/uploads/users/${item?.image}`}
                        />
                      ) : (
                        <div
                          className="avatar-text d-flex align-items-center justify-content-center"
                          style={{ background: "grey" }}
                        >
                          <span className="text-uppercase text-white">
                            {item?.firstName?.slice(0, 1)}{" "}
                          </span>
                        </div>
                      )}
                      {/* <img className="me-3" src={`${process.env.REACT_APP_API_URL}/uploads/users/${item?.image}`} /> */}
                      <div className="connection-detail cursor-pointer text-start ms-2" onClick={() => handleViewPeople(item?._id)}>
                        <h3 className="mb-1">
                          {item?.firstName ? item?.firstName?.slice(0, 15) : item?.username}
                        </h3>
                        <p className="mb-0">{item?.profession}</p>
                      </div>
                    </div>
                    <button
                      className="connect"
                      onClick={() => handleFollowAndUnfollow(item)}
                    >
                      Follow
                    </button>
                  </a>
                </li>
              );
            })}
            <button className="connect" onClick={() => navigate("/find")}>
              Find Colleagues
            </button>
          </>
        )}
      </ul>
    </div>
  );
}
