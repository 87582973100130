import { createSlice } from "@reduxjs/toolkit";
import { getNotificationThunk } from "./notificationThunk";

const initialState = {
  notifications: [],
  latestNotifications: [],
  total: 0,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNotificationThunk.fulfilled, (state, action) => {
      state.notifications = action?.payload?.data?.body?.notifications;
      state.latestNotifications =
        action?.payload?.data?.body?.latestNotifications;
      state.total = action?.payload?.data?.total;
    });
  },
});

export default notificationSlice.reducer;
