import { configureStore } from "@reduxjs/toolkit";

import authSlice from "./auth/authSlice";
import postsSlice from "./posts/postsSlice";
import profileSlice from "./profile/profileSlice";
import messageSlice from "./message/messageSlice";
import userSlice from "./user/userSlice";
import notificationSlice from "./notification/notificationSlice";
import goalSlice from "./goal/goalSlice";
import hashTagsSlice from "./hashtags/hashTagSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    posts: postsSlice,
    profile: profileSlice,
    message: messageSlice,
    user: userSlice,
    notification: notificationSlice,
    goal: goalSlice,
    hashTag: hashTagsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
