import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import plus from "../Components/Assets/images/plus.svg";
import { profileSelector } from "../Features/profile/profileSelector";
import { useDispatch, useSelector } from "react-redux";
import { getUserSkillsThunk } from "../Features/profile/profileThunk";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { authSelector } from "../Features/auth/authSelector";
import axios from "axios";
import toast from "react-hot-toast";
import * as Yup from "yup";

export default function Skills() {
  const { skills } = useSelector(profileSelector);
  const { token } = useSelector(authSelector);

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [seeMore, setSeeMore] = useState(3);

  const callApi = async (token) => {
    await dispatch(getUserSkillsThunk(token));
  };

  useEffect(() => {
    // const token = JSON.parse(localStorage.getItem("token"));
    callApi(token);
  }, []);

  const formik = useFormik({
    initialValues: {
      title: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("This field will be required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/skill/create`,
          values,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        if (res.status === 200) {
          toast.success(res?.data?.message);
          callApi(token);
          setShow(false);
          resetForm();
        }
 
      } catch (error) {
        console.log(error?.response?.data?.message);
        setErrorMessage(error?.response?.data?.message);
        // toast.error(error?.response?.data?.message);
      }
    },
  });
 
  return (
    <>
      <div>
        <div className="Skills  my-4 px-4 py-4 text-start ">
          <div className="title-with-icon d-flex justify-content-between">
            <div className="titel">
              <h3 className="mb-1">Skills and Hobbies</h3>
            </div>
            <div className="icon">
              <button
                className="border-0 bg-transparent"
                onClick={() => setShow(true)}
              >
                <img src={plus} />
              </button>
            </div>
          </div>
          <div className="row my-3">
            {skills &&
              skills?.slice(0, seeMore)?.map((item) => {
                return (
                  <div className="col-md-4" key={item?._id}>
                    <p className="m-0">{item?.title}</p>
                  </div>
                );
              })}
          </div>
          {seeMore === skills?.length ? (
            <Link
              className="common-btn"
              style={{ display: skills?.length > 3 ? "" : "none" }}
              onClick={() => setSeeMore(3)}
            >
              Less more
            </Link>
          ) : (
            <Link
              className="common-btn"
              style={{ display: skills?.length <= 2 ? "none" : "" }}
              onClick={() => setSeeMore(skills?.length)}
            >
              See more <span>({skills?.length - 3})</span>
            </Link>
          )}
        </div>
      </div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>Add new skill </Modal.Header>
        <Modal.Body>
          <div className="modal-body px-3">
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3 text-start">
                <label className="form-label m-0">Title *</label>
                <input
                  type="text"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  name="title"
                  className="form-control"
                  id="validationCustom03"
                  aria-describedby="firstname"
                />
                <span style={{ color: "red" }}>
                  {" "}
                  {formik.touched.title && formik.errors.title ? (
                    <div>{formik.errors.title} </div>
                  ) : null}
                </span>
                <span style={{ color: "red" }}> {errorMessage}</span>
                {/* <div className="valid-feedback">{errorMessage}</div> */}
              </div>
              <div className="file-input">
                <button type="submit" className="save-changes">
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
