import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authSelector } from "../../Features/auth/authSelector";
import { useSelector } from "react-redux";

export default function ProtectedRoutes({ Component }) {
  const navigate = useNavigate();
  const { token } = useSelector(authSelector);

  useEffect(() => {
    if (!token || token === "undefined" || token == null) {
      navigate("/Login");
    }

  }, [token, navigate]);
  
  return <>{token && <Component />}</>;
}
