import React, { useEffect, useState } from "react";
import Navbar from "../Header/Navbar";
import Advertisement from "../Advertisement";
import Footer from "../Footer";
import { useSelector } from "react-redux";
import { authSelector } from "../../Features/auth/authSelector";
import { useDispatch } from "react-redux";
import { getFindColleaguesThunk } from "../../Features/auth/authThunk";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";

export default function FindColleagues() {
  const dispatch = useDispatch();
  const { findColleagues } = useSelector(authSelector);
  const { token } = useSelector(authSelector);
  const [people, setPeople] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isData, setIsData] = useState(false);

  const navigate = useNavigate();
  const getData = async () => {
    try {
      setIsLoading(true);
      const res = await dispatch(getFindColleaguesThunk(token));
      if (res?.payload?.data?.body?.length > 0) {
        setPeople(res?.payload?.data?.body);
        setIsData(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  const handleFollowAndUnfollow = async (item) => {
    try {
      const action = "follow";

      const followUserResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/follow/followUnfollowUser`,
        {
          action,
          follow_user_id: item._id,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (followUserResponse?.status === 200) {
        getData();
        toast.success(
          item?.username
            ? `Now ${action}ing   ${item?.username}.`
            : `Now ${action}ing   ${item?.firstName} ${item?.lastName}.`,
          {
            position: "bottom-left",
          }
        );
      }

      // No need to call getData again here, as it's already called in useEffect
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/Login");
        localStorage.removeItem("token");
      }
      console.log({ error });
      toast.error(error?.response?.data?.message, {
        position: "bottom-left",
      });
    }
  };

  const handleViewPeople = (_id) => {
    navigate(`/results/view-profile/${_id}`);
  };
  return (
    <>
      <div>
        <Navbar />
        <div className="container main">
          <div className="row">
            {isLoading && !isData ? (
              <>
                <div className="vh-100 d-flex align-items-center justify-content-center">
                  <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="96"
                    visible={true}
                  />
                </div>
              </>
            ) : (
              <div className="col-lg-9 col-md-8 col-sm-12 center">
                {findColleagues?.length > 0 &&
                  findColleagues?.map((item) => {
                    return (
                      <>
                        <div className="search-profile-wraper p-3 mb-3">
                          <div className="user-field d-flex justify-content-between">
                            <div className="d-flex">
                              {item?.image ? (
                                <img
                                  className="border-thumb user-img me-2"
                                  src={`${process.env.REACT_APP_API_URL}/uploads/users/${item?.image}`}
                                />
                              ) : (
                                <div
                                  className="me-3 avatar-text d-flex align-items-center justify-content-center"
                                  style={{ background: "grey" }}
                                >
                                  <span className="text-uppercase text-white">
                                    {item?.firstName && item?.username
                                      ? item?.firstName?.slice(0, 1)
                                        ? item?.username?.slice(0, 1)
                                        : "G"
                                      : "N/A"}
                                  </span>
                                </div>
                              )}
                              <span className="text-uppercase text-white font-lg">
                                {""}
                              </span>

                              <div className="company-detail ms-4">
                                <h4 className="company-title">
                                  {item?.firstName
                                    ? `${item?.firstName}  `
                                    : `${item?.username}`}
                                </h4>
                                <p className="subheading">
                                  {/* {item?.intro_details?.slice(0, 50)}... */}
                                </p>
                                <p className="bottom_heading mb-0">
                                  {/* {item.location}{" "} */}
                                </p>
                                <div className="d-flex follower-area">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 16 16"
                                    data-supported-dps="16x16"
                                    fill="currentColor"
                                    className="mercado-match"
                                    width="16"
                                    height="16"
                                    focusable="false"
                                  >
                                    <path d="M14 11.75V15H9v-3.25A1.75 1.75 0 0110.75 10h1.5A1.75 1.75 0 0114 11.75zM11.5 9A2.5 2.5 0 109 6.5 2.5 2.5 0 0011.5 9zM5 1a3 3 0 103 3 3 3 0 00-3-3zm.75 7h-1.5A2.25 2.25 0 002 10.25V15h6v-4.75A2.25 2.25 0 005.75 8z"></path>
                                  </svg>
                                  <p className="mb-0">
                                    <strong>{item?.username}</strong>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="mt-3">
                              <button
                                className="connect following-btn me-2"
                                onClick={() => handleFollowAndUnfollow(item)}
                              >
                                {/* {btnText ?? "not working"} */}

                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 16 16"
                                  data-supported-dps="16x16"
                                  fill="currentColor"
                                  className="mercado-match"
                                  width="16"
                                  height="16"
                                  focusable="false"
                                >
                                  <path d="M12.57 2H15L6 15l-5-5 1.41-1.41 3.31 3.3z"></path>
                                </svg>

                                <span className="ms-1">Follow</span>
                              </button>
                              <button
                                className="connect following-btn me-2"
                                onClick={() => handleViewPeople(item?._id)}
                              >
                                <span className="ms-1">Visit page</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            )}

            <div className="col-lg-3 col-md-6 col-sm-12 right">
              <div className="sticky-side">
                {/* <Advertisement /> */}
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
