import React from 'react'
import { Link } from 'react-router-dom'

export default function PageNotFound() {
  return (
    <div>
       
        <div className='container main login errorpage-section'>
            <h2 className='mt-4 mb-2'>404</h2>
            <h4>Page Not Found</h4>
            <h2 className='abslt-txt'>404</h2>
            <p className='my-2'>The page you're looking for isn't available. Try another page or use the home button below.</p>
            <Link to="/home" className='mt-4'>Back To Home</Link>
      
    </div>
    </div>
  )
}
