import React, { useEffect, useState } from "react";
import strokefire from "../../Components/Assets/images/stroke-fire.svg";
import popularshow from "../../Components/Assets/images/popularshow.svg";
import singlestar from "../../Components/Assets/images/white-star.svg";
import toprated from "../../Components/Assets/images/top-rated.svg";

import thomas from "../../Components/Assets/images/thomas.png";
import options from "../../Components/Assets/images/options.svg";
import click from "../../Components/Assets/images/clicked.svg";
import comment from "../../Components/Assets/images/chat.svg";
import send from "../../Components/Assets/images/share.svg";
import bookmark from "../../Components/Assets/images/bookm.svg";

import like from "../../Components/Assets/images/like.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

import { useDispatch, useSelector } from "react-redux";
import {
  postGetsByUserThunk,
  getTopRatedPostsThunk,
} from "../../Features/posts/postThunk";

import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";

import {
  Button,
  Card,
  Dropdown,
  Form,
  InputGroup,
  Modal,
  Placeholder,
} from "react-bootstrap";
import toast from "react-hot-toast";
import mic from "../../Components/Assets/images/mic.svg";
import gallery from "../../Components/Assets/images/gallery.svg";
import sendbtn from "../../Components/Assets/images/send-btn.svg";

import { postSelector } from "../../Features/posts/postsSelector";
import { authSelector } from "../../Features/auth/authSelector";
import Rating from "../Posts/Rating";
import moment from "moment";
// import {  } from "../../Features/posts/postThunk";

export default function PostsByAuthUser(props) {
  const { posts, topRatedPosts } = useSelector(postSelector);
  const { userData, token } = useSelector(authSelector);
  const [seeMore, setSeeMore] = useState(3);
  const [showCommentbox, setShowCommentbox] = useState("");
  const [replyCommentBox, setReplyCommentBox] = useState("");
  const [replyCommentBoxForm, setReplyCommentBoxForm] = useState("");
  const [replyCommentBoxShow, setReplyCommentBoxShow] = useState("");
  const [repliesOfComment, setRepliesOfComment] = useState();
  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  const [isTopRated, setIsTopRated] = useState(false);

  const [seeMoreDes, setSeeMoreDes] = useState(120);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  function logoutUser() {
    // dispatch(setIsLoggedIn());
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/");
  }

  function checkLoginToken() {
    let token = JSON.parse(localStorage.getItem("token"));
    if (!token) {
      logoutUser();
    } else {
      return token;
    }
  }

  const callApi = async (token) => {
    const handleErrors = (error) => {
      if (error?.response?.status === 401) {
        navigate("/Login");
        localStorage.removeItem("token");
      }
      console.error("Error:", error);
    };

    try {
      if (isTopRated) {
        const res = await dispatch(
          getTopRatedPostsThunk({
            token: token,
            hashtag: props.hashtags.join(","),
            bookmark: "",
            page: "profile",
            limit: 3,
          })
        );

        if (res?.payload?.status === 200) {
          // Handle success for top-rated posts
        } else {
          handleErrors(res?.payload?.error);
        }
      } else {
        const res = await dispatch(
          postGetsByUserThunk({
            token: token,
            hashtag: props.hashtags.join(","),
            bookmark: "",
            page: "profile",
            limit: 3,
          })
        );

        if (res?.payload?.status === 200) {
          // Handle success for regular posts
        } else {
          handleErrors(res?.payload?.error);
        }
      }
    } catch (error) {
      // Handle errors outside the timeout
      handleErrors(error);
    }
  };

  const handaleEditComment = (_id) => {
    setShowCommentbox(_id);
  };
  useEffect(() => {
    callApi(token);
  }, [isTopRated, props.hashtags]);

  const handleLikeAndUnlike = async (post) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/post/likeUnlike`,
        {
          post_id: post.id,
          status: post.status,
        },
        {
          headers: {
            Authorization: checkLoginToken(),
          },
        }
      );

      if (res?.status == 200) {
        await dispatch(postGetsByUserThunk(checkLoginToken()));
        // callApi()
      } else {
      }
    } catch (error) {
      console.log({ error });
    }
  };
  const handleDeletePost = async (_id) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/post/delete/${_id}`,

        { headers: { Authorization: token } }
      );

      if (res?.status == 200) {
        toast.success(res?.data?.message);
        await dispatch(postGetsByUserThunk(checkLoginToken()));
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      console.log(error);

      toast.error(error?.response?.data?.message);
    }
  };
  const handleDeleteComment = async (_id) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/post/deleteComment/${_id}`,

        { headers: { Authorization: token } }
      );

      if (res?.status == 200) {
        toast.success(res?.data?.message);
        await dispatch(postGetsByUserThunk(checkLoginToken()));
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      console.log(error);

      toast.error(error?.response?.data?.message);
    }
  };

  const handaleLike = async (item) => {
    try {
      const likeAndUlikeCommentsRes = await axios.post(
        `${process.env.REACT_APP_API_URL}/post/likeAndUlikeComments`,
        {
          comment_id: item?._id,
          post_id: item?.post_id,
          status: !item?.commentLiked,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (likeAndUlikeCommentsRes?.status == 200) {
        await dispatch(postGetsByUserThunk(checkLoginToken()));
        // handleReplyComment(item);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRatingChange = async (value) => {};
  const handleReplyComment = async (ele) => {
    try {
      const commentsRepliyres = await axios.post(
        `${process.env.REACT_APP_API_URL}/post/commentsReplies`,
        {
          parent_id: ele?._id,
          post_id: ele?.post_id,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (commentsRepliyres?.status === 200) {
        setRepliesOfComment(commentsRepliyres?.data?.body);
        setReplyCommentBox(ele?._id);
        setReplyCommentBoxShow(ele?.post_id);
        setReplyCommentBoxForm(ele?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewPeople = (_id) => {
    navigate(`/results/view-profile/${_id}`);
  };

  const handleChangePostImage = async (e) => {
    let token = JSON.parse(localStorage.getItem("token"));

    const files = e.target.files; // Array of selected files
    const imageFiles = [];

    for (const file of files) {
      let formData = new FormData();
      formData.append("folder", "demo");
      formData.append("file", file);

      try {
        let resp = await axios.post(
          `${process.env.REACT_APP_API_URL}/post/uploadFile`,
          formData,
          {
            headers: {
              Authorization: token,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (resp?.data?.success) {
          imageFiles.push(resp?.data?.body?.filename);
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          navigate("/Login");
          localStorage.removeItem("token");
        }
        console.log(error);
      }
    }
    setSelectedImage([...selectedImage, ...imageFiles]);
  };
  const formik = useFormik({
    initialValues: {
      description: "",
      filename: selectedImage,
      _id: "",
    },

    onSubmit: async (values, { resetForm }) => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/post/update`,
          { ...values, filename: selectedImage },
          {
            headers: {
              Authorization: token,
            },
          }
        );

        if (res?.status === 200) {
          setShow(false);
          callApi(token);
          resetForm();
          setSelectedImage([]);
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          navigate("/Login");
          localStorage.removeItem("token");
        }
        console.log(error);
      }
    },
  });
  return (
    <div className="side-tab second-tab post-tabs shadow-none">
      <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active border-0 justify-content-center align-items-center d-flex"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected="true"
            onClick={() => {
              setIsTopRated(false);
            }}
          >
            Popular <img className="ms-2 hide" src={strokefire} />
            <img className="ms-2 show" src={popularshow} />
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link border-0 justify-content-center align-items-center d-flex"
            id="profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#profile"
            type="button"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
            onClick={() => {
              setIsTopRated(true);
            }}
          >
            Top Rated <img className="ms-2 hide" src={singlestar} />
            <img className="ms-2 show" src={toprated} />
          </button>
        </li>
      </ul>
      {isLoading ? (
        <>
          <div className="card  rounded-4 py-4 px-4 mt-4">
            <div className="user-field d-flex align-items-center justify-content-between position-relative">
              <div className="user-thumb d-flex align-items-center w-100">
                <Placeholder
                  as="h3"
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    backgroundColor: "#dbdbdb",
                  }}
                  animation="wave"
                  className="me-2"
                />

                <div className="w-75">
                  <Placeholder as={Card.Title} animation="glow">
                    <Placeholder xs={6} />
                  </Placeholder>
                  <Placeholder as={Card.Text} animation="glow">
                    <Placeholder xs={7} />
                  </Placeholder>
                </div>
              </div>
              <Placeholder
                as={Card.Text}
                animation="glow"
                style={{ width: "15%" }}
              >
                <Placeholder xs={3} className="me-1" />
                <Placeholder xs={3} className="me-1" />
                <Placeholder xs={3} />
              </Placeholder>
            </div>

            <Placeholder as="p" animation="glow" className="w-100">
              <Placeholder xs={12} />
            </Placeholder>

            <Placeholder
              style={{
                width: "100%",
                height: "380px",
                borderRadius: "10px",
                backgroundColor: "rgb(225 225 225)",
              }}
              animation="wave"
            />

            <div className="button-group d-flex justify-content-between align-items-center mt-3">
              <Placeholder as={Card.Title} animation="glow" className="w-50">
                <Placeholder xs={2} className="me-2" />
                <Placeholder xs={2} className="me-2" />
                <Placeholder xs={2} />
              </Placeholder>
              <Placeholder
                as={Card.Title}
                animation="glow"
                className="w-50 text-end"
              >
                <Placeholder xs={1} className="me-2" />
              </Placeholder>
            </div>
            <Placeholder as={Card.Title} animation="glow" className="w-25">
              <Placeholder xs={12} className="me-2" />
            </Placeholder>

            <div className="user-thumb d-flex align-items-center">
              <Placeholder
                as="h3"
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  backgroundColor: "#dbdbdb",
                }}
                animation="wave"
                className="me-2 mb-0"
              />

              <Placeholder
                as={InputGroup}
                animation="glow"
                style={{ width: "90%", height: "35px" }}
              >
                <Placeholder xs={12} />
              </Placeholder>
            </div>

            {/* <CommentsForm values={item} /> */}
          </div>
        </>
      ) : (
        <div className="tab-content border-0  mt-0" id="myTabContent">
          {posts?.length &&
            posts?.map((item, index) => {
              return (
                <div
                  key={item?._id}
                  className="tab-pane border-0 show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="card border-0 py-4 px-4">
                    <div className="user-field d-flex align-items-center justify-content-between position-relative">
                      <div className="user-thumb d-flex align-items-center">
                        {item?.user[0]?.image ? (
                          <img
                            className="me-3"
                            src={`${process.env.REACT_APP_API_URL}/uploads/users/${item?.user[0]?.image}`}
                          />
                        ) : (
                          <div
                            className="me-3 avatar-text d-flex align-items-center justify-content-center"
                            style={{ background: "grey" }}
                          >
                            <span className="text-uppercase text-white">
                              {item?.user[0]?.firstName?.slice(0, 1)}{" "}
                            </span>
                          </div>
                        )}

                        <div className="user-details">
                          <h3
                            className="text-start mb-1"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleViewPeople(item?.user_id)}
                          >
                            {item?.user[0]?.firstName +
                              " " +
                              item?.user[0]?.lastName}
                          </h3>
                          <p className="text-start mb-0">
                            {item?.user[0]?.intro_details?.slice(0, 40)} ...
                          </p>
                          <p className="text-start mb-0">
                            {moment(item?.createdAt).fromNow()}
                          </p>
                        </div>
                      </div>
                      {/* rating */}

                      <div className="rating-box">
                        <Rating
                          value={item?.ratingOfPost}
                          item={item}
                          onChange={handleRatingChange}
                        />
                      </div>

                      {userData?._id === item?.user_id ? (
                        <Dropdown>
                          <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <img src={options} />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                setShow(true);

                                formik.setValues({
                                  description: item?.description,
                                  _id: item?._id,
                                });
                                setSelectedImage(item?.files[0]?.filename);
                              }}
                            >
                              Edit post
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleDeletePost(item._id)}
                            >
                              Delete post
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="post-description text-start py-3">
                      {item?.description?.slice(0, seeMoreDes)}

                      {seeMoreDes === item?.description?.length ? (
                        <Link
                          className="common-btn"
                          style={{
                            display:
                              item?.description.length > 120 ? "" : "none",
                          }}
                          onClick={() => setSeeMoreDes(120)}
                        >
                          Less more
                        </Link>
                      ) : (
                        <Link
                          className="common-btn"
                          onClick={() =>
                            setSeeMoreDes(item?.description?.length)
                          }
                        >
                          {item?.description?.length > 120 ? "See more " : ""}
                        </Link>
                      )}
                    </div>{" "}
                    <Swiper
                      pagination={true}
                      modules={[Pagination]}
                      className="mySwiper"
                    >
                      {!item?.files ? (
                        ""
                      ) : (
                        <>
                          {item?.files[0]?.filename?.map((ele) => {
                            return (
                              <SwiperSlide key={ele?._id}>
                                <img
                                  className="uploaded-image"
                                  src={`${process.env.REACT_APP_API_URL}/uploads/demo/${ele}`}
                                />
                              </SwiperSlide>
                            );
                          })}
                        </>
                      )}
                    </Swiper>
                    <div className="button-group d-flex justify-content-between align-items-center mt-3">
                      <div className="set-one d-flex align-items-end">
                        <a className="me-4 position-relative">
                          <img
                            className={item?.isLiked ? "like_btn_click" : ""}
                            onClick={() =>
                              handleLikeAndUnlike({
                                id: item?._id,
                                status: !item?.isLiked,
                              })
                            }
                            src={click}
                          />
                          <span>{item?.likes?.length}</span>
                        </a>
                        <a className="me-3 ms-2 position-relative">
                          <img src={comment} />
                        </a>
                        <a className="me-2 ms-1 position-relative">
                          <img src={send} />
                        </a>
                      </div>
                      <div className="set-one">
                        <a>
                          <img src={bookmark} />
                        </a>
                      </div>
                    </div>
                    <div className="comments text-start my-3">
                      <h4 className="position-relative">
                        Comments{" "}
                        <span className="round-yellow">
                          {item?.comments?.length}
                        </span>
                      </h4>

                      <>
                        {item?.comments?.slice(0, seeMore)?.map((ele) => {
                          return (
                            <>
                              {showCommentbox == ele?._id ? (
                                <div className="d-flex post-comment-field">
                                  <div className="user-field">
                                    <img src={thomas} />
                                  </div>
                                  <div className="right-comment-form ms-4">
                                    <h5>{ele?.user[0]?.firstName} </h5>
                                    <p>
                                      {ele?.user[0]?.intro_details?.slice(
                                        0,
                                        25
                                      )}
                                      ...
                                    </p>

                                    {/* Edit comments */}
                                    <Formik
                                      key={index}
                                      initialValues={{
                                        comment: ele?.comment,
                                      }}
                                      onSubmit={async (
                                        values,
                                        { resetForm }
                                      ) => {
                                        try {
                                          const resComment = await axios.post(
                                            `${process.env.REACT_APP_API_URL}/post/addEditComment`,
                                            {
                                              ...values,
                                              post_id: ele?.post_id,
                                              comment_id: ele?._id,
                                            },
                                            {
                                              headers: {
                                                Authorization: token,
                                              },
                                            }
                                          );

                                          if (resComment?.status == 200) {
                                            callApi(token);
                                            resetForm();
                                            setShowCommentbox("");
                                          }
                                        } catch (error) {
                                          console.log(error);
                                        }
                                      }}
                                    >
                                      {({
                                        values,
                                        handleChange,
                                        handleSubmit,
                                      }) => (
                                        <form onSubmit={handleSubmit}>
                                          <input
                                            type="text"
                                            name="comment"
                                            placeholder="add a comment...."
                                            value={values.comment}
                                            onChange={handleChange}
                                          />
                                          <div>
                                            <button
                                              type="submit"
                                              className="btn post-save me-3"
                                            >
                                              Save changes
                                            </button>
                                            <button
                                              className="btn post-cancel"
                                              type="button"
                                            >
                                              Cancel
                                            </button>
                                          </div>
                                        </form>
                                      )}
                                    </Formik>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <div
                                    key={item._id}
                                    className="d-flex message-one grey-cmnt justify-content-between my-3"
                                  >
                                    <div className="user-info me-1">
                                      {ele?.user[0]?.image ? (
                                        <img
                                          className="user"
                                          src={`${process.env.REACT_APP_API_URL}/uploads/users/${ele?.user[0]?.image}`}
                                        />
                                      ) : (
                                        <div
                                          className="me-3 avatar-text d-flex align-items-center justify-content-center"
                                          style={{ background: "grey" }}
                                        >
                                          <span className="text-uppercase text-white">
                                            {ele?.user[0]?.firstName?.slice(
                                              0,
                                              1
                                            )}{" "}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                    {/* comment user name */}
                                    <div className="sr-message">
                                      <b>
                                        {" "}
                                        <p
                                          className="mb-0"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            handleViewPeople(ele?.user_id)
                                          }
                                        >
                                          {ele?.user[0]?.firstName}.
                                        </p>
                                      </b>
                                      <p className="mb-1 user-profilee">
                                        {ele?.comment}
                                      </p>

                                      <div className="feedback d-flex align-items-center">
                                        <div onClick={() => handaleLike(ele)}>
                                          <img
                                            className={
                                              ele?.commentLiked
                                                ? "liked-comment me-2"
                                                : " me-2"
                                            }
                                            src={like}
                                          />
                                        </div>
                                        <div className="position-relative">
                                          <button
                                            className="btn ms-2 reply-btnn"
                                            onClick={() => {
                                              handleReplyComment(ele);
                                            }}
                                          >
                                            Reply
                                          </button>
                                        </div>
                                      </div>
                                      {replyCommentBox == ele?._id ? (
                                        <div>
                                          <div className="write-message d-flex position-relative align-items-center reply-section-message">
                                            {userData?.image ? (
                                              <img
                                                className="border-thumb user-img me-2"
                                                src={`${process.env.REACT_APP_API_URL}/uploads/users/${userData?.image}`}
                                              />
                                            ) : (
                                              <div
                                                className="me-3 avatar-text d-flex align-items-center justify-content-center"
                                                style={{ background: "grey" }}
                                              >
                                                <span className="text-uppercase text-white">
                                                  {userData?.firstName?.slice(
                                                    0,
                                                    1
                                                  )}{" "}
                                                </span>
                                              </div>
                                            )}

                                            <Formik
                                              key={index}
                                              initialValues={{ comment: "" }}
                                              onSubmit={async (
                                                values,
                                                { resetForm }
                                              ) => {
                                                try {
                                                  const resComment =
                                                    await axios.post(
                                                      `${process.env.REACT_APP_API_URL}/post/replyOnCommentCreateEdit`,
                                                      {
                                                        ...values,
                                                        post_id: ele?.post_id,
                                                        parent_id: ele?._id,
                                                      },
                                                      {
                                                        headers: {
                                                          Authorization: token,
                                                        },
                                                      }
                                                    );

                                                  if (
                                                    resComment?.status == 200
                                                  ) {
                                                    handleReplyComment(ele);
                                                    resetForm();
                                                  }
                                                } catch (error) {
                                                  console.log(error);
                                                }
                                              }}
                                            >
                                              {({
                                                values,
                                                handleChange,
                                                handleSubmit,
                                                errors,
                                                touched,
                                              }) => (
                                                <form
                                                  onSubmit={handleSubmit}
                                                  className="w-100"
                                                >
                                                  <input
                                                    type="text"
                                                    name="comment"
                                                    placeholder="Write a comment"
                                                    value={values.comment}
                                                    onChange={handleChange}
                                                  />

                                                  <div className="sending-opt d-flex align-items-center">
                                                    <button
                                                      type="submit"
                                                      className="me-2 border-0 bg-transparent"
                                                    >
                                                      {" "}
                                                      <img src={sendbtn} />
                                                    </button>
                                                  </div>
                                                </form>
                                              )}
                                            </Formik>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="time-options text-end">
                                      <span className="time me-2">
                                        {ele?.createdAt?.slice(15, 19)}
                                      </span>
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          variant="light"
                                          id="dropdown-basic"
                                        >
                                          <img src={options} />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          {userData?._id === ele?.user_id ? (
                                            <Dropdown.Item
                                              onClick={() =>
                                                handaleEditComment(ele._id, ele)
                                              }
                                            >
                                              Edit comment
                                            </Dropdown.Item>
                                          ) : (
                                            ""
                                          )}

                                          <Dropdown.Item
                                            onClick={() =>
                                              handleDeleteComment(ele._id)
                                            }
                                          >
                                            Delete comment
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                  {/* comments reply */}

                                  {replyCommentBox == ele?._id &&
                                    repliesOfComment
                                      ?.slice(0, 5)
                                      ?.map((reply) => {
                                        return (
                                          <div
                                            key={reply._id}
                                            className="d-flex message-one grey-cmnt justify-content-between my-3 reply-comment-box"
                                          >
                                            <div className="user-info me-1">
                                              {reply?.user?.image ? (
                                                <img
                                                  className="user"
                                                  src={`${process.env.REACT_APP_API_URL}/uploads/users/${reply?.user?.image}`}
                                                />
                                              ) : (
                                                <div
                                                  className="me-3 avatar-text d-flex align-items-center justify-content-center"
                                                  style={{
                                                    background: "grey",
                                                  }}
                                                >
                                                  <span className="text-uppercase text-white">
                                                    {reply?.user?.firstName?.slice(
                                                      0,
                                                      1
                                                    )}{" "}
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                            <div className="sr-message">
                                              <b>
                                                {" "}
                                                <p
                                                  className="mb-0"
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleViewPeople(
                                                      reply?.user_id
                                                    )
                                                  }
                                                >
                                                  {reply?.user?.firstName}{" "}
                                                  {reply?.user?.lastName}
                                                </p>
                                              </b>
                                              <p className="mb-1 user-profilee">
                                                {reply?.comment}
                                              </p>

                                              <div className="feedback d-flex align-items-center">
                                                {/* <div
                                                    onClick={() => {
                                                      handaleLike(reply);
                                                    }}
                                                  >
                                                    <img
                                                      className={
                                                        reply?.commentLiked
                                                          ? "liked-comment me-2"
                                                          : " me-2"
                                                      }
                                                      src={like}
                                                    />
                                                  </div> */}
                                              </div>
                                              {replyCommentBox == reply?._id ? (
                                                <div>
                                                  <div className="write-message d-flex position-relative align-items-center reply-section-message">
                                                    {userData?.image ? (
                                                      <img
                                                        className="border-thumb user-img me-2"
                                                        src={`${process.env.REACT_APP_API_URL}/uploads/users/${userData?.image}`}
                                                      />
                                                    ) : (
                                                      <div
                                                        className="me-3 avatar-text d-flex align-items-center justify-content-center"
                                                        style={{
                                                          background: "grey",
                                                        }}
                                                      >
                                                        <span className="text-uppercase text-white">
                                                          {userData?.firstName?.slice(
                                                            0,
                                                            1
                                                          )}{" "}
                                                        </span>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            <div className="time-options text-end">
                                              <span className="time me-2">
                                                {reply?.createdAt?.slice(
                                                  15,
                                                  19
                                                )}
                                              </span>
                                              {/* <Dropdown>
                                                  <Dropdown.Toggle
                                                    variant="light"
                                                    id="dropdown-basic"
                                                  >
                                                    <img src={options} />
                                                  </Dropdown.Toggle>

                                                  <Dropdown.Menu>
                                                    <Dropdown.Item
                                                      onClick={() =>
                                                        handaleEditComment(
                                                          ele._id,
                                                          ele
                                                        )
                                                      }
                                                    >
                                                      Edit comment
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                      onClick={() =>
                                                        handleDeleteComment(
                                                          ele._id
                                                        )
                                                      }
                                                    >
                                                      Delete comment
                                                    </Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown> */}
                                            </div>
                                          </div>
                                        );
                                      })}
                                </>
                              )}
                            </>
                          );
                        })}
                      </>
                    </div>
                    {seeMore === item?.comments?.length ? (
                      <Link
                        className="common-btn text-start mb-2"
                        style={{
                          display: item?.comments?.length > 3 ? "" : "none",
                        }}
                        onClick={() => setSeeMore(3)}
                      >
                        Less more
                      </Link>
                    ) : (
                      <Link
                        className="common-btn text-start mb-2"
                        style={{
                          display: item?.comments?.length <= 2 ? "none" : "",
                        }}
                        onClick={() => setSeeMore(item?.comments?.length)}
                      >
                        See more <span>({item?.comments?.length - 3})</span>
                      </Link>
                    )}
                    <div>
                      <div className="write-message d-flex position-relative align-items-center ">
                        {userData?.image ? (
                          <img
                            className="border-thumb user-img me-2"
                            src={`${process.env.REACT_APP_API_URL}/uploads/users/${userData?.image}`}
                          />
                        ) : (
                          <div
                            className="me-3 avatar-text d-flex align-items-center justify-content-center"
                            style={{ background: "grey" }}
                          >
                            <span className="text-uppercase text-white">
                              {userData?.firstName?.slice(0, 1)}{" "}
                            </span>
                          </div>
                        )}

                        <Formik
                          key={index}
                          initialValues={{ comment: "" }}
                          validationSchema={Yup.object().shape({
                            comment: Yup.string().required(
                              "Comment is required"
                            ),
                          })}
                          onSubmit={async (values, { resetForm }) => {
                            try {
                              const resComment = await axios.post(
                                `${process.env.REACT_APP_API_URL}/post/addEditComment`,
                                {
                                  ...values,
                                  post_id: item._id,
                                },
                                {
                                  headers: {
                                    Authorization: token,
                                  },
                                }
                              );

                              if (resComment?.status == 200) {
                                resetForm();
                                callApi(token);
                              }
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                        >
                          {({
                            values,
                            handleChange,
                            handleSubmit,
                            errors,
                            touched,
                          }) => (
                            <form onSubmit={handleSubmit} className="w-100">
                              <input
                                type="text"
                                name="comment"
                                placeholder="Write a comment"
                                value={values.comment}
                                onChange={handleChange}
                              />

                              <div className="sending-opt d-flex align-items-center">
                                <button className="me-2 border-0 bg-transparent">
                                  {" "}
                                  <img className="me-2" src={mic} />
                                </button>
                                <button className="me-2 border-0 bg-transparent">
                                  {" "}
                                  <img className="me-2" src={gallery} />{" "}
                                </button>
                                <button
                                  type="submit"
                                  className="me-2 border-0 bg-transparent"
                                >
                                  {" "}
                                  <img src={sendbtn} />
                                </button>
                              </div>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </div>
                    {/* <CommentsForm values={item} /> */}
                  </div>
                </div>
              );
            })}
        </div>
      )}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        className="add-post-modal"
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title>
            <div className="user-field d-flex align-items-center">
              {userData?.image ? (
                <img
                  className="border-thumb"
                  src={`${process.env.REACT_APP_API_URL}/uploads/users/${userData?.image}`}
                />
              ) : (
                <div
                  className="me-3 avatar-text d-flex align-items-center justify-content-center"
                  style={{ background: "grey" }}
                >
                  <span className="text-uppercase text-white">
                    {userData?.firstName?.slice(0, 1)}{" "}
                  </span>
                </div>
              )}
              <h5 className="mb-0">
                {userData?.firstName + userData?.lastName}
              </h5>
            </div>
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <Form.Control
              className="p-0 border-0 mb-3"
              as="textarea"
              placeholder="What do you want to talk about?"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              rows={6}
            />

            <div className="d-flex align-items-center">
              <div className="add-media">
                <Form.Control
                  type="file"
                  className="d-none"
                  id="addFile"
                  name="filename"
                  onChange={(e) => {
                    handleChangePostImage(e);
                    formik.handleChange(e);
                  }}
                  multiple
                />

                {selectedImage?.length > 0 && (
                  <div>
                    {selectedImage?.map((image, index) => (
                      <img
                        key={index}
                        src={`${process.env.REACT_APP_API_URL}/uploads/demo/${image}`}
                        height="290px"
                        className="mb-3 img-fluid"
                      />
                    ))}
                  </div>
                )}
                <Form.Label htmlFor="addFile">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="19"
                    viewBox="0 0 24 21"
                    fill="none"
                  >
                    <path
                      d="M20.5714 0H3.42857C2.51956 0.000992601 1.64807 0.362535 1.0053 1.0053C0.362535 1.64807 0.000992601 2.51956 0 3.42857V17.1429C0.000992601 18.0519 0.362535 18.9234 1.0053 19.5661C1.64807 20.2089 2.51956 20.5704 3.42857 20.5714H20.5714C21.4804 20.5704 22.3519 20.2089 22.9947 19.5661C23.6375 18.9234 23.999 18.0519 24 17.1429V3.42857C23.999 2.51956 23.6375 1.64807 22.9947 1.0053C22.3519 0.362535 21.4804 0.000992601 20.5714 0ZM16.2857 3.42857C16.7943 3.42857 17.2915 3.57938 17.7143 3.86194C18.1372 4.14449 18.4668 4.54609 18.6614 5.01596C18.856 5.48582 18.907 6.00285 18.8077 6.50166C18.7085 7.00047 18.4636 7.45865 18.104 7.81827C17.7444 8.1779 17.2862 8.4228 16.7874 8.52202C16.2886 8.62124 15.7715 8.57032 15.3017 8.37569C14.8318 8.18107 14.4302 7.85148 14.1476 7.42861C13.8651 7.00574 13.7143 6.50858 13.7143 6C13.715 5.31823 13.9861 4.66459 14.4682 4.18251C14.9503 3.70043 15.6039 3.42928 16.2857 3.42857ZM3.42857 18.8571C2.97391 18.8571 2.53788 18.6765 2.21639 18.355C1.8949 18.0335 1.71429 17.5975 1.71429 17.1429V13.5198L6.795 9.00375C7.2852 8.56899 7.92272 8.33749 8.57766 8.3564C9.23261 8.37531 9.8557 8.64322 10.32 9.10554L13.7995 12.5775L7.51982 18.8571H3.42857ZM22.2857 17.1429C22.2857 17.5975 22.1051 18.0335 21.7836 18.355C21.4621 18.6765 21.0261 18.8571 20.5714 18.8571H9.94446L16.4491 12.3525C16.9096 11.9609 17.4941 11.7451 18.0986 11.7435C18.7032 11.742 19.2887 11.9547 19.7513 12.3439L22.2857 14.4557V17.1429Z"
                      fill="#666666"
                    />
                  </svg>
                </Form.Label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="">
            <Button type="submit" className="save-changes px-5">
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}
