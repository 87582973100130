import axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPostsThunk } from "../../Features/posts/postThunk";

const Rating = ({ value, onChange, item }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const callApi = async (token) => {
    try {
      const res = await dispatch(getPostsThunk(checkLoginToken()));
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/Login");
        localStorage.removeItem("token");
      }
      console.log({ error });
    }
  };
  function logoutUser() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/");
  }

  function checkLoginToken() {
    let token = JSON.parse(localStorage.getItem("token"));
    if (!token) {
      logoutUser();
    } else {
      return token;
    }
  }
  const stars = [1, 2, 3, 4, 5]; 
  return (
    <div className="stars-quantity d-flex">
      {stars.map((star) => (
        <span
          className={"stars " + (star <= value ? "filled" : "blank")}
          key={star}
          onClick={async () => {
            try {
              const updateRating = await axios.put(
                `${process.env.REACT_APP_API_URL}/post/rating`,
                {
                  user_id: item?.user_id,
                  _id: item?._id,
                  rating: star,
                },
                {
                  headers: {
                    Authorization: checkLoginToken(),
                  },
                }
              ); 
              if (updateRating?.status === 200) {
                //callApi(checkLoginToken());
                onChange(star);
              }
            } catch (error) {
              if (error?.response?.status === 401) {
                navigate("/Login");
                localStorage.removeItem("token");
              }
              console.log(error);
            }
          }}
        >
          <i className="fa-solid fa-star"></i>
        </span>
      ))}
    </div>
  );
};

export default Rating;
