import { createSlice } from "@reduxjs/toolkit";
import { getUserGoalsThunk } from "./goalThunk";

const initialState = {
  goals: [],
};

const goalSlice = createSlice({
  name: "goal",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // create
      .addCase(getUserGoalsThunk.fulfilled, (state, action) => {
        state.goals = action?.payload?.data?.body;
      });
  },
});

export default goalSlice.reducer;
