import React from 'react'
import {Link } from "react-router-dom"
import chalk from "../Components/Assets/images/chalk.svg"
import pin from "../Components/Assets/images/pin.svg"
import unmute from "../Components/Assets/images/unmute.svg"
import resume from "../Components/Assets/images/video-off.svg"
import mute from "../Components/Assets/images/mute.svg"
import group from "../Components/Assets/images/meeting.png"
import stopvideo from "../Components/Assets/images/video.svg"
import comments from "../Components/Assets/images/comment.svg"
import participants from "../Components/Assets/images/participants.svg"
import sam from "../Components/Assets/images/sam.png"
import Victoria from "../Components/Assets/images/victoria.png"
import sharepictre from "../Components/Assets/images/share-images.svg"
import user from "../Components/Assets/images/user-2.png"
import horiopt from "../Components/Assets/images/hz-option.svg"
import like from "../Components/Assets/images/like.svg"
import dislike from "../Components/Assets/images/dislike.svg"
export default function Videoconference() {
  return (
    <div>
        <div className='message-box position-relative'>
            <div className='message-box-header d-flex align-items-center justify-content-between px-3 py-3 text-start'>
                <h4 className='mb-0'>Conference with <span >Victoria, Marketing</span>
                <p className='mt-1 mb-0 current-status'>Online</p>
                </h4>
                <div className='chalkboard'>
                    <Link   className='me-0'><img className="me-2" src={chalk} />Open Chalkboard</Link>
                </div>
            </div>
            <div className='participants d-flex align-items-center justify-content-center pt-3'>
                <div className='one position-relative me-1'>
                    <img src={Victoria} />
                    <label className='name'>Victoria Reyes</label>
                </div>
                <div className='one position-relative ms-1'>
                    <img src={group} />
                    <label className='name'><img src={unmute}/> Marketing</label>
                </div>
            </div>
            <div className='video-box mt-3 px-4'>
                            <img className='focused-thumb' src={sam} />
                            <div className='video-chat-btn d-flex align-items-center justify-content-between px-3 py-3'>
                                <div className='btn-with-icon'>
                                    <button className='border-0 mx-2'><p className='m-0'><img src={mute} /> <span>Mute</span> </p><p className='d-none m-0'><img src={mute} /> <span>Unmute</span> </p></button>
                                    <button className='border-0 mx-2'><p className='m-0'><img src={stopvideo} /> <span>Turn off</span> </p><p className='d-none m-0'><img src={resume} /> <span>Turn on</span> </p></button>
                                    <button className='border-0 mx-2 participants'><div className='icon position-relative'><img src={participants} /> <label>3</label></div> <span>Participants </span></button>
                                    <button className='border-0 mx-2 comments'><div className='icon position-relative'><img src={comments} />  <label>1</label></div> <span>Comments  </span></button>
                                </div>
                                <div className='hold-end'>
                                    <Link  className='hold me-2'>Hold</Link>
                                    <Link   className='end'>End</Link>
                                </div>
                            </div>
                            <div className='comments text-start my-3'>
                        <h4 className='position-relative'>Comments <span className='round-yellow'>8</span></h4>
                        <div className='d-flex message-one justify-content-between my-3'> 
                            <div className='user-info me-1'>
                                <img className='user' src={user} />
                            </div>
                            <div className='sr-message'>
                                <p className='mb-1'>Hi, Thomas. Awesome Post!!! Next Time We will all enjoy this view! Let’s plan next trip.</p>
                                <div className='feedback d-flex align-items-center'>
                                    <a href='/'><img className='me-2' src={like} /></a>
                                    <a href='/'><img className='me-1' src={dislike} /></a>
                                    <a  className='ms-2' href='/'>Reply</a>
                                </div>
                            </div>
                            <div className='time-options text-end'>
                                <span className='time me-2'>4:20 PM</span>
                                <img src={horiopt} />
                            </div>

                        </div>
                        <div className='d-flex message-one justify-content-between my-0'>
                            <div className='user-info me-1'>
                                <img className='user' src={user} />
                            </div>
                            <div className='sr-message'>
                                <p className='mb-1'>Hi, Thomas. Awesome Post!!! Next Time We will all enjoy this view! Let’s plan next trip.</p>
                                <div className='feedback d-flex align-items-center'>
                                    <a href='/'><img className='me-2' src={like} /></a>
                                    <a href='/'><img className='me-1' src={dislike} /></a>
                                    <a  className='ms-2' href='/'>Reply</a>
                                </div>
                            </div>
                            <div className='time-options text-end'>
                                <span className='time me-2'>4:20 PM</span>
                                <img src={horiopt} /> 
                            </div>

                        </div>
                    </div>
            </div>
            <div className='message-field py-3 px-3 mt-3'>
                 <textarea rows="4" cols="50" placeholder='Write your message' className='border-0 px-3 py-3'></textarea>
                 <div className='d-flex justify-content-between align-items-center mt-2'>
                    <div className='col-md-6 d-flex'>
                        <div className='image-input position-relative overflow-hidden'>
                            <input type="file" id="imageInput"></input>
                            <label htmlFor="imageInput" className="image-button position-absolute">asass</label>
                            <img src={pin} />
                        </div>
                        <div className='image-input position-relative overflow-hidden ms-2'>
                            <input type="file" id="imageInput"></input>
                            <label htmlFor="imageInput" className="image-button position-absolute">asass</label>
                            <img src={sharepictre} />
                        </div>
                        
                    </div>
                    <div className='col-md-6 text-end'>
                        <button>Send</button>
                    </div>
                 </div>
            </div>
        </div>
    </div>
  )
}
