import React from "react";
import icon from "../Components/Assets/images/notify-icon.svg";
import user from "../Components/Assets/images/account.svg";
import secure from "../Components/Assets/images/secure.svg";
import data from "../Components/Assets/images/data.svg";
export default function Settingtabs() {
  return (
    <div>
      <div className="d-flex align-items-center w-100 ">
        <div
          className="nav flex-column nav-pills overflow-hidden w-100"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <button className="nav-link text-start settings-btn px-4 py-3">
            Settings
          </button>
          <button
            className="nav-link text-start active d-flex justify-content-between align-items-center px-4 py-3 position-relative overflow-hidden"
            id="v-pills-home-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-home"
            type="button"
            role="tab"
            aria-controls="v-pills-home"
            aria-selected="true"
          >
            Account Preferences <img src={user} />
          </button>
          <button
            className="nav-link text-start position-relative overflow-hidden px-4 py-3"
            id="v-pills-profile-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-profile"
            type="button"
            role="tab"
            aria-controls="v-pills-profile"
            aria-selected="false"
          >
            Sign in & Security <img src={secure} />
          </button>
          {/* <button className="nav-link text-start position-relative overflow-hidden px-4 py-3" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
            Data Privacy <img src={data} />
        </button> */}
          <button
            className="nav-link text-start position-relative overflow-hidden px-4 py-3"
            id="v-pills-settings-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-settings"
            type="button"
            role="tab"
            aria-controls="v-pills-settings"
            aria-selected="false"
          >
            Notification <img src={icon} />
          </button>
        </div>
      </div>
    </div>
  );
}
